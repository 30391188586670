const { langPath } = gbCommonInfo
const userCredentials = [
  {
    path: `${langPath}/credentials/index`,
    name: 'page_user_credentials',
    meta: {
      pageType: 'user_credentials',
      keepAlive: false,
      notNeedCommonHeader: true,
      isCssInVue: true,
    },
    component: () => import(/* webpackChunkName: "user_credentials-module" */ '../../user_credentials/index.vue')
  }
]

export {
  userCredentials
}
