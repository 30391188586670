import schttp from 'public/src/services/schttp'
import { useAsyncSingleCall } from 'public/src/pre_requests/utlis/use-async-single-call.js'
import { isLogin } from 'public/src/pages/common/utils/index.js'


export async function fetchCampaignsPageData (params) {
  return schttp({ 
    url: '/api/ccc/campaignsPageData/get', 
    params,
  })
}

export const fetchCrowdIdsAndAbtParams = useAsyncSingleCall(() => {
  return schttp({ 
    url: '/api/ccc/crowdIdsAndAbtParams/get',
  })
}
)

function generateComponentPreRequestParams({ triggerParams }) {

  const { identity, tabIndex } = triggerParams
  return {
    [identity]: {
      identity,
      tabIndex,
      isLogin: isLogin(),
    }
  }
}

// 专题模块的预请求
export const campaignsQuickRequests = {
  quickRequests: {
    getCampaignsPageData: {
      generateParams: generateComponentPreRequestParams,
      request: fetchCampaignsPageData,
      // 设置会话缓存过期时间
      sessionExpired: 1000 * 60 * 20
    },
    getCampaignsCrowdIdsAndAbtParams: {
      request: fetchCrowdIdsAndAbtParams,
      params: () => {},
    }
  },
}
