import Vue from 'vue'
import { GoodsItemInfo } from 'public/src/services/goodsItemInfo'
import mutations from '../product_list_v2/mutations'
import { pageStateFactory, componentStateFactory, resultsFactory } from '../product_list_v2/state'
import { storeResultsInitialData } from './state.js'

export default {
  ...mutations,
  resetState(state, { payload, PageStateArgs, ComponentStateArgs, ResultsStateArgs }) {
    if (payload.language) state.Language = payload.language
    state.PageState = pageStateFactory(PageStateArgs)
    state.ComponentState = componentStateFactory(ComponentStateArgs)
    state.Results =  resultsFactory({ ...storeResultsInitialData, ...ResultsStateArgs })
    if (!state.GoodsItemInfoServ) state.GoodsItemInfoServ = new GoodsItemInfo({ itemLanguage: state.Language, locals: state.Locals })
  },
  dealingPageInfos(state, payload) {
    mutations.dealingPageInfos(state, payload)
    if (payload.cat_info.requestType !== 'firstload') return
    const { Results } = state
    const {
      storeData,
      storeOperation,
      storeTabConfig,
      storePromotionData,
      storeReviewData,
      storeAbtResult,
      storeDeliverInfo,
      suggestedSalePriceType,
      adpCate,
    } = payload
    const cateAdp = (adpCate?.products || []).map((item = {})=>item.goods_id).join(',')
    
    Vue.set(Results, 'storeData', storeData)
    Vue.set(Results, 'storeOperation', storeOperation)
    Vue.set(Results, 'storeTabConfig', storeTabConfig)
    Vue.set(Results, 'storePromotionData', storePromotionData)
    Vue.set(Results, 'storeReviewData', storeReviewData)
    Vue.set(Results, 'storeAbtResult', storeAbtResult)
    Vue.set(Results, 'storeDeliverInfo', storeDeliverInfo)
    Vue.set(Results, 'suggestedSalePriceType', suggestedSalePriceType) // 合规字段
    Vue.set(Results, 'pageDataResolveTime', Date.now()) // 客户端数据初始化完成时间 用于校准倒计时
    Vue.set(Results, 'isFollowed', !!storeData?.followsStatus)
    Vue.set(Results, 'cateAdp', cateAdp) // url 中携带adp_cate_id，下次请求需要带上adp 置顶 类目商品置顶

    gbCommonInfo.robotStoreData = storeData
  },
  initPage({ PageState }, { catInfo, goods }) {
    mutations.initPage({ PageState }, { catInfo })
    PageState.storeBG = goods?.[0]?.goods_img
  },
  // 更新店铺促销券数据
  updateStorePromotionCouponInfoData(state, payload) {
    const storePromotionData = state.Results.storePromotionData || {}
    const { couponInfo, isCouponInfo, couponLanguage } = payload || {}
    if (couponInfo) {
      Vue.set(storePromotionData, 'couponInfo', couponInfo)
    }
    if (typeof isCouponInfo === 'boolean') {
      Vue.set(storePromotionData, 'isCouponInfo', isCouponInfo)
    }
    if(couponLanguage){
      Vue.set(storePromotionData, 'couponLanguage', couponLanguage)
    }
  },
  // 更新店招是否可见状态
  updateShopBannerVisible(state, payload) {
    state.Results.isShopBannerVisible = payload
  },
  // 更新促销激活tab
  updatePromotionInfoActiveIndex(state, payload){
    state.Results.promotionInfoActiveIndex = payload
  },

  updateFilterPanelShow(state, payload) {
    state.Results.filterPanelShow = payload
  },
  updateIsFollowed(state, payload) {
    state.Results.isFollowed = payload
  },
  // 更新 storeTabConfig
  updateStoreTabConfigByTabId(state, tabId){
    const { isTopTabStore, topTabs, tabTypeList } =  state.Results.storeTabConfig  || {}
    // top tab
    if(isTopTabStore && topTabs && topTabs.includes(tabId)){
      state.Results.storeTabConfig.topTabActive = tabId
    }

    if(!isTopTabStore){
      // 底部tab
      if(tabTypeList && tabTypeList.includes(tabId)){
        state.Results.storeTabConfig.tabActive = tabId
      }
      // 顶部
      if(topTabs && topTabs.includes(tabId)){
        state.Results.storeTabConfig.tabActive = 'items'
        state.Results.storeTabConfig.topTabActive = tabId
      }
    }
  },
  updateStoreDeliverInfo(state, { atmosphereData }) {
    if (state.Results.storeDeliverInfo && atmosphereData) {
      state.Results.storeDeliverInfo.atmosphereData = atmosphereData
    }
  },
  // 控制榜单组件的显示隐藏
  updateFlowRankVisible(state, payload) {
    const params = payload.cat_info || {}
    const visible = !Boolean(
      params.pic_nav_id ||
        params.tag_ids ||
        params.tsp_ids ||
        params.attr_values ||
        params.child_cat_id ||
        params.max_price ||
        params.min_price
    )
    const flowRankData =
      state.ComponentState?.ProductList?.flowDataV2?.flowRank?.Data
        ?.flowRankingList
    if (!flowRankData || !flowRankData.deliverPlaceItemsObject) return
    Object.values(flowRankData.deliverPlaceItemsObject).forEach(n => {
      n.visibleInFlow = visible
    })
  },
}
