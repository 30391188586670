const path = [
  '*-a-(\\d+).html',
  'product/article/:article_id',
  'afterpay.html',
  'contact-us.html',
  'articlePreview',
  'article-(\\S+).html',
]

export const createArticleRoute = langPath => {
  const routes = path.map((route, index) => {
    return {
      path: `${langPath}/${route}`,
      component: () => import(/* webpackChunkName: "article" */ '../../article_spa/container.vue'),
      name: `page_article_${index}`,
      beforeEnter: (to, from, next) => {
        // 检查 Swiper 是否已加载，如果已加载则直接导航
        if (typeof Swiper === 'undefined' && typeof window !== 'undefined') {
          const script = document.createElement('script')
          script.src = `${gbCommonInfo.PUBLIC_CDN}/pwa_dist/libs/swiper/swiper.min.js` // Swiper 的脚本文件 CDN 资源
          script.onload = next  // 当脚本加载完成后，继续路由导航
          document.body.appendChild(script)
        } else {
          next()
        }
      },
      meta: {
        useArticleCode: true,
        pageType: 'article',
        notNeedCommonHeader: true,
        scrollTop: true,
        monitorTag: 'article',
        isCssInVue: true,
      }
    }
  })
  return routes
}
