const { langPath } = gbCommonInfo
const friendshipCouponRoutes = [
  {
    path: `${langPath}/friendship_coupon`,
    component: () => import('../../friendship_coupon/container.vue'),
    name: 'FriendshipCoupon',
    meta: {
      notNeedCommonHeader: true,
      keepAlive: false
    }
  },
  {
    path: `${langPath}/couponActivity`,
    component: () => import('../../friendship_coupon/friendshipCouponReceive.vue'),
    name: 'FriendshipCouponReceive',
    meta: {
      notNeedCommonHeader: true,
      keepAlive: false
    }
  }
]

// 站点设置
// friendshipCouponRoutes.forEach((router) => {
//   router.meta.pageType = 'friendship_coupon'
// })


export {
  friendshipCouponRoutes
}
