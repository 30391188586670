import { pageStateFactory, componentStateFactory, resultsFactory } from '../product_list_v2/state'

export const storeResultsInitialData = {
  storeData: {},
  storeOperation: {},
  storeTabConfig: {},
  storePromotionData: {},
  storeReviewData: {},
  storeAbtResult: {},
  storeDeliverInfo: {},
  suggestedSalePriceType: '',
  pageDataResolveTime: Date.now(),
  isShopBannerVisible: true,
  promotionInfoActiveIndex: 0,
  
  filterPanelShow: false,
  isFollowed: false,
}
export default {
  Locals: {},
  Language: {},
  PageState: pageStateFactory(),
  ComponentState: componentStateFactory(),
  Results: resultsFactory({
    ...storeResultsInitialData
  }),
  GoodsItemInfoServ: null,
  // 业务监控开关
  reportMetrics: { 
    page: true,
    img: true, 
    addBag: true, 
    jumpDetail: true, 
    clickCartBag: true,
    cccClick: true,
  },
}
