import { prefetchResource } from '../../../services/prefetchResource'

export const detailRouters = [
  {
    path: '/*-p-(\\d+)-cat-(\\d+).html',
    component: prefetchResource.importAsyncComponent({
      chunkName: 'goods_detail',
      componentFactory: () =>
        import(
        /* webpackChunkName: "goods_detail" */ '../../goods_detail/container.vue'
        ),
    }),
    name: 'page_goods_detail',
    meta: {
      scrollTop: true,
      pageType: 'productDetail',
      isCssInVue: true,
      monitorTag: 'product-detail',
    },
  },
  {
    path: '/*-p-(\\d+).html',
    component: prefetchResource.importAsyncComponent({
      chunkName: 'goods_detail',
      componentFactory: () =>
        import(
        /* webpackChunkName: "goods_detail" */ '../../goods_detail/container.vue'
        ),
    }),
    name: 'page_goods_detail',
    meta: {
      scrollTop: true,
      isCssInVue: true,
      pageType: 'productDetail',
    },
  },
  {
    path: '/*-p-(\\d+)-cat-(|).html',
    component: prefetchResource.importAsyncComponent({
      chunkName: 'goods_detail',
      componentFactory: () =>
        import(
        /* webpackChunkName: "goods_detail" */ '../../goods_detail/container.vue'
        ),
    }),
    name: 'page_goods_detail',
    meta: {
      scrollTop: true,
      pageType: 'productDetail',
      isCssInVue: true,
      monitorTag: 'product-detail',
    }
  },
]
