import schttp from 'public/src/services/schttp'
import { createBFFMixinRequestHelper } from './util'

export const getMessageCouponListApi = (params, useBffApi) => {
  return schttp({
    method: 'GET',
    url: `${useBffApi ? '' : '/api'}/user/message/get_coupon_list`,
    params: params,
    useBffApi
  })
}

export const bindCouponApi = (params, useBffApi) => {
  return schttp({
    method: 'POST',
    url: `${useBffApi ? '' : '/api'}/user/coupon/bind_coupon`,
    data: params,
    useBffApi
  })
}

//获取未读消息
export const getMessageUnReadApi = (params, useBffApi) => {
  return schttp({
    method: 'GET',
    url: useBffApi ? '/user/v3/msg_unread' : '/api/user/message/getUnRead/get',
    params: params,
    useBffApi
  })
}

//设置已读消息
export const setMessageReadApi = (params, useBffApi) => {
  return schttp({
    method: 'POST',
    url: useBffApi
      ? '/user/v2/message_set_read'
      : '/api/user/message/setRead/update',
    ...(
      useBffApi ? {
        params: params,
      } : {
        data: params,
      }
    ),
    useBffApi
  })
}

//获取多语言
export const getMessageLanguageApi = (params, useBffApi) => {
  if (useBffApi) {
    return schttp({
      method: 'POST',
      url: '/system/configs/page_multi_language_mapping',
      data: params,
      useBffApi
    })
  }
  return schttp({
    url: '/api/user/message/language/get'
  })
}

export const getMessageTipApi = useBffApi => {
  return schttp({
    method: 'GET',
    url: useBffApi
      ? '/user/v2/message_get_tip'
      : '/api/user/message/getMessageTip/get',
    useBffApi
  })
}

//删除站内信消息
export const messageDeleteApi = (params, useBffApi) => {
  return schttp({
    method: 'POST',
    url: useBffApi
      ? '/user/v2/message_delete'
      : '/api/user/message/delete/delete',
    data: params,
    useBffApi
  })
}

//分页获取订单消息列表
export const getOrderListApi = (params, useBffApi) => {
  return schttp({
    method: 'GET',
    url: useBffApi
      ? '/user/v2/message_order_list'
      : '/api/user/message/getOrderList/get',
    params: params,
    useBffApi
  })
}

//分页获取新闻消息列表
export const getNewsListApi = (params, useBffApi) => {
  return schttp({
    method: 'GET',
    url: useBffApi
      ? '/user/v2/message_news_list'
      : '/api/user/message/getNewsList/get',
    params: params,
    useBffApi
  })
}

//分页获取催销消息列表
export const getPromoListApi = (params, useBffApi) => {
  return schttp({
    method: 'GET',
    url: useBffApi
      ? '/user/v2/get_promo_message'
      : '/api/user/message/getPromoList/get',
    params: params,
    useBffApi
  })
}

//分页获取activity消息列表
export const getActivityListApi = (params, useBffApi) => {
  return schttp({
    method: 'GET',
    url: useBffApi
      ? '/user/v2/message_activity_list'
      : '/api/user/message/getActivityList/get',
    params: params,
    useBffApi
  })
}

export const getCCCDataApi = () => {
  return schttp({
    method: 'GET',
    url: '/api/user/message/advertising/get'
  })
}

export const getCCCDataBFFApi = params => {
  return schttp({
    method: 'GET',
    url: '/ccc/common_component',
    params: params,
    useBffApi: true
  })
}

export const getMessageCCCDataApi = createBFFMixinRequestHelper({
  originRequest: {
    request: getCCCDataApi
  },
  bffRequest: {
    request: getCCCDataBFFApi,
    formatResponse(res) {
      if (res?.code === '0') {
        const data = res.info?.content?.reduce(
          (acc, item) => {
            if (item.placeHolderKey === 'vertical_diversion') {
              acc.vcData.push(...item?.props?.items)
            } else if (item.placeHolderKey === 'horizontal_diversion') {
              acc.hzData.push({
                ...item?.props?.metaData,
                aodId: item?.aodId,
                materialName: item?.materialName
              })
            }

            return acc
          },
          { hzData: [], vcData: [] }
        )

        return {
          hzData: data?.hzData.slice(0, 10),
          vcData: data?.vcData.length < 3 ? [] : data?.vcData.slice(0, 10),
          cateLinks: res?.info?.cateLinks
        }
      }
    }
  }
})
