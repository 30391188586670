const { langPath } = gbCommonInfo
const emailUnbindRoutes = [
  {
    path: `${langPath}/email_unbind`,
    name: 'page_email_unbind',
    meta: {
      isCssInVue: true,
      pageType: 'email_unbind',
      keepAlive: false,
      notNeedCommonHeader: true
    },
    component: () => import(/* webpackChunkName: "email-unbind-module" */ '../../email_unbind/container.vue')
  }
]

export {
  emailUnbindRoutes
}
