
import { computed, onMounted, ref } from 'vue'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'

export default function useShare({ route }) {
  const changeCardShareIconAbt = ref(false)
  onMounted(async () => {
    const { collectandaddcart } = await getUserAbtData()
    changeCardShareIconAbt.value = collectandaddcart?.p?.collectandaddcart === 'A' // 调整购物车与收藏入口位置abt

  })

  const showWish = computed(() =>{
    //详情页分享
    return ['page_goods_detail'].includes(route.value.name)
    && changeCardShareIconAbt.value
  })
  return {
    showWish
  }
}
