<template>
  <div class="store-header__right">
    <!-- 没有吸顶 -->
    <StoreHeaderMore 
      v-show="!isStoreTopTabSticky"
    />
    <!-- 命中abt -->
    <template 
      v-if="isTopfollowbutton"
    >
      <StoreFollowIcon 
        v-if="isTrendStore"
        v-show="isStoreTopTabSticky"
      />
      <StoreFollowEntry 
        v-else
        v-show="isStoreTopTabSticky"
      />
    </template>
  </div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'StoreHeaderCenter', // 店铺中间
})
</script>
<script setup>
import {  computed } from 'vue'
import { useStorePageData, useInjectStoreHeader } from '../../hooks/useStoreHeader.js'
import StoreHeaderMore from './StoreHeaderMore.vue'
import StoreFollowIcon from './StoreFollowIcon.vue'
import StoreFollowEntry from './StoreFollowEntry.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'

daEventCenter.addSubscriber({ modulecode: '2-9' })
daEventCenter.addSubscriber({ modulecode: '2-22' }) 

const { isStoreTopTabSticky } = useInjectStoreHeader()

const { storeAbtResult, isTrendStore } = useStorePageData()

const isTopfollowbutton = computed(()=>{
  return storeAbtResult.value.followenhance?.p?.topfollowbutton === 'B'
})

</script>
<style lang="less">
.store-header__right{
  flex-shrink: 0;
}

</style>
