import schttp from 'public/src/services/schttp'
import { preloadImg } from 'public/src/pages/ccc-campaigns/components/common/utils.js'
import { prefetchResource } from 'public/src/services/prefetchResource/index.js'
import { ApiCache } from './ApiCache/index'
import { isSwitchBffApiVersion } from 'public/src/pages/common/bff-new/index'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'

export const rankBgIconTop3Map = [
  '/pwa_dist/images/search/rank_1-3692b60124.svg',
  '/pwa_dist/images/search/rank_2-0761c3f98a.svg',
  '/pwa_dist/images/search/rank_3-20863f21b2.svg'
]
export const trendDefaultSearchIcon = '/pwa_dist/images/search/rank_other-5a804fff61.svg'
export const trendTitielIcon = '/pwa_dist/images/search/icon_crown-d4cb48eb41.svg'

const { IS_RW } = gbCommonInfo
const useBffApi = isSwitchBffApiVersion(['preSearch_v1']) || {}
const preSearchUseBffApi = !IS_RW && useBffApi?.['preSearch_v1'] 

async function fetchSearchPageDataBff(params) {
  const {
    SearchSimilar = {},
    Similaritems = {},
    SearchTrendNew = {},
  } = await getUserAbtData()
  function wordFromBff(data = {}) {
    const { word_type, goods_id, scene, store_code } = data
    const isGoodsDetail = goods_id && !!Number(goods_id) // pick 页面等 拿到的不是goodsId
    return schttp({
      url: '/product/search/v3/get_keywords',
      useBffApi: true,
      isPreLoad: true,
      params: {
        goods_id: (isGoodsDetail && goods_id) || '',
        scene: scene === 'store' ? 'store' : isGoodsDetail ? 'detail' : 'home',
        word_type: word_type,
        store_code: store_code
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  function detailSimilarBff(data) { // 商详预搜页相似推荐
    const { goods_id, cate_id = '' } = data
    const isGoodsDetail = goods_id && !!Number(goods_id)
    if (!isGoodsDetail || !goods_id || !cate_id || SearchSimilar?.p?.SearchSimilar !== 'show') {
      return [] // 商详页面现在拿不到cate_id,这里就return了
    }
    return schttp({
      url: '/product/recommend/get_similar_list',
      useBffApi: true,
      isPreLoad: true,
      params: {
        goods_list: `${goods_id}_${cate_id}`,
        scene: goods_id ? 'detail' : 'home',
        cate_id,
        page: 1,
        limit: 11,
        size: 11,
        rule_id: Similaritems?.p
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  function dealWithTrendData({ data, type_id = '', language }) {
    if (!data || !Array.isArray(data)) return []
    // trendingSearch由keywords参数返回
    return data.map((v, vi) => {
      v.trend_name = v.trend_name ? `${v.trend_name} ${language.SHEIN_KEY_PWA_24660}` : language.SHEIN_KEY_PWA_24658
      const result_content = []
      const trend_list_content = []
      
      // 埋点相关参数处理
      v.info = v.info.map((item, index) => {
        item.type_id = type_id
        item.position = index + 1
        item.result_content = `11\`${item.word}\`${item.position}\`${type_id}\`-\`${item.wordLabel?.labelType || '-'}\`${item.word_type || 'common'}`
        item.trend_list_content = `${v.cate_flag}\`${v.trend_name}\`${v.cate_id}\`${vi + 1}\`${item.word}\`${index + 1}\`${item.new_flag}`
        item.trend_list_content_click = item.trend_list_content + '`1'
  
        // 埋点方需要展示3分2以上才上报，所以将指令式埋点塞到3分2的位置
        item.isExpose = Math.ceil(v.info.length * 0.7) === index
        item.state = item.rank_change?.includes('-') ?  -1 :  item.rank_change?.includes('+') ? 1 : 0
  
        result_content.push(item.result_content)
        trend_list_content.push(item.trend_list_content)
  
        return item
      })
  
      v.result_content = result_content.join()
      v.trend_list_content = trend_list_content.join()
      return v
    })
  }

  try {
    const [defaultRes, hotTrendRes, detailSimilarRes, languageABtRes] = await Promise.all([
      wordFromBff({
        word_type: 1,
        ...params,
      }), // 底纹词
      wordFromBff({
        word_type: 2,
        ...params,
      }), // 热词 + 趋势榜
      detailSimilarBff(params),
      fetchSearchPageDataPwa({ ...params, onlyLanguage: true }) // 多语言
    ])
    if (defaultRes?.code != 0 || hotTrendRes?.code != 0) {
      // 底纹词和热词数据有问题，手动抛出，走pwa中间层
      throw new Error(JSON.stringify({
        defaultRes,
        hotTrendRes,
      }))
    }
    const trendData = hotTrendRes?.info?.multiTrendKeywords
    const language =  languageABtRes?.info?.language
    const abtInfoSearchTrendNew = SearchTrendNew?.p || {}
    const defaultWords = defaultRes?.info?.keywords || []
    let defaultWordsHKTagOn = false
    for (let i = 0; i < defaultWords.length; i++ ) {
      if ( ['trend', 'hot', 'sold', 'like'].includes(defaultWords[i].wordLabel?.labelType)) {
        defaultWordsHKTagOn = true
        break
      }
    }
    const isGoodsDetail = params.goods_id && !!Number(params.goods_id,)
    const goodsId = (isGoodsDetail && params.goods_id) || ''
    const res = {
      info: {
        language,
        hot: {
          defaultTagOn: false, // 热词无默认词标签
          hasTag: false, 
          HKTagOn: false, // 数据客户端暂时没有用到，先和pwa中间层一样返回该字段
          keywords: hotTrendRes?.info?.keywords
        },
        default: { 
          defaultTagOn: defaultWordsHKTagOn,
          hasTag: false, 
          HKTagOn: false, // 数据客户端暂时没有用到，先和pwa中间层一样返回该字段
          keywords: defaultWords
        },
        scene: params.scene,
        store_code: params.store_code,
        paramsGoodsId: goodsId,
        similarItems: { ...detailSimilarRes?.info, goods_id: goodsId, cate_id: params.cate_id },
        abtData: languageABtRes?.info?.abtData,
        trendHome: !goodsId ? dealWithTrendData({ data: trendData, type_id: abtInfoSearchTrendNew.Home, language }) : [],
        trendDetails: goodsId ? dealWithTrendData({ data: trendData, type_id: abtInfoSearchTrendNew.GoodsDetail, language }) : [],
      }
    }
    return res
  } catch(err) {
    return fetchSearchPageDataPwa(params)
  }
}

function fetchSearchPageDataPwa(params) {
  return schttp({
    url: '/api/searchwords/searchNecessaryData/get',
    isPreLoad: true,
    params
  })
}

async function fetchSearchPageData(params) {
  let res = {}
  if (preSearchUseBffApi) {
    res =  await fetchSearchPageDataBff(params)
  } else {
    res =  await fetchSearchPageDataPwa(params)
  }
  const { trendDetails, trendHome, hot } = res?.info || {}
  const hotWordList = (hot?.keywords || []).map(i => i.imgSrc).filter( i => !!i)
  const trendDList = [...(trendDetails || []), ...(trendHome || [])]
  // 取两个趋势的列表下的前三个商品进行预取图片
  if (trendDList.length) {
    const trendDListFirstTwo = trendDList.slice(0, 2)
    const trendDListFirstTwoGoods = trendDListFirstTwo.reduce((acc, cur) => {
      const urls = (cur?.info || []).slice(0, 3).map(item => item?.pageUrl || item?.word_image )
      return [...acc, ...urls].filter(item => item)
    }, [])
    if (trendDListFirstTwoGoods.length || hotWordList.length) {
      const imgs = [...trendDListFirstTwoGoods, ...rankBgIconTop3Map, trendDefaultSearchIcon, trendTitielIcon, ...hotWordList]
      preloadImg(imgs)
    }
  }
  return res
}

const apiCache = new ApiCache({
  cacheTime: 1 * 60 * 1000, // 缓存时间 1 分钟
  request: async (params) => {
    return await fetchSearchPageData(params)
  },
  notCacheKeys: [],
})

let preRequestParams = {
  goods_id: '',
  // cate_id: ''
}
const handlePreSeachRouterTriggerQuickRequestBefore = () => {
  requestIdleCallback(() => {
    apiCache.request(preRequestParams)
  })
}

/* 空闲时间触发预请求 */ 
;(function flushPreSeachFetchApi() {
    if (typeof window === 'undefined') return
    setTimeout(() => {
      requestIdleCallback(() => {
        handlePreSeachRouterTriggerQuickRequestBefore()
      })
    }, 1000)
})()

export const handlePreSearchBeforeEachRoute = (to, from) => {
  // 商详页去预搜页有可能携带goods_id
  const goods_id = [to.name, from.name].includes('page_goods_detail') ? !IS_RW && (to.params?.['1'] || (!to.params?.['1'] && from.params?.['1'])) || '' : ''
  // const cate_id = to?.query?.cate_id || from?.query?.cate_id || '' // 商详页面现在拿不到cate_id 了，因此预搜页商详相似会无数据
  Object.assign(preRequestParams, { goods_id })
  if ([to.name, from.name, to?.query?.pagefrom, from?.query?.pagefrom].includes('page_store')) {
    const store_code = to?.query?.store_code || from?.query?.store_code || ''
    Object.assign(preRequestParams, { scene: 'store', store_code })
  } else {
    preRequestParams = { goods_id }
  }
  if (to?.name === 'config_index') {
    const loadComponent = prefetchResource.importAsyncComponent({
      chunkName: 'pre_search', // 跟webpackChunkName保持一致
      componentFactory: () => import( /* webpackChunkName: 'pre_search' */ 'public/src/pages/common/search_words/search.vue'),
    })
    loadComponent?.()
  }
  handlePreSeachRouterTriggerQuickRequestBefore()
}

export const getSearchNecessaryData = async (params) => {
  let result = await apiCache.request(params)
  try {
    // 修复Observer数据污染的问题
    result = JSON.parse(JSON.stringify(result))
  } catch (error) {
    result = {}
  }
  return result
}
