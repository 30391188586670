export const getQuickCart = () => {
  if (typeof window === 'undefined') return false
  const target = document.getElementsByClassName('j-cart-bag-container')
  return !!window._gb_list_cart_ &&
    !!target.length &&
    !!target[0].offsetParent &&
    window._gb_list_cart_
}

export const getNewQuickCart = () => {
  if (typeof window === 'undefined') return false
  const target = document.getElementsByClassName('j-quick-cart-container')
  return !!window._gb_list_cart_ &&
    !!target.length &&
    !!target[0].offsetParent &&
    window._gb_list_cart_
}

export const getHeaderCart = () => {
  if (typeof window === 'undefined') return false
  const target = document.getElementsByClassName('j-header-cart')
  return !!window._gb_header_cart_ &&
    !!target.length &&
    !!target[0].offsetParent &&
    window._gb_header_cart_
}

export const getFooterCart = () => {
  if (typeof window === 'undefined') return false
  const target = document.getElementsByClassName('j-footer-cart')
  return !!window._gb_footer_ &&
    !!target.length &&
    !!target[0].offsetParent &&
    window._gb_footer_
}
