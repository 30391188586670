
import { Scroll, Expose, getExposeInstance } from '@shein-aidc/basis-expose'

const windowScrollInstance = getExposeInstance()
let windowLinstnerScrollEventInstance = null
// 提供测试
if (typeof window != 'undefined') {
  window.windowScrollInstance = windowScrollInstance

  windowLinstnerScrollEventInstance = new Scroll()
}


export {
  Scroll,
  windowLinstnerScrollEventInstance,
  windowScrollInstance
}

export default Expose
