<script lang="jsx">
import { Icon } from '@shein-aidc/icon-vue2'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import { useStorePageData, useInjectStoreHeader } from '../../hooks/useStoreHeader.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'

export default {
  name: 'StoreFollowEntry',
  components: {
    Icon
  },
  data() {
    return {
      showToast: false,
      hasToast: false,
      toastPosition: {},
    }
  },
  setup() {
    const { isStoreTopTabSticky } = useInjectStoreHeader()
    const { storeAbtResult, GB_cssRight, language, langPath } = useStorePageData()

    return {
      storeAbtResult,
      GB_cssRight,
      language,
      langPath,
      isStoreTopTabSticky,
    }
  },
  computed: {
    followenhanceAbt(){
      return this.storeAbtResult.followenhance?.p?.masktip
    },
    toastText(){
      return this.language.SHEIN_KEY_PWA_32258
    }
  },
  watch: {
    isStoreTopTabSticky(val){
      if(val){
        this.handleTipToast()
      }
    },
  },
  mounted() {
    this.hasToast = this.getCacheToast()
    if(this.isStoreTopTabSticky){
      this.handleTipToast()
    }
  },
  methods: {
    handleTipToast(){
      if(this.hasToast) return
      if(this.followenhanceAbt !== 'B') return
      if(this.showToast) return
      this.toastPosition = this.handleToastPosition() // todo 
      this.setCacheToast()
      this.hasToast = true
      this.showToast = true
      setTimeout(() => {
        this.showToast = false
      }, 3000)
    },
    /**
     * 获取缓存中判断是否弹过toast的key
     * @description STORE_FOLLOW_ENTRY_TOAST
     * @description 更换设备、清除缓存、缓存过期，均重新倒计时
     */
    getLocalCacheKey() {
      return `STORE_FOLLOW_ENTRY_TOAST`
    },
    getCacheToast() {
      const cacheKey = this.getLocalCacheKey()
      return window.localStorage.getItem(cacheKey)
    },
    setCacheToast(val = 1) {
      const cacheKey = this.getLocalCacheKey()
      window.localStorage.setItem(cacheKey, val)
    },

    handleToastPosition() {
      const followEntryRef = this.$refs.followEntryRef
      if (followEntryRef) {
        const followEntry = followEntryRef.$el
        const followEntryRect = followEntry.getBoundingClientRect()
        const { top, height } = followEntryRect ?? {}
        return {
          y: top + height + 6.6
        }
      }
    },
    handleClickFollowButton(){
      daEventCenter.triggerNotice({
        daId: '2-9-13',
      })
      const login = isLogin()
      if (!login){
        return SHEIN_LOGIN.show({
          show: true,
          cb: () => {
            this.handleClickFollowButton()
          }
        })
      }
      this.$router.push({
        path: `${this.langPath}/store/following`,
        query: {
          page_from: 'page_store',
          entrance: 'top_button',
        },
      })
    }
  },
  render() {
    const propStyle = {
      zIndex: 301,
      transform: 'translate3d(0, 0, 301px)',
    }
    // 兼容弹出toast时位置
    if (this.toastPosition?.y) {
      Object.assign(propStyle, {
        position: 'fixed',
        top: `${this.toastPosition?.y}px`
      })
    }
    if (this.GB_cssRight) {
      propStyle.left = '0.32rem'
      propStyle.marginRight = '0.32rem'
      propStyle.marginLeft = 'auto'
    } else {
      propStyle.right = '0.32rem'
      propStyle.marginLeft = '0.32rem'
      propStyle.left = 'auto'
    }
    return (
      <div
        v-expose={{
          id: '2-22-78',
          data: {},
        }}
        v-tap={{
          id: '2-22-79',
          data: {},
        }}
        class={this.showToast && 'store-icon-wrapper'}
        vOn:click={this.handleClickFollowButton}
      >
        <SPopover
          class="store-icon-popover"
          value={this.showToast}
          trigger="unset"
          trigger-type="user"
          prop-style={propStyle}
          theme="dark"
          placemen="bottom-end"
          append-to-body={true}
          fix-disappear-position={true}
          custom-class="store-icon-popover__wrapper"
        >
          <template slot="reference">
            <Icon
              ref="followEntryRef"
              name="sui_icon_follow_18px"
              size=".64rem"
            />
          </template>
          {this.toastText}
        </SPopover>
      </div>
    )
  }
}
</script>

<style lang="less">
.store-icon-wrapper {
  position: relative;
}

.store-icon-popover {
  display: block;
  padding-left: .32rem /* 12/37.5 */;
  &__wrapper {
    padding: 0;

    &::before {
      background: linear-gradient(135deg, rgba(0,0,0,.8) 50.5%, transparent 50%);
    }

    .S-popover__main-content {
      padding: 0.32rem;
      border-radius: 2px;
      background-color: rgba(0, 0, 0, 0.8);

      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
    }
  }
}
</style>
