import { getQueryString } from '@shein/common-function'
import { abtservice } from 'public/src/services/abt'
// eslint-disable-next-line @shein-aidc/abt/abt
export const AB_TEST = () => abtservice.getUserAbtResultForAnalysis({ posKeys: 'SearchSuggestNew,SearchDefaultNew,SearchHotGD,SearchHotNew,SearchSuggestwordFeedback,PicSearch,MoreSearchWord,NewPicSearch,HomeSearch,SearchSimilar,Similaritems' }).sa

function sliceWord(word, type) {
  if (typeof type === 'undefined') {
    type = SaPageInfo?.page_param?.result_type ?? 2
  }
  return type === 2 ? (word ?? '').slice(0, 150) : word
}

// 埋点要求的字段名是entrancetype（小写）, url携带的entranceType（大写）
function getEntrancetypeVal() {
  return SaPageInfo?.page_param?.entrancetype || getQueryString({ key: 'entranceType' }) || '-'
}

/**
 * 搜索埋点玩法searchInput
 *1 expose_search  落地曝光, 上报搜索框(内)内容 --e.g.原词/默认词全组
 *2 click_search  点击搜索框, 上报搜索框(内)内容 --e.g.原词/默认词当前轮更词
 *3 expose_top_site_search  抽屉内容曝光
 *4 click_top_site_search  抽屉内容点击 -- 修改 doSearch
 */
const analysis = {
  buildResultContent({ currentWord, correction, placeholder, defaultWords, historyWords = [], hotImages = [], associationWords = { guideWords: [], word: [] }, isCatNameSearchWrods, localIndex }) {
    let items = []
    // 当 currentWord 有值时，处理并添加到数组
    if (currentWord) {
      items.push(`${isCatNameSearchWrods ? 3 : 2}\`${sliceWord(currentWord)}\`1\`-\`-\`-\`common`)
    } else{
      // 处理默认词或者占位词
      let defaultOrPlaceolderItems = (defaultWords || (placeholder ? [placeholder] : []))
        .filter(i => !!i.word)
        .map((item, index) => `3\`${item.word}\`${(localIndex + 1) || (index + 1)}\`${item.type || '-'}\`-\`${item.wordLabel?.labelType || '-'}\`${item?.wordTagType || 'common'}`)
      items = items.concat(defaultOrPlaceolderItems)
    }

    // 处理历史词
    let historyItems = historyWords.map((item, index) => `5\`${item.keywords}\`${index + 1}\`-\`-\`-\`${item?.wordTagType || 'common'}`)
    items = items.concat(historyItems)

    // 处理热词
    let hotImageItems = hotImages.map((item, index) => `${item.from === 'ccc' ? 9 : 6}\`${item.word}\`${index + 1}\`${item.type || '-'}\`-\`${item.wordLabel?.labelType || '-'}\`${item?.wordTagType || 'common'}`)
    items = items.concat(hotImageItems)

    // 处理纠错词
    if (correction) {
      items.push(`8\`${correction}\`1\`-\`-\`-\`common`)
    }

    // 处理联想引导词
    let guideWordItems = associationWords.guideWords.map((item, index) => `7\`${item.keywords}\`${(correction ? 1 : 0) + index + 1}\`${'-'}\`${'-'}\`${item.tag || '-'}\`${item?.wordTagType || 'common'}`)
    items = items.concat(guideWordItems)

    // 处理联想词
    let associationWordItems = associationWords.word.map((item, index) => `${correction ? 8 : 4}\`${item.keywords}\`${(correction ? 1 : 0) + associationWords.guideWords.length + index + 1}\`${'-'}\`${'-'}\`${item.tag || '-'}\`${item?.wordTagType || 'common'}`)
    items = items.concat(associationWordItems)

    // 处理额外的联想词的品类/属性筛选词埋点数据
    const baseNum = (correction ? 1 : 0) + associationWords.guideWords.length + 1
    let associationAttrResultContent = associationWords.word.reduce((data, n, i) => {
      data.push(...(n.attrs || []).map(m => `18\`${n.keywords} ${m}\`${baseNum + i}\`${m}\`-\`-\`common`))
      return data
    }, [])
    items = items.concat(associationAttrResultContent)

    let finalString = items.join()

    return finalString
  },
  buildIdentifier({ currentWord, correction, placeholder, defaultWords, historyWords = [], hotImages = [], associationWords = { guideWords: [], word: [] }, isCatNameSearchWrods }) {
    // {st: 2,  // 类型 sc: '', // 原词 sr: 0, // 纠错词 ps: 0 // 位置 }
    const arr = []
    // 搜索词
    if (currentWord) {
      arr.push({ st: `${isCatNameSearchWrods ? 3 : 2}`, sc: sliceWord(currentWord), sr: 0, ps: 1 })
    } else {
      // 默认词和占位词
      const words = defaultWords || (placeholder ? [placeholder] : [])
      words
        .filter(item => !!item.word)
        .forEach((item, index) => {
          arr.push({ st: 3, sc: item.word, ps: index + 1, sr: 0 })
        })
    }
    // 历史词
    historyWords.forEach((item, index) => {
      arr.push({ st: 5, sc: item.keywords, ps: index + 1, sr: 0 });
    })
    // 热词
    hotImages.forEach((item, index) => {
      arr.push({ st: 6, sc: item.word, ps: index + 1, sr: 0 })
    })
    // 纠错次
    if (correction) {
      arr.push({ st: 8, sc: currentWord, ps: 1, sr: correction })
    }
    // 联想引导词
    associationWords.guideWords.forEach((item, index) => {
      arr.push({ st: 7, sc: item.keywords, ps: (correction ? 1 : 0) + index + 1, sr: 0 })
    })
    // 联想词
    associationWords.word.forEach((item, index) => {
      arr.push({ st: correction ? 8 : 4, sc: item.keywords, ps: (correction ? 1 : 0) + associationWords.guideWords.length + index + 1, sr: 0 })
    })
    // 联想词的品类/属性筛选词
    const baseNum = (correction ? 1 : 0) + associationWords.guideWords.length + 1
    associationWords.word.forEach((n, i) => {
      n.attrs?.forEach(m => arr.push({ st: 18, sc: `${n.keywords} ${m}`, sr: 0, ps: baseNum + i }))
    })
    return arr.map(({ st, sc, sr, ps }) => `st=${st}\`sc=${sc}\`sr=${sr}\`ps=${ps}`).join(',')
  },
  searchInput(activity_name, payload, options) {
    const data = {
      activity_name,
      activity_param: {
        search_content: sliceWord(payload.currentWord) || payload.placeholder?.word || '',
        result_content: this.buildResultContent(payload),
        abtest: AB_TEST(),
        crowd_id: payload.crowdId || payload.placeholder?.crowdId || 0,
        src_module: payload.src_module || 'search',
        src_identifier: this.buildIdentifier(payload),
        category_id: SaPageInfo?.page_param?.category_id || '',
        result_type: SaPageInfo?.page_param?.result_type || payload.result_type,
        ...options,
      }
    }
    if(['expose_search', 'click_search', 'expose_top_site_search', 'click_top_site_search'].includes(activity_name)) {
      window.argResultContent = data?.activity_param?.result_content || '-'
    }
    sa('send', data)
  },
  doSearch({ keyword = '', result_type = 2, data: { position = 1, type, tag = '', attr = '' }, crowdId, originWord, pageType, trend_list_content = '', wordTagType = 'common' }) {
    if (!keyword || !result_type) throw new Error('search click analysis error')
    const srcModuleMap = {
      all: 'search',
      store: 'store_search',
      brand: 'chanel_search',
    }
    const data = {
      activity_name: 'click_top_site_search',
      activity_param: {
        search_content: result_type === 18 ? `${keyword} ${attr}` : sliceWord(keyword, result_type),
        result_content: result_type === 18
          ? `18\`${keyword} ${attr}\`${position}\`-\`${attr}\`${wordTagType}\`common`
          : `${result_type}\`${sliceWord(keyword, result_type)}\`${position}\`${type || '-'}\`${'-'}\`${tag || '-'}\`${wordTagType}`,
        abtest: AB_TEST(),
        crowd_id: crowdId || 0,
        src_module: srcModuleMap[pageType] ?? 'search',
        src_identifier: `st=${result_type}\`sc=${sliceWord(originWord, result_type)}${attr ? ' ' + attr : ''}\`sr=${result_type == 8 ? keyword : '0'}\`ps=${position}`,
        trend_list_content,
        entrancetype: getEntrancetypeVal()
      }
    }
    window.argResultContent = data?.activity_param?.result_content || '-'
    sa('send', data)
  },
  // 全站搜索入口曝光
  exposeSearch(payload) {
    if(!payload) {
      window.argResultContent = '-'
    }
    !payload
      ? /*console.log('expose_search') ||*/ sa('send', {
        activity_name: 'expose_search',
        activity_param: {
          abtest: AB_TEST(),
          Searchboxform: 1,
          entrancetype: getEntrancetypeVal(),
          result_content: '-'
        }
      })
      : this.searchInput('expose_search', payload, { Searchboxform: 2, entrancetype: getEntrancetypeVal(), })
  },
  // 全站搜索入口点击
  clickSearch(payload) {
    if(payload?.type === 'clickBackBtn') {
      window.argResultContent = '-'
    }
    payload?.type === 'clickBackBtn'
      ? /*console.log('click_search') ||*/ sa('send', {
        activity_name: 'click_search',
        activity_param: {
          abtest: AB_TEST(),
          Searchboxform: 1,
          entrancetype: getEntrancetypeVal(),
          result_content: '-'
        }
      })
      : this.searchInput('click_search', payload, { Searchboxform: 2, entrancetype: getEntrancetypeVal(), })
  },
  // 门店搜索入口曝光
  exposeStoreSearch(payload) {
    sa('send', {
      activity_name: 'expose_store_search',
      activity_param: {
        abtest: AB_TEST(),
        Searchboxform: payload.Searchboxform,
        search_content: payload.search_content,
        result_content: payload?.result_content || '',
        src_module: payload?.src_module || '',
        src_identifier: payload?.src_identifier || ''
      }
    })
  },
  // 专题搜索入口曝光
  exposeBrandSearch(payload) {
    sa('send', {
      activity_name: 'expose_chanel_search',
      activity_param: {
        abtest: AB_TEST(),
        Searchboxform: payload.Searchboxform,
        search_content: payload.search_content
      }
    })
  },
}
export default analysis
