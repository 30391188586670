const { langPath } = gbCommonInfo
const xtraRoutes = [
  {
    path: `${langPath}/xtra/:productCode`,
    name: 'page_xtar',
    meta: {
      keepAlive: false,
      notNeedCommonHeader: true,
      orderType: 'xtra',
    },
    component: () => import(/* webpackChunkName: "buy-prime-checkout" */ '../../buy_prime/container.vue')
  }
]

export {
  xtraRoutes
}
