import { isLogin } from 'public/src/pages/common/utils/index.js'
import { FooterRcmdListResolveStatus } from './constants'

export default {
  language: {},
  totalPoints: 0,
  surveyNum: 0,
  isLogin: isLogin(),
  isStudent: false,
  routerUrl: null,
  hadFreeTrial: false,
  orderCount: {
    unpaid: 0,
    processing: 0,
    shipped: 0,
    returns: 0,
    review: 0
  },
  couponNum: 0,
  balances: [],
  showUserCenterNotice: false,
  showUserCenterNoticeText: '',
  isRisk: false,
  isMainDataReady: false,
  advertise: {
    content: [],
    cateLinks: {}
  },
  apolloSiteUrl: {},
  followedStoreHaveNew: 0, // 关注的店铺里是否有上新 0-无，1-有
  isFollowClick: false, // 是否点击过follow
  isFollowClickWithinThreeDays: false, // 是否在3天内点击过follow

  /* new */
  moreServices: [],
  moreServiceConfig: {},
  isOpenReviewEntry: false,
  isOpenBonusDayEntry: false,
  isShowFriendshipCouponEntry: false,
  isOpenFreeTrialEntry: false,
  isOpenSheinxEntry: false,
  // isShowPointRemind: false,
  // SHEIN VIP
  isOldEsVip: false,
  vipSiteFrom: '',
  vipLevel: '',

  primeData: {},
  superSaveData: {},
  unpaidOrder: [],
  blackFiveConfig: {}, // 黑五配置
  unReviewOrder: {},
  personalReward: {}, // 个人资产内容
  visibleGuide: true, // 一键购个人中心组件是否展示
  news: {},
  unpaidOrderAbt: {
    PopType: 'A', // A：展示商品视角弹窗，B：展示商品+订单视角弹窗，Default：展示原有弹窗
    FloorType: 'Off', 
  },
  // 新人权益数据
  newUserBenefits: null,
  // 超省卡&付费会员融合楼层
  userRightsFusion: null,
  routeInfo: {},
  // 游戏赠送钱包金额临过期提示
  expireGameMoney: null,
  // 点击底部tab刷新页面开关
  enableBottomTabRefresh: false,
  // 底部推荐商品ccc配置信息
  footerRcmdCccConfig: null,
  // 底部推荐商品ccc配置信息获取状态
  footerRcmdListResolveStatus: FooterRcmdListResolveStatus.PENDING,
  refreshingPage: false,
  abtSource: {},
  sheinClubInfo: {},
  trendFlowData: [],
  firstTrendFlowPending: true,
  trendFlowAbt: {},
  trendFlowPageNum: 1,
}
