
import { getBffVersion } from 'public/src/pages/user/child_pages/user_bff_version/index.js'

const userNewsRouteComponent = (useBffApi = false) => {

  const isBff = typeof window === 'undefined' ? useBffApi : getBffVersion('userCenter_v1')

  return !isBff ? 
    () => import( /* webpackChunkName: "user_news_old" */ '../../user_news/index.vue') : 
    () => import( /* webpackChunkName: "user_news" */ '../../user_index/components/news/index.vue')
}

export const createUserNewsRoute = (langPath = '', useBffApi = false) => {
  return {
    path: `${langPath}/user/news`,
    name: 'user_news',
    meta: {
      keepAlive: true,
      pageType: 'user_news',
      monitorTag: 'user_news',
      isCssInVue: true,
      notNeedCommonHeader: true,
    },
    component: userNewsRouteComponent(useBffApi),
  }
}
