const { langPath } = gbCommonInfo
const liveRoutes = [
  {
    path: `${langPath}/live/:id`,
    component: () => import(/* webpackChunkName: "live" */ '../../live/container.vue'),
    name: 'page_live',
    meta: {
      pageType: 'live',
      scrollTop: true,
      isCssInVue: true,
      notNeedCommonHeader: true
    }
  }
]

export {
  liveRoutes
}
