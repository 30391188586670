export const createTrackRoute = (langPath = '') => {
  return {
    path: `${langPath}/orders/track`,
    name: 'ordersTrack',
    meta: {
      keepAlive: false,
      pageType: 'ordersTrack',
      monitorTag: 'orders-track',
      isCssInVue: true,
      notNeedCommonHeader: true,
    },
    component: () => import(/* webpackChunkName: "orders_track" */ '../../trackNew/container.vue') 
  }
}
