// 顶部搜索header 高度 1.17rem
export const TOP_HEADER_HEIGHT = 1.17

// 普通店铺 tab container 高度
export const TAB_CONTAINER_TAB_HEIGHT = 1.17

// 短店招服务保障吸顶腰带
export const SERVICE_LABEL_BELT_HEIGHT = 24 / 37.5

// 品牌店铺底部导航栏高度
export const BRAND_STORE_NAV_BAR_HEIGHT = 1.33333

// 促销吸底条高度
export const STORE_PROMOTION_BOTTOM_STRIP_HEIGHT = 34 / 37.5



// home Tab 复合页 CCC 组件白名单
export const homeTabCccWhitelist = {
  componentKey: [
    'STORE_INFO_COMPONENT',
    'IMAGE_COMPONENT',
    'IMAGE_CAROUSEL_COMPONENT',
    'STORE_COUPON_COMPONENT',
    'SHOPACTIVITY_COMPONENT',
  ],
  styleType: [
    'SELECTED_HORIZONTAL_PRODUCTS_DYNAMIC',
    'ACTIVITY_LITTLE_BANNER_DYNAMIC',
    'RANKING_ENTRANCE_RECOMMEND',
    'PLATFORM_HORIZONTAL_COUPON',
    'PLATFORM_VERTICAL_COUPON',
    'STORE_HORIZONTAL_ITEMS',
    'STORE_VERTICAL_ITEMS',
    'STORE_FLASH_SALE',
    'STORE_DISCOUNT_PRODUCTS_RECOMMEND',
    'STORE_VIDEO',
    'STORE_HOT_SEARCHTERM_RECOMMEND',
    'STORE_HOT_PRODUCTS_RECOMMEND',
    'STORE_NEW_ARRIVALS_RECOMMEND'
  ]
}
