let _requestAnimationFrame = null
if (typeof window !== 'undefined') {
  if (window.requestAnimationFrame) {
    _requestAnimationFrame = window.requestAnimationFrame
  } else {
    let vendors = ['ms', 'moz', 'webkit', 'o']
    for(let x = 0; x < vendors.length && !_requestAnimationFrame; ++x) {
      _requestAnimationFrame = window[vendors[x] + 'RequestAnimationFrame']
    }
  }
}

// 服务端渲染和浏览器不支持时直接返回，不使用setTimeout模拟
if (!_requestAnimationFrame) {
  _requestAnimationFrame = (cb) => {
    cb()
  }
}

export default _requestAnimationFrame
