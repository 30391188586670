// TODO: 暂时使用，9.12后使用sui-mobile的LazyMount替换
export default {
  name: 'LazyMount',
  props: {
    mountPropName: {
      type: String,
      default: 'visible',
    },
  },
  data() {
    return {
      isMounted: false,
    }
  },
  render(h) {
    const defaultSlotVnode = this.$slots.default[0]

    if (!defaultSlotVnode) return h(false)

    const defaultSlotMountProp =
      defaultSlotVnode.componentOptions.propsData[this.mountPropName]

    if (defaultSlotMountProp && !this.isMounted) {
      this.isMounted = true
    }

    return this.isMounted ? defaultSlotVnode : h(false)
  },
}
