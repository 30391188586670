
import schttp from 'public/src/services/schttp'
import { prefetchResource } from 'public/src/services/prefetchResource/index.js'
import { preloadImg } from 'public/src/pages/ccc-campaigns/components/common/utils.js'

let pendingding = null
const isImage = /\.(png|jpe?g|webp)$/i
export const createFlashSaleRoute = (langPath = '') => {
  return {
    path: `${langPath}/flash-sale.html`,
    name: 'flash_sale',
    meta: {
      keepAlive: true,
      pageType: 'flash_sale',
      monitorTag: 'flash-sale',
      isCssInVue: true,
      // toSavePosition: ['page_goods_detail'],
    },
    beforeEnter: (to, from, next) => {

      if (from.name && from.name != 'flash_sale') {
        if (window.flashSaleSSRData || gbCommonInfo.FLASH_PRERENDER_SWITCH !== 'on') {
          next()
          return
        }
        if (!pendingding ) {
          let dom
          pendingding = new Promise(resolve => {
            window._gb_app_.$store.state.rootStatus.exchangingSpaSceneInApp = true
            dom = document.querySelector('#prerender-loading')
            dom.style = 'display: block;'
            const query = to.query
            query.isPrefetch = true
            // header头部可能会在吸顶状态, 进行复位
            window.forceUpdateHeaderFixed?.({ forceFixed: false, routeName: 'flash_sale' })
            return schttp({ url: `/api/flash-sale/get`, params: query }).then((res) => {
              if (!res) {
                resolve()
                return
              }
              const { ssrHTML } = res
              let goodsImgs = undefined
              if (ssrHTML) {
                document.querySelector('#prerender-in-app').appendChild(document.createRange().createContextualFragment(ssrHTML).querySelector('#flash-sale-container'))
                document.querySelector('#prerender-css-flashsale').innerHTML = res.contextForSSR.styles || ''
                document.querySelector('#prerender-in-app #flash-sale-container').setAttribute('data-server-rendered', true)
  
                const imgs = Array.from(document.querySelectorAll('#prerender-in-app #flash-sale-container img.fsp-element')).map(ele => ele.src).filter(src => isImage.test(src)).slice(0, 6)
                if (imgs.length)  goodsImgs = preloadImg([... new Set(imgs)])
              }
              requestAnimationFrame(() => { 
                window.flashSaleSSRData = res.contextForSSR
                goodsImgs
                  ? goodsImgs.then(() => requestAnimationFrame(() => resolve()))
                  : requestAnimationFrame(() => resolve())
              })
             
             

            }).catch(e => {
              console.error(e)
              resolve()
            })
          }).then(() => {
            next()
            if (dom) {
              dom.style = 'display: none;'
            }
          }).catch((e) => {
            console.error(e)
            next()
          })
        } else {
          next()
        }
      } else {
        next()
      }
    },
    component: prefetchResource.importAsyncComponent({
      chunkName: 'flash_sale', // 跟webpackChunkName保持一致
      componentFactory: () => import( /* webpackChunkName: 'flash_sale' */ '../../flash_sale_v2/container.vue')
        .then(r => pendingding ? pendingding.then(() => {  return Promise.resolve(r) }) : Promise.resolve(r))
    }),
  }
}
