export const createFlashSaleSpecialsRoute = (langPath = '') => {
  return {
    path: `${langPath}/flash-sale-specials-zone.html`,
    name: 'flash_sale_specials_zone',
    meta: {
      keepAlive: true,
      pageType: 'flash_sale_specials_zone',
      monitorTag: 'flash-sale-specials-zone',
      isCssInVue: true,
      notNeedCommonHeader: true,
    },
    component: () => import(/* webpackChunkName: "flash_sale_specials_zone" */ '../../flash_sale_special_zone/App.vue')
  }
}
