const { langPath } = gbCommonInfo

const giftcardRoutes = [
  {
    path: '/giftcard.html',
    name: 'giftcard',
    meta: {
      code: 'giftcard',
      keepAlive: true,
      pageType: 'giftcard',
      notNeedCommonHeader: true,
      isCssInVue: true
    },
    component: () => import(/* webpackChunkName: "giftcard-home" */ '../../giftcard/child_pages/home.vue'),
  },
  {
    path: '/giftcard/checkout/first/:id',
    name: 'checkout',
    meta: {
      code: 'checkout',
      keepAlive: true,
      pageType: 'giftcard',
      notNeedCommonHeader: true,
      isCssInVue: true
    },
    component: () => import(/* webpackChunkName: "giftcard-checkout" */ '../../giftcard/child_pages/checkout.vue')
  },
  {
    path: '/giftcard/checkout/again/:id',
    name: 'checkout_again',
    meta: {
      code: 'checkout_again',
      keepAlive: false,
      pageType: 'giftcard',
      notNeedCommonHeader: true,
      isCssInVue: true
    },
    component: () => import(/* webpackChunkName: "giftcard-checkout-again" */ '../../giftcard/child_pages/checkout_again.vue')
  }
]

// 站点设置
giftcardRoutes.forEach((router) => {
  router.path = `${langPath}${router.path}`
})

export {
  giftcardRoutes
}
