import { prefetchResource } from 'public/src/services/prefetchResource/index.js'
import { preloadImg } from '../../ccc-campaigns/components/common/utils'

const { langPath } = gbCommonInfo

const checkoutRoutes = [
  {
    path: `${langPath}/checkout`,
    name: 'checkout',
    meta: {
      isCssInVue: true,
      keepAlive: false,
      notNeedCommonHeader: true,
      pageType: 'checkout',
      monitorTag: 'check-out'
    },
    // component: () => import(/* webpackChunkName: "checkout_index" */'../../checkout/container.vue')
    component: prefetchResource.importAsyncComponent({
      chunkName: 'checkout_index', // 跟webpackChunkName保持一致
      componentFactory: () => import( /* webpackChunkName: 'checkout_index' */ '../../checkout/container.vue')
    }),
    beforeEnter: (to, from, next) => {
      if(from.name != 'checkout'){
        // 非落地
        if(!window.checkoutPending) {
          return location.reload()
        }
        window.checkoutPending.then((res) => {
          let { contextForSSR, ssrHTML, ssrVueCSS, ssrSuiCSS, appendedSelector } = res || {}
          return new Promise(resolve => {
            if(ssrHTML && ssrVueCSS) {
              if (ssrSuiCSS && appendedSelector) {
                document.querySelector('#ssrSuiCSS').textContent += ssrSuiCSS
                window.gbSuiSsrData = [... new Set((window.gbSuiSsrData || []).concat(appendedSelector))]
              }
              let paymentIcon
              try {
                let { folded_position, paymentMethods } = contextForSSR.checkout.results
                if (folded_position > 0) {
                  paymentMethods = paymentMethods.slice(0, folded_position)
                }
                paymentIcon = preloadImg([].concat(...paymentMethods.map(e => [e.logo_url].concat(e.card_logo_list || []))), null, 1000)
              } catch (error) {
                console.error(error)
              }
              window._gb_app_.$store.state.rootStatus.exchangingSpaScene = true
              document.querySelector('#prerender-app').appendChild(document.createRange().createContextualFragment(ssrHTML).querySelector('#checkout-app'))
              document.querySelector('#prerender-css-checkout').innerHTML += ssrVueCSS
              document.querySelector('#prerender-app #checkout-app').setAttribute('data-server-rendered', true)
              requestAnimationFrame(() => { // 上屏前
                window.gbCheckoutSsrData = contextForSSR
                paymentIcon
                  ? paymentIcon.then(() => requestAnimationFrame(() => resolve())) // 第二帧出 icon, 再跳转
                  : requestAnimationFrame(() => resolve())
              })
            } else {
              window.gbCheckoutSsrData = contextForSSR
              resolve()
            }
          })
        }).then(() => next()).catch(reload => typeof reload === 'function' ? reload() : location.reload())
        window.checkoutPending = null
      } else {
        next()
      }
    },
  },
  {
    path: `${langPath}/checkout/guest_first_address`,
    name: 'guest_first_address',
    meta: {
      isCssInVue: true,
      keepAlive: true,
      noNeedEmitPageUnload: true,
      notNeedCommonHeader: true,
      pageType: 'first_address'
    },
    redirect () {
      location.href = `${gbCommonInfo.langPath}/404.html`
    },
  },
  {
    path: `${langPath}/checkout/first_address`,
    name: 'first_address',
    meta: {
      isCssInVue: true,
      keepAlive: true,
      noNeedEmitPageUnload: true,
      notNeedCommonHeader: true,
      pageType: 'first_address'
    },
    component: () => import(/* webpackChunkName: "checkout_first_address" */'../../checkout/first_address/container.vue')
  },
  {
    path: `${langPath}/checkout/blik_code_way`,
    name: 'blik_code_way',
    meta: {
      isCssInVue: true,
      keepAlive: false,
      notNeedCommonHeader: true,
      pageType: 'blik_code_way'
    },
    component: () => import(/* webpackChunkName: "checkout_blik_code_way" */'../../checkout/blik_code_way/container.vue')
  },
  {
    path: `${langPath}/checkout/paidy`,
    name: 'checkout-paidy-paylater',
    meta: {
      isCssInVue: true,
      keepAlive: false,
      notNeedCommonHeader: true,
      pageType: 'checkout-paidy-paylater'
    },
    component: () => import(/* webpackChunkName: "checkout-paidy-paylater" */'../../checkout/paidy/container.vue')
  },
  {
    path: `${langPath}/checkout/middlePage`,
    name: 'checkout-middle-page',
    meta: {
      isCssInVue: true,
      keepAlive: false,
      notNeedCommonHeader: true,
      pageType: 'checkout-middle-page'
    },
    component: () => import(/* webpackChunkName: "checkout-middle-page" */'../../checkout/middlePage/container.vue')
  }
]

export {
  checkoutRoutes
}
