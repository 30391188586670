import Vue from 'vue'
import { appValidBranch } from 'public/src/pages/common/analysis/branch.js'
import { isSwitchBffApiVersion } from 'public/src/pages/common/bff-new/index.js'
import {
  bannerFetch,
  BANNER_EXPORATION_TIME,
  removeApp,
  emitClearApp,
  handleEmitApp,
} from './utils.js'
import { branchCookie, setBranchCookie } from './branchCookie.js' 

export const installAppTopBanner = async ({ el, router, store, channelId } = {}) => {
  const dom = document.querySelector(el)
  if (!dom) return
  const useBffApi = isSwitchBffApiVersion(['home_v2'])?.['home_v2']
  const [resContent, { default: PublicBannerDiverter }] = await Promise.all([bannerFetch({ pageType: 'topBanner', useBffApi, channelId }), import('public/src/pages/components/publicBanner/publicBannerDiverter.vue')])

  const topBannerEventHide = () => {
    if (window.sheinBanner?.topBanner?.Local) {
      window.sheinBanner.topBanner.Local.setFlag = 'hide'
    }
    document.getElementById('top-banner')?.remove() // 删除占位元素
    emitClearApp({ type: 'topBanner' })
  }

  if (!resContent?.content?.length) {
    topBannerEventHide() // 无数据时，直接隐藏
    return
  }

  return new Vue({
    el: dom,
    router,
    store,
    render: (h) => {
      return h(PublicBannerDiverter, {
        props: {
          type: 'topBanner',
          resContent,
          useBffApi,
        },
        on: {
          showDone: el => {
            removeApp('shein_top_banner', { removeHeight: true })
            handleEmitApp({ type: 'topBanner', el })
          },
          // 当前组件会出现在某些状态下不进行展示。（业务逻辑倒计时结束）
          eventHide: () => {
            topBannerEventHide()
          },
        },
      })
    },
  })
}

export const installAppHeader = async ({ el, router, store, showcategory } = {}) => {
  const dom = document.querySelector(el)
  if (!dom) return

  const dispatchHeader = ({ el }) => {
    // 替换当前元素上的绝对定位改为相对定位
    el.style.position = 'relative'
    handleEmitApp({
      type: 'header',
      el,
      forced: true,
      cb: outerHTML => {
        const classNames = ['.j-common-header-center', '.common-logo-header__wishlist']
        classNames.forEach(className => {
          const target = el.querySelector(className)
          if (target?.outerHTML) {
            outerHTML = outerHTML.replace(target.outerHTML, '')
          }
        })
        return outerHTML
      },
    })
  }

  const CommonHeader = (await import('public/src/pages/components/common_header/header.vue')).default

  let flagRemove = false
  return new Vue({
    el: dom,
    router,
    store,
    render: (h) => {
      return h(CommonHeader, {
        on: {
          showDone: el => {
            if (!flagRemove) {
              removeApp('shein-header', { removeHeight: true })
              flagRemove = true
            }
            
            dispatchHeader({ el })
          },
          showcategory: () => {
            showcategory && showcategory()
          },
        },
      })
    },
  })
}

export const installAppPolicyBanner = async ({
  el,
  router,
  store,
  type = 'app',
} = {}) => {
  const dom = document.querySelector(el)
  if (!dom) return

  const [resContent, { default: PublicBannerDiverter }] = await Promise.all([bannerFetch({ pageType: 'policyBanner' }), import('public/src/pages/components/publicBanner/publicBannerDiverter.vue')])
  // 是否有免邮政策组件
  const isHasFreeshipping = resContent?.content?.some(item => item.styleType === 'FREE_SHIPPING_COMPONENT')
  if (!resContent?.content?.length || isHasFreeshipping) {
    // 删除占位元素
    if (type === 'app') {
      document.getElementById('shein-policy-app')?.remove()
    } else {
      document.getElementById('home-policy-app')?.remove()
    }
    gbCommonInfo.hasPolicyBanner = false
    return
  }

  return new Vue({
    el: dom,
    router,
    store,
    render: (h) => {
      return h(PublicBannerDiverter, {
        props: {
          type: 'policyBanner',
          resContent,
        },
        on: {
          showDone: el => {
            removeApp('shein-policy', { removeHeight: true })
            handleEmitApp({ type: 'policyBanner', el, forced: true })
          },
        },
      })
    },
  })
}

/**
 * branch逻辑展示：通过当前页是否展示， 展示后保存当前页的type到cookie中；
 * */
export const installAppBranch = async ({ router, store }) => {
  const { cookieValue, branchCookieKey } = branchCookie()

  if (cookieValue === 'c') {
    // 用户关闭后时间还没有过期，不再展示。
    document.getElementById('branch-app')?.remove()
    return
  }

  const showPages = (cookieValue ? cookieValue.split('_') : []).filter(item => item)
  let href = location.pathname
    .replace(`/${gbCommonInfo.lang}`, '')
    .toLowerCase()
  if (href === '/') {
    href = '-indexHome-'
  }

  const isCache = showPages.some(v => new RegExp(v).test(href)) && !document.querySelector('.branch-first')

  const valid = await appValidBranch()
  if (!valid) {
    document.getElementById('branch-app')?.remove()
    return
  }

  const journeyBranchCompoment = await import(
    /* webpackChunkName: "journey-branch-async" */ 'public/src/pages/components/journey-branch'
  )

  const branchDispach = ({ el, forced }) => {
    const strs = [
      'vue-journey-fade-slide-top-enter',
      'vue-journey-fade-slide-top-enter-active',
      'v-enter',
      'v-enter-active',
    ] // 存储html字段删除动画style
    return handleEmitApp({
      type: 'branch',
      el,
      forced,
      cb: outerHTML => {
        outerHTML = strs.reduce((prev, cur) => prev.replace(cur, ''), outerHTML)
        return outerHTML.replace('position: fixed', 'position: absolute')
      },
    })
  }

  return new Vue({
    el: '#shein-branch',
    router,
    store,
    render: h => {
      return h(journeyBranchCompoment.default, {
        props: {
          cacheBranch: isCache,
        },
        on: {
          showDone(el, { showHostPages = [] } = {}) {
            removeApp('branch-site', { removeHeight: false }) // 只删除占位元素， 不删除父容器的占位的高度，组件内部自行控制
            setBranchCookie({
              key: branchCookieKey,
              value: showHostPages.join('_'),
              end: BANNER_EXPORATION_TIME,
            })
            branchDispach({ el })
          },
          /**
           * 点击关闭x按钮进行关闭时触发
           * expirationTime 毫秒
           */
          eventHide: expirationTime => {
            document.getElementById('branch-app')?.remove()
            setBranchCookie({
              key: branchCookieKey,
              value: 'c',
              end: expirationTime / 1000,
            })
            emitClearApp({ type: 'branch' })
          },

          updateDom(el) {
            branchDispach({ el, forced: true })
          },
        },
      })
    },
  })
}
