import TMG from '@shein/time-management-guru'
import { campaignsQuickRequests } from './modules/campaigns' 
import { pageGoodsDetailRequests, pageGoodsDetailLanguageRequests } from './modules/goods_detail'
import { configIndexQuickRequests } from './modules/config_index'
import { categoryRequests, sideCategoryRequests, categoryBffRequests } from './modules/category/index.js'
import { robotRequests } from './modules/robot'
import shellJSON from '../../../configs/sw/shell.json'

(() => {
    if (typeof window === 'undefined' || window.__IS_REGISTERED_QUICK_REQUESTS__) return

    TMG.registerQuickRequests({
      globalConfig: {
        // 使用sw的版本作为缓存版本
        version: shellJSON.shell_ver,
        globalCacheKeys: {
          siteUid: gbCommonInfo.SiteUID,
          lang: gbCommonInfo.appLanguage,
        }
      },
      modules: {
        campaigns: campaignsQuickRequests,
        // 商详(route name page_goods_detail)
        pageGoodsDetail: pageGoodsDetailRequests,
        pageGoodsDetailLanguage: pageGoodsDetailLanguageRequests,
        configIndex: configIndexQuickRequests,
        category: categoryRequests,
        sideCategory: sideCategoryRequests,
        bffCategory: categoryBffRequests,
        robot: robotRequests
      }
    })

    // 已注册
    window.__IS_REGISTERED_QUICK_REQUESTS__ = true
})()
