const { langPath, language } = gbCommonInfo
const sheinxRoutes = [
  {
    path: `${langPath}/sheinx`,
    component: () => import('../../sheinx/home.vue'),
    name: 'sheinxHome',
    meta: {
      isCssInVue: true,
      navTitle: language.SHEIN_KEY_PWA_14575,
      notNeedCommonHeader: true,
      keepAlive: false
    }
  },
  {
    path: `${langPath}/sheinx/commission`,
    component: () => import('../../sheinx/commission.vue'),
    name: 'sheinxCommission',
    meta: {
      isCssInVue: true,
      navTitle: language.SHEIN_KEY_PWA_18642,
      notNeedCommonHeader: true,
      keepAlive: false
    }
  },
  {
    path: `${langPath}/sheinx/design`,
    component: () => import('../../sheinx/design.vue'),
    name: 'sheinxDesign',
    meta: {
      isCssInVue: true,
      navTitle: language.SHEIN_KEY_PWA_18643,
      notNeedCommonHeader: true,
      keepAlive: false
    }
  }
]

// 站点设置
sheinxRoutes.forEach((router) => {
  router.meta.pageType = 'sheinx'
})


export {
  sheinxRoutes
}
