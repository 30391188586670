var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"legacy-header",style:({
    transform: _vm.GB_cssRight ? (_vm.showSearchStoreNewStyle ? 'translateX(-0.96rem)' : _vm.isStoreSearchPage ? 'translateX(-0.56rem)' : '' ) : 
      (_vm.showSearchStoreNewStyle ? 'translateX(0.96rem)' : _vm.isStorePageOrSearchPage ? 'translateX(0.56rem)' : '')
  })},[_c('CommonHeaderInput',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowInput),expression:"isShowInput"}],key:_vm.initSearchBoxLogic,style:({
      width: _vm.showSearchStoreNewStyle
        ? '7.44rem'
        : (_vm.isStorePageOrSearchPage)
          ? '6.78rem'
          : ''
    }),attrs:{"isShowInput":_vm.isShowInput,"storeDefaultWords":_vm.storeDefaultWords,"autoplay":_vm.needSearchAutoPlay,"start-deal":_vm.dealDefaultWords,"abtDefaultWords":_vm.abtDefaultWords,"goodsId":_vm.goodsId,"commonHeaderBg":_vm.commonHeaderBg,"showDefaultWordsState":_vm.showDefaultWordsState,"cateIds":_vm.cateIds,"selectId":_vm.selectId,"bizScene":_vm.bizScene,"isCatNameSearchWrods":_vm.isCatNameSearchWrods,"defaultWordsProp":_vm.listDefaultWordsProp ? _vm.listDefaultWordsProp : [],"page-type":_vm.searchEntranceBtnType,"useBff":_vm.useBffApi?.['preSearch_v1'],"isHitBlackListTitle":_vm.isHitBlackListTitle},on:{"update:startDeal":function($event){_vm.dealDefaultWords=$event},"update:start-deal":function($event){_vm.dealDefaultWords=$event},"initMiddlePage":function($event){return _vm.goPresearch({ camera: 1 })},"clickSearchBar":_vm.goPresearch,"uploadSuccessHandler":_vm.uploadSuccessHandler,"handleProductListSearchWords":_vm.handleProductListSearchWords,"cacheListDefaultWordsReq":_vm.cacheListDefaultWordsReq}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }