import { percentNumSign } from '@/public/src/pages/common/promotion/utils/common.js'
import { getPromotionByIds } from './getPromotion'

class BestCoupon {
  constructor({ language }) {
    this.language = language
    this.typeIds = [20, 21] // 参与利诱点信息的活动类型
  }

  async getData(res, config) {
    const {
      isNewuserStyle = false
    } = config

    const language = await this.language

    const { cartBriefInfo } = res || {}

    let tips = ''
    let iconText = ''
    let iconTextOff = ''
    let couponNum = 0
    let lurePointItemType = null // 金额券：6 折扣券：5 免邮券：7 免邮金额抵扣券/运费券：8  券包该值为 null
    let identify = ''

    // 满足门槛的订单返券活动
    const promotions = cartBriefInfo.promotion_range?.filter(f => f.range > 0 && this.typeIds.includes(f.type_id))

    if (promotions?.length) {
      const promotionInfos = await getPromotionByIds(promotions.map(m => m.promotion_id))
      const maxPromotion = this.getMaxPromotion(promotionInfos)
      if (maxPromotion) {
        const newuserTag = !!promotionInfos.find(promo => this.isNewUserReturnCoupon(promo))
        const isNewuserTip = isNewuserStyle && newuserTag

        couponNum = maxPromotion.coupon_num
        identify = maxPromotion.promotion_id

        if (maxPromotion.coupon_num === 1) {
          tips = isNewuserTip ? language.SHEIN_KEY_PWA_33192 : language.SHEIN_KEY_PWA_33193
          const coupon = maxPromotion.coupons[0]
          const isFreeShipppingCoupon = [5, 6].includes(+coupon.apply_for)
          const isPriceFreeShippingCoupon = +coupon.apply_for === 9
          const isPercentCoupon = [2, 5].includes(+coupon.rule_dimension)
          if (isFreeShipppingCoupon) {
            lurePointItemType = 7
            iconText = language.SHEIN_KEY_PWA_33202
          } else if (isPriceFreeShippingCoupon) {
            lurePointItemType = 8
            const maxMen = coupon.rule?.[coupon.rule?.length - 1]?.value
            iconText = isPercentCoupon ? percentNumSign(maxMen?.amount) : maxMen?.amountWithSymbol
            iconTextOff = language.SHEIN_KEY_PWA_31507
          } else {
            lurePointItemType = isPercentCoupon ? 5 : 6
            const maxMen = coupon.rule?.[coupon.rule?.length - 1]?.value
            iconText = isPercentCoupon ? percentNumSign(maxMen?.amount) : maxMen?.amountWithSymbol
            iconTextOff = language.SHEIN_KEY_PWA_31507
          }
        } else if (maxPromotion.coupon_num > 1) {
          tips = isNewuserTip ? language.SHEIN_KEY_PWA_33194 : language.SHEIN_KEY_PWA_33195
          // 全金额券
          if (maxPromotion.all_full_reduce_flag == 1) {
            iconText = maxPromotion.total_value.amountWithSymbol
            iconTextOff = language.SHEIN_KEY_PWA_33204
          } else {
            iconText = 'SALE'
          }
        }
      }
    }

    return {
      tag: false,
      isPopover: true,
      identify,
      tips,
      couponNum,
      lurePointItemType,
      iconText,
      iconTextOff,
    }
  }

  getMaxPromotion(promotionInfos) {
    if (!promotionInfos?.length) return null

    let maxPromo = null
    promotionInfos.forEach(promo => {
      // 订单返券是单档活动
      if (!maxPromo || +maxPromo.rules[0].value_amount.amount < +promo.rules[0].value_amount.amount) {
        maxPromo = promo
      }
    })
    return maxPromo
  }

  isNewUserReturnCoupon(promotionInfo) {
    return !!(promotionInfo.coupons?.find(coupon => coupon.type_id == gbCommonInfo.NEW_USER_COUPON_TYPE_ID))
  }
}

export default BestCoupon
