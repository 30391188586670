
import storePageDataManager from 'public/src/pages/store_pages/js/storePageDataManager.js'
import { prefetchResource } from 'public/src/services/prefetchResource/index.js'

const path = [
  'store/home',
  '\.+-store-\\d{10}.html' // store reg
]


export const getStoreComponent = () => {
  return {
    component: prefetchResource.importAsyncComponent({
      chunkName: 'store_container', // 跟webpackChunkName保持一致
      componentFactory: () => import( /* webpackChunkName: 'store_container' */ '../../store_pages/StoreContainer.vue')
        .then(r => {
          if (typeof window === 'undefined') {
            return Promise.resolve(r)
          }
          return !window.__store_promise_preload_html ? Promise.resolve(r) : window.__store_promise_preload_html.then(() => { window.__store_promise_preload_html  = null; return Promise.resolve(r) })
        })
    })
  }
}

export const createStoreRoute = langPath => ({
  path: `${langPath}/(${path.join('|')})`,
  name: 'page_store',
  meta: {
    pageType: 'page_store'
  },
  ...getStoreComponent(),
  beforeEnter: (to, from, next) => {
    // spa 客户端请求提前
    if(typeof window !== 'undefined' && typeof gbCommonInfo !== 'undefined'){
      if(gbCommonInfo?.contextForSSR?.cat_info?.type !== 'store' || storePageDataManager.pageSsrDataResolved){
        storePageDataManager.prerenderRequest(to);
      }
    }
    next();

  },

  meta: {
    keepAlive: true,
    isCssInVue: true,
    pageType: 'page_store',
    monitorTag: 'page_store',
    noNeedEmitPageUnload: true,
  },
})

