import createApp from './app'
import { mixin } from './client_mixin'
import { routes, clientFunc } from './router/client'

import cartTagTips from './util/cartTagTips/index.js'
import _requestAnimationFrame from 'public/src/pages/common/renderFrameMixin/polyfill.js'
import { markPoint } from 'public/src/services/mark/index.js'
import { businessLibInitializeForCSR } from './business-lib-initialize/csr'

const { contextForSSR } = gbCommonInfo

window._gb_cart_tag_tips_ = cartTagTips

const { app, router, store } = createApp(contextForSSR, {
  routes,
  mixin,
  clientFunc
})

businessLibInitializeForCSR({ router })

router.beforeResolve((to, from, next) => {
  const matched = router.getMatchedComponents(to)
  const prevMatched = router.getMatchedComponents(from)
  let diffed = false
  const activated = matched.filter((c, i) => {
    return diffed || (diffed = (prevMatched[i] !== c) || to.name === 'config_activity' || to.name === 'campaigns')
  })
  const asyncDataHooks = activated.map(c => c.asyncData).filter(_ => _)
  if (!asyncDataHooks.length) {
    return _requestAnimationFrame(next)
  }
  // 执行asyncData hooks 传递直出的数据
  Promise.all(asyncDataHooks.map(hook => {
    markPoint({ eventName: 'pageAsyncData' })
    return hook({
      store,
      context: contextForSSR,
      to,
      route: to,
      from,
    })
  }))
    .then((res) => {
      if(res?.[0] == 'uninstall') {
        delete activated[0].asyncData
      }
      _requestAnimationFrame(next)
    })
    .catch(next)
})

// 由于 _gb_app 必须在 router.onReady 中注册，而在注册之前可能被 EventBus 事件绑定使用，所以在未注册前将事件暂存
// 进队列，待注册后重新执行绑定
let gbApp
Object.defineProperty(window, '_gb_app_', {
  get () {
    return gbApp
  },
  set (v) {
    gbApp = v
  }
})

router.onReady(() => {
  // 暴露全局变量 mounted 之前window._gb_app_ 都为undefined
  window._gb_app_ = app.$mount('#app')

  window._gb_cart_tag_tips_?.initialize()
})

markPoint({ eventName: 'productAppJsLoaded', measureTo: 'pageAsyncData' })
