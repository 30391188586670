
/**
 * 解决同时调用同一个异步任务，只想触发一次异步任务，共用响应结果的场景
 * @param {Function} asyncTask 异步任务
 * @param {Object} options 选项 { wait: (ms)在响应成功wait时间内的调用，直接返回第一次响应的结果 }
 * @returns 
 */
export function useAsyncSingleCall (asyncTask, options = {}) {
  const waitQueue = []
  const { wait } = options
  let waitTimer = null
  let waitCacheData = null
  let isSuccessFinishSingleCall = false
  
  // 清空等待队列
  const flushWaitQueue = (flushType, data) => {
    waitQueue.forEach(task => {
      flushType === 'success' ? task.resolve.call(null, data) : task.reject.call(null, data)
    })
  
    waitQueue.length = 0
  }
  
  // wait后重置等待状态
  const asyncResetWaitState = () => {
    if (waitTimer !== null) return
  
    waitTimer = setTimeout(() => {
      waitCacheData = null
      waitTimer = null
      waitQueue.length = 0
  
      isSuccessFinishSingleCall = false
    }, wait)
  }
    
  return function factoryTask (...args) {
    return new Promise((resolve, reject) => {
      // 1. 收集调用者
      waitQueue.push({ resolve, reject })
  
      // 2. 拦截条件
      // 在响应成功wait时间内的调用，直接返回第一次响应的结果
      if (wait && isSuccessFinishSingleCall) {
        flushWaitQueue('success', waitCacheData)
        return
      }
  
      // 拦截响应成功前的调用
      if (waitQueue.length > 1) return
  
  
      // 3. 异步任务开始
      // 4. 异步任务结束 => 调用等待队列中的任务，清空等待队列
      asyncTask.apply(null, args).then((data) => {
        flushWaitQueue('success', data)
  
        if (wait) {
          waitCacheData = data
          isSuccessFinishSingleCall = true
  
          asyncResetWaitState()
        }
      }).catch(error => {
        flushWaitQueue('error', error)
      })
    })
  }
}
  
