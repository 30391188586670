import { getCurrentInstance, ref, onMounted, computed } from 'vue'
import { abtservice } from 'public/src/services/abt'
import { useGlobalMapState } from './store.js'

export default function useSearchIcon({ route, pageScene }) {
  const { proxy: vm = {} } = getCurrentInstance()
  const { goodsDetailInfo } = useGlobalMapState(['goodsDetailInfo'])

  // 是否展示搜索框，false展示icon，true展示框
  const isShowInput = ref(false)
  const handleIsShowInput = (isShow) => {
    isShowInput.value = isShow
  }

  const extendsReport = { // 扩展埋点字段用
    result_content: '-'
  }

  const goPresearch = function () {
    if (vm.$route.mate) {
      vm.$route.mate.isNewPage = true
    }
    if (vm.$route.params) {
      vm.$route.params.isNewPage = true // 加不了mate ，就加在params里
    }
  }

  const searchAbtResult = ref('')
  const preSearchEntry = ref(null)
  const currentPageName = ref('')
  const diaplay = computed(() => {
    return !isShowInput.value && route.value?.meta?.notNeedCommonHeader === true
  })
  const routeQuery = computed(() => {
    return {
      pageType: 'all',
      pageName: currentPageName.value,
      pagefrom: currentPageName.value,
      ...(['goodsDetail'].includes(pageScene.value) && goodsDetailInfo.value?.goodsId ? { goods_id: goodsDetailInfo.value?.goodsId } : null),
      ...(['goodsDetail'].includes(pageScene.value) && goodsDetailInfo.value?.goodsId ? { cate_id: goodsDetailInfo.value?.goodsId } : null),
    }
  })

  onMounted(async () => {
    // 获取埋点实验
    const newPosKeys = ['SearchSuggestNew', 'SearchDefaultNew', 'SearchHotGD', 'SearchHotNew', 'MoreSearchWord', 'NewPicSearch', 'HomeSearch', 'SearchSimilar', 'Similaritems']
    const posKeys = ['SearchSuggestwordFeedback', 'PicSearch']
    const posKeysParams = {
      newPosKeys: newPosKeys.join(),
      posKeys: posKeys.join()
    }
    await abtservice.getUserAbtResult(posKeysParams)
    searchAbtResult.value = abtservice.getUserAbtResultForAnalysis({
      posKeys: [...newPosKeys, ...posKeys].join()
    }).sa
    diaplay.value && preSearchEntry.value?.exposeReport({
      abtest: searchAbtResult.value
    })
    currentPageName.value = window.SaPageInfo?.page_name
    appEventCenter.$on('pageOnload', () => {
      currentPageName.value = window.SaPageInfo?.page_name
      diaplay.value && preSearchEntry.value?.exposeReport({
        abtest: searchAbtResult.value
      })
    })
  })
  return {
    isShowInput,
    goPresearch,
    preSearchEntry,
    searchAbtResult,
    handleIsShowInput,
    searchIconQuery: routeQuery,
    searchIconExtendsReport: extendsReport,
  }
}
