import Vue from 'vue'

class GroupDrawer {
  constructor() {
    this.compInstance = null
  }

  async use(config, cb) {
    if (!this.compInstance) {
      const component = await import(/* webpackChunkName: "wishlist_group_drawer" */ './index.vue')
      const vm = new Vue({
        render (h) {
          return h(component.default)
        }
      }).$mount()
      document.body.appendChild(vm.$el)
      const comp = vm.$children[0]
      this.compInstance = comp
    }
    
    return await this.compInstance.use(config, cb)
  }
}

export default (...arg) => {
  const groupDrawer = new GroupDrawer()
  return groupDrawer.use(...arg)
}
