<template>
  <div
    class="legacy-header"
    :style="{
      transform: GB_cssRight ? (showSearchStoreNewStyle ? 'translateX(-0.96rem)' : isStoreSearchPage ? 'translateX(-0.56rem)' : '' ) : 
        (showSearchStoreNewStyle ? 'translateX(0.96rem)' : isStorePageOrSearchPage ? 'translateX(0.56rem)' : '')
    }"
  >
    <CommonHeaderInput
      v-show="isShowInput"
      :key="initSearchBoxLogic"
      :style="{
        width: showSearchStoreNewStyle
          ? '7.44rem'
          : (isStorePageOrSearchPage)
            ? '6.78rem'
            : ''
      }"
      :isShowInput="isShowInput"
      :storeDefaultWords="storeDefaultWords"
      :autoplay="needSearchAutoPlay"
      :start-deal.sync="dealDefaultWords"
      :abtDefaultWords="abtDefaultWords"
      :goodsId="goodsId"
      :commonHeaderBg="commonHeaderBg"
      :showDefaultWordsState="showDefaultWordsState"
      :cateIds="cateIds"
      :selectId="selectId"
      :bizScene="bizScene"
      :isCatNameSearchWrods="isCatNameSearchWrods"
      :defaultWordsProp="listDefaultWordsProp ? listDefaultWordsProp : []"
      :page-type="searchEntranceBtnType"
      :useBff="useBffApi?.['preSearch_v1']"
      :isHitBlackListTitle="isHitBlackListTitle"
      @initMiddlePage="goPresearch({ camera: 1 })"
      @clickSearchBar="goPresearch"
      @uploadSuccessHandler="uploadSuccessHandler"
      @handleProductListSearchWords="handleProductListSearchWords"
      @cacheListDefaultWordsReq="cacheListDefaultWordsReq"
    />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { storeModuleName } from 'public/src/pages/product_app/store/modules/store_pages'
import {
  throttle,
  transformImg,
  getQueryString,
  setSessionStorage
} from '@shein/common-function'
import { getStoreCodeFromUrl } from 'public/src/pages/store_pages/js/utils.js'
import {
  uploadImgApi,
  uploadImgBffApi
} from 'public/src/services/api/upload.js'
import schttp from 'public/src/services/schttp'
import { isLogin } from 'public/src/pages/common/utils/index.js'

import { LIST_TABLE, DA_CATEGORY_MAP, DA_CATEGORY_MAP2 } from './utils/const.js'
import CommonHeaderInput from './search_input/scene/commonHeaderInput.vue'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import { markPoint } from 'public/src/services/mark/index.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { isSwitchBffApiVersion } from 'public/src/pages/common/bff-new/index.js'

import ControlAsync from './utils/controlAsync.js'
const headerAnalysis = new ControlAsync({
  file: () =>
    import(/* webpackChunkName: "header-analysis" */ './analysis/index.js')
}).run()
const searchAnalysis = new ControlAsync({
  file: () =>
    import(
      /* webpackChunkName: "header-search-analysis" */ 'public/src/pages/common/search_words/analysis/index.js'
    )
}).run()

const {
  tabActive,
  is_include_critical_css,
  cccSkinHeaderLogo,
  PUBLIC_CDN,
  lang,
  WEB_CLIENT,
  IS_RW,
  language,
  langPath,
  GB_cssRight,
  IMG_LINK,
} = gbCommonInfo
// 处理埋点重复曝光问题
let exposeSearchTextArr = []
// 当前页面黑名单请求缓存
const searchWordBlackReqCacheMap = new Map()
export default {
  name: 'LegacyHeader',
  components: {
    CommonHeaderInput,
  },
  props: {
    iconColor: {
      type: String,
      default: '#000'
    },
    showSearchStoreNewStyle: {
      type: Boolean,
      default: false
    },
    showStoreDefaultWords: {
      type: Boolean,
      default: false
    },
    isStoreSearchPage: {
      type: Boolean,
      default: false
    },
    isStorePageOrSearchPage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      useNewInput: true,
      // useNewInput: false,
      IMG_LINK,
      lang,
      language,
      addScreenLanguage: null,
      tabActive,
      is_include_critical_css,
      IS_RW,
      cccSkinHeaderLogo,
      addScreenIcon: false,
      searchEntranceBtnType: 'all', // 搜索分为全站搜索以及店铺搜索
      daCategory: 'Home',
      WEB_CLIENT,
      PUBLIC_CDN,
      langPath,
      GB_cssRight,
      installCartDrainage: false,
      headerPresearchScroll: false,
      scanResultShowed: false,
      showSearchIndex: false,
      showSearchCategory: false,
      currPageExposed: false,
      outPointInfo: {},
      loadHeaderPresearch: false,
      abtDefaultWords: {},
      abtShowSearchButton: false,
      abtSearchResultShowCamera: false, // 搜索结果页是否显示图片搜索
      dealDefaultWords: false,
      TopSucking: {}, // 搜索框固定实验
      searchFixed: false, // 搜索结果页固定搜索框
      showCartIcon: true, // 购物车展示（针对泛列表场景特殊处理，考虑在业务场景里处理可能会遗漏和闪动，所以放在这）
      showCardIconAbt: false,
      currentKeyWord: '', // 搜索词
      catInfo: {},
      showListCardIconAbt: false,
      showDetailCardIconAbt: false, // 商详展示购物车icon控制
      currentWord: {},
      routeName: '',
      storeRightCornerShowMore: false,
      goodsId: '', // goodsDetailInfo?.goodsId 返回不会变，所以从route拿
      storeDefaultWords: [],
      abtSBCListshowsearch: false, // SBC列表滑动时候，是否展示悬浮搜索按钮
      abtCateListshowsearch: false, // Category列表滑动时候，是否展示悬浮搜索按钮
      hideHeaderRight: false,
      storeSearchStretched: false,
      catName: '',
      abtListshowsearch: false, // 真实和选品场景是否展示悬浮搜索按钮实验
      abtRealListshowsearch: false, // 真实场景是否展示悬浮搜索按钮实验
      abtSelectLsitshowsearch: false, // 选品场景是否展示悬浮搜索按钮实验
      abtPicksListshowsearch: false, // picks是否展示悬浮搜索按钮实验
      abtListTitleTypeVal: '', // 真实和选品场景是否展示搜索框实验配置
      abtRealListTitleTypeVal: '', // 真实场景是否展示搜索框实验配置
      abtSelectListTitleTypeVal: '', // 选品场景是否展示搜索框实验配置
      abtPicksListTitleTypeVal: '', // picks场景是否展示搜索框实验配置
      inputNewStyle: false, // 搜索框样式强化
      cateIds: '', // 用于存储列表场景的类目id
      selectId: '', // 用于存储列表场景的选品id
      bizScene: '', // 给底纹词接口的场景字段
      isBackFromDetail: false, // 是否是从商详返回的
      abtListShadingWord: false, // 真实/选品/picks等场景底纹词策略
      showDefaultWordsState: false, // 搜索框是否展示底纹词
      abtSearchDefaultNew: {},
      abtSearchHotGD: {},
      isCatNameSearchWrods: false, // 是否类目名称/页面名称作为搜索词
      cacheListDefaultWordsKey: '', // 用于缓存最新的底纹词请求结果的key
      listDefaultWordsProp: null, // 请求的列表类目底纹词存在缓存的时候，传给input使用，减少重复请求
      searchStoreNewStyle: false, // 店铺搜索框强化实验
      abtNewStoreiconchange: false, // 店铺头部icon实验
      abtFollowBtnEntry: false, // 店铺关注店铺入口强化样式实验

      isHitBlackListTitle: false, // 标题作为搜索词时，该标题是否命中黑名单
    }
  },
  computed: {
    ...mapState([
      'redpoint',
      'indexUnReadInfo',
      'commonHeaderBg',
      'goodsDetailInfo',
      'messageViewMode',
      'unReadChatNum',
      'searchSelectKeyWord'
    ]),
    ...mapState(storeModuleName, ['Language', 'Results']),
    storeResults() {
      return this.Results || {}
    },
    // 店铺相关多语言
    storeLanguage() {
      return this.Language || {}
    },
    isOldView() {
      return this.messageViewMode === 'Old' || !this.messageViewMode
    },
    isConfigIndexPage() {
      return this.routeName === 'config_index'
    },
    isGoodsDetailPage() {
      return this.routeName === 'page_goods_detail'
    },
    isNewCampaignPage() {
      return (
        this.routeName === 'config_activity' &&
        this.$route?.query?.from_tabbar_new === '1'
      )
    },
    isSearchResultPage() {
      return /pdsearch/.test(this.$route?.params?.pathMatch)
    },
    // 判断是否显示返回按钮
    showBackButton() {
      const excludeList = [
        'config_index',
        'page_category',
        'wishlistGroupShare'
      ]
      // 专题 new 频道需要隐藏后退按钮
      return !excludeList.includes(this.routeName) && !this.isNewCampaignPage
    },
    // 判断当前是否显示红点（红点只在首页和收藏页才可能显示）
    showRedpoint() {
      return this.redpoint > 0
    },
    existOrderUnRead() {
      let { orderUnRead = 0, orderOrNewsUnRead } = this.indexUnReadInfo
      return this.isOldView ? orderOrNewsUnRead : orderUnRead
    },
    existActivityOrPromoUnreadNum() {
      let {
        activityUnreadNum = 0,
        promoUnreadNum = 0,
        newsUnreadNum = 0
      } = this.indexUnReadInfo
      return this.isOldView
        ? activityUnreadNum + promoUnreadNum
        : activityUnreadNum + promoUnreadNum + newsUnreadNum
    },
    isShowMessagePoint() {
      let {
        activityUnreadNum = 0,
        promoUnreadNum = 0,
        orderOrNewsUnRead = 0
      } = this.indexUnReadInfo
      // 登录的话 展示order或者news的数字 如果这两个数字都没有的情况下 活动促销有值的话 显示红点
      // 不登录的话 活动促销有值的话 显示红点
      if (isLogin()) {
        return !!(activityUnreadNum + promoUnreadNum + orderOrNewsUnRead)
      } else {
        return !!(activityUnreadNum + promoUnreadNum)
      }
    },
    needSearchAutoPlay() {
      return this.isShowInput || this.showDefaultWordsState
    },
    needExposedSearch() {
      return this.isShowInput && !this.currPageExposed
    },
    // 搜索结果页隐藏图搜按钮
    hideSearchInputPicSearch() {
      // if ('config_index' == this.routeName) {
      if (['config_index', 'page_goods_detail'].includes(this.routeName)) {
        // 首页、商详固定展示出相机icon
        // 其他固定展示情况： picks、选品列表页 在搜索框内部处理了，不再头部处理
        return false
      }
      return (
        (!this.abtSearchResultShowCamera &&
          this.routeName === 'product-list-v2' &&
          this.$route.path.includes('/pdsearch')) ||
        ['store', 'brand'].includes(this.searchEntranceBtnType) ||
        !this.abtSearchResultShowCamera
      )
    },
    // 从店铺活动组件进入的选品页屏蔽搜索入口
    hideSearchEntry() {
      return (
        ['shop-activity', 'store-coupon'].includes(this.$route?.query?.comp) &&
        (this.$route?.meta?.type === 'selection' ||
          this.$route?.query?.src_module === 'storeHomePage')
      )
    },
    isShareIconShow() {
      return this.isGoodsDetailPage
    },
    isBrandStore() {
      return !!this.$store.getters['store_pages/isBrandStore']
    },
    // 是否显示搜索框
    isShowInput() {
      // 触发搜索框吸顶时展示
      if (this.searchFixed) return true
      return this.isFiexdRuleShowInput
    },
    // 是否因为固定的规则固定展示搜索框
    isFiexdRuleShowInput() {
      // 从店铺活动组件进入的选品页屏蔽搜索入口
      if (this.hideSearchEntry) return false

      // 分类页面并且实验开启
      if (this.routeName === 'page_category' && this.showSearchCategory)
        return true

      // 店铺页展示
      if (this.checkIsPageStore(this.$route.name)) return true

      if (this.headerPresearchScroll) {
        // 首页实验开启和促销页面滚动
        if (this.isConfigIndexPage && this.showSearchIndex) return true // 在首页
        if (this.isNewCampaignPage) return true // 新的专题场景
        if (this.isGoodsDetailPage) return true // 商详实验开启
      }

      const type = this.$route?.meta?.type
      const { fromPageType, entranceType } = this.$route?.query || {}

      // 真实列表
      if (type === 'entity') {
        return this.isShowSearchHead({
          listType: type,
          oldAbtValue: this.abtListTitleTypeVal,
          newAbtValue: this.abtRealListTitleTypeVal,
          fromPageType,
          entranceType
        })
      }

      // 选品列表
      if (type === 'selection') {
        return this.isShowSearchHead({
          listType: type,
          oldAbtValue: this.abtListTitleTypeVal,
          newAbtValue: this.abtSelectListTitleTypeVal,
          fromPageType,
          entranceType
        })
      }

      // pick场景
      if (type === 'picks' && this.abtPicksListTitleTypeVal === 'search')
        return true
      return false
    },
    isSearchFixed() {
      const type = this.$route?.meta?.type
      // 真实列表
      if (
        type === 'entity' &&
        (this.abtListshowsearch || this.abtRealListshowsearch)
      )
        return true
      // 选品列表
      if (
        type === 'selection' &&
        (this.abtListshowsearch || this.abtSelectLsitshowsearch)
      )
        return true
      // picks场景
      if (type === 'picks' && this.abtPicksListshowsearch) return true
      return false
    },
    // 是否命中新的底纹词策略，直接展示底纹词
    isHitDefaultWrodsTactics() {
      const type = this.$route?.meta?.type
      // 真实和选品场景
      if (
        ['entity', 'selection'].includes(type) &&
        this.abtListShadingWord?.ListShadingWord === 'show'
      )
        return true
      // picks场景
      if (
        type === 'picks' &&
        this.abtListShadingWord?.PListShadingWord === 'show'
      )
        return true
      return false
    },
    // 由于 commonHeaderInput 组件使用 v-show 导致 轮播组件/列表搜索框兜底逻辑 需要自行更新组件状态
    // 使用 key 的方式 可以让整个组件在不同条件变化时走一遍完整的搜索框兜底逻辑
    initSearchBoxLogic() {
      if (this.selectId) {
        return this.selectId
      }
      if (this.cateIds) {
        return this.cateIds
      }
      return ''
    }
  },
  watch: {
    $route: function (newVal, oldVal) {
      let pageType = newVal.meta?.pageType
      this.routeName = this.$route.name
      // 路由路径变化清空searchWordBlackReqCacheMap和重置showDefaultWordsState的状态和cacheProductListSearchWordsReq
      if (newVal.path !== oldVal.path) {
        this.showDefaultWordsState = false
        this.isCatNameSearchWrods = false
        this.listDefaultWordsProp = null
        for (let key of searchWordBlackReqCacheMap.keys()) {
          searchWordBlackReqCacheMap.delete(key)
        }
        const listCatName =
          this.$route?.meta?.silInfo?.list_cat_name || this.catName
        this.catName = listCatName
        // 非预搜页和搜索结果页的情况下
        if (!(pageType === 'preSearch' || newVal.meta?.type === 'search')) {
          this.catInfo = {}
          this.catName = ''
          this.currentKeyWord = ''
          this.assignRootState({
            searchSelectKeyWord: '',
            currentDefaultKeyword: ''
          })
          this.searchFixed = false
        }
      }
      // 如果去到首页，清除底纹词缓存
      if (this.routeName === 'config_index') {
        window.sessionStorage.removeItem('cacheProductListSearchWordsReq')
        // 清除分布式的合并词，避免从首页搜索icon到预搜又出现列表的词
        this.assignRootState({
          mixedKeyword: ''
        })
      }

      if (this.isGoodsDetailPage) {
        // 清除分布式的合并词，避免从商详搜索icon到预搜又出现列表的词
        this.assignRootState({
          mixedKeyword: ''
        })
      }

      if (this.isGoodsDetailPage && !this.IS_RW) {
        this.goodsId = newVal.params['1'] || ''
      }
      if (newVal.meta && pageType && DA_CATEGORY_MAP[pageType]) {
        this.daCategory = DA_CATEGORY_MAP[pageType]
      }
      if (newVal.name && DA_CATEGORY_MAP2[newVal.name]) {
        this.daCategory = DA_CATEGORY_MAP2[newVal.name]
      }

      this.pageViewed = false
      this.currPageExposed = false
      this.headerPresearchScroll = false
      this.showSearchFixed()
      this.setCartState()
      // dealingSearchInput会触发底纹词请求
      if (newVal.name !== 'pre_search' && !this.currentKeyWord) {
        this.dealingSearchInput()
      }

      // 路由跳转重新更新
      this.setPageType(newVal.name)
      this.handleStoreSearchData()
      this.showProductListSearchFixed(0)
    },
    needExposedSearch: {
      immediate: true,
      handler(val) {
        if (val) {
          this.currPageExposed = true
          if (this.pageViewed) {
            // this.exposedVisualSearch()
          } else {
            this.waitPageViewedToReport = true
          }
        }
      }
    },
    isShowInput: {
      handler() {
        this.dealingSearchInput({ transfer: 'watchIsShowInput' })
        this.$emit('isShowInput', this.isShowInput)
      },
      immediate: true
    },
    isShareIconShow(show) {
      if (show) {
        headerAnalysis().then(res => res.exposeShare(this.goodsDetailInfo))
      }
    },
    catInfo: {
      handler(newVal) {
        if (!newVal?.type) return
        newVal.cat_name && (this.catName = newVal.cat_name)
        if (newVal.cat_name && this.isShowInput) {
          this.handleProductListSearchWords({
            searchword: newVal.cat_name,
            transfer: 'cateInfo change'
          })
        }
      },
      deep: true
    }
  },
  created() {
    this.routeName = this.$route.name
    this.goodsId = this.goodsDetailInfo?.goodsId || ''
    this.useBffApi = isSwitchBffApiVersion(['picSearch_v1', 'preSearch_v1']) || {} 

    window.sessionStorage.removeItem('cacheProductListSearchWordsReq')
  },
  async mounted() {
    this.initScrollFixed()
    this.bindEvent()
    await this.handleWishlistShow()
    this.dealingSearchInput()
    this.initStoreData()
    // 方便其他页面放出事件给头部切换logo  输入框 比如整个页面是overflow滚动
    appEventCenter.$on(
      'changeHeaderPresearchScroll',
      this.setHeaderPresearchScroll
    )
    appEventCenter.$on('resetCatInfo', this.setCatInfo)

    appEventCenter.$on('pdsearchExposure', this.pdsearchExposure)
    appEventCenter.$emit('headerMounted', this) // 这个不能删除
    appEventCenter.$on(
      'stretchStoreSearchToRight',
      this.stretchStoreSearchToRight
    )
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.preSearchScroll)
    appEventCenter.$off(
      'changeHeaderPresearchScroll',
      this.setHeaderPresearchScroll
    )
    appEventCenter.$off('resetCatInfo', this.setCatInfo)
    appEventCenter.$off('pdsearchExposure', this.pdsearchExposure)
    appEventCenter.$off(
      'stretchStoreSearchToRight',
      this.stretchStoreSearchToRight
    )
    appEventCenter.$off('changeHeaderSticky', this.setHeaderStickyState)
  },
  methods: {
    ...mapMutations([
      'assignRootState',
      'changeHomeImmersiveInfo'
    ]),
    initScrollFixed() {
      const scrollFn = throttle({
        func: () => {
          if (this.$route.meta.notNeedCommonHeader) return // 不需要头部的页面不做处理
          this.storeRightCornerShowMore = false
          const scrollTop = document.documentElement.scrollTop
          if (scrollTop > 0) {
            this.showProductListSearchFixed(scrollTop)
          }
        },
        wait: 10,
        options: { leading: true }
      })
      document.addEventListener('scroll', scrollFn, { passive: true })

      this.$once('hook:beforeDestroy', () => {
        document.removeEventListener('scroll', scrollFn)
      })
    },
    handleGoBack() {
      markPoint('toNextPageClick', 'public')
      // 分享需要登录的2级页面回退会到首页，先注释
      // if (document.referrer.indexOf('user/login') > -1) {
      //   location.href = langPath
      // } else
      if (
        location.pathname.indexOf('/shop-multiple-sets-') > -1 &&
        document.referrer &&
        (document.referrer.indexOf('shein') == -1 ||
          document.referrer.indexOf('romwe') == -1)
      ) {
        // 站外落地页为shoplook
        location.href = langPath
      } else {
        setTimeout(function () {
          // 异步执行一些 例如埋点任务
          history.back()
        }, 100)
      }
    },

    async handleWishlistShow() {
      const {
        RotationShow = {},
        ShowSearch = {},
        // TopSucking = {},
        NewPicSearch = {},
        HomeSearch = {},
        listiconBag = {},
        ListTitleType = {},
        hovercart = {},
        Listshowsearch = {},
        ListShadingWord = {},
        storelistsearch = {}
      } = await getUserAbtData()
      this.showSearchIndex = this.IS_RW ? RotationShow?.param == 'type=A' : true // shein默认展示
      this.abtShowSearchButton =
        HomeSearch?.param?.HomeSearch == 'button' && !this.IS_RW
      this.showSearchCategory = ShowSearch?.param == 'type=A' // 分类实验展示
      this.abtListshowsearch = Listshowsearch?.param?.Listshowsearch === 'show' // 真实+选品 列表
      this.abtRealListshowsearch =
        Listshowsearch?.param?.CListshowsearch === 'show' // 真实 列表
      this.abtSelectLsitshowsearch =
        Listshowsearch?.param?.SCListshowsearch === 'show' // 选品 列表
      this.abtPicksListshowsearch =
        Listshowsearch?.param?.PListshowsearch === 'show'
      this.abtListTitleTypeVal = ListTitleType?.param?.searchType || ''
      this.abtRealListTitleTypeVal = ListTitleType?.param?.csearchType || ''
      this.abtSelectListTitleTypeVal = ListTitleType?.param?.scsearchType || ''
      this.abtPicksListTitleTypeVal = ListTitleType?.param?.sheinpicks || ''
      this.abtListShadingWord = ListShadingWord?.param
      this.searchStoreNewStyle = storelistsearch.p?.storelistsearch === 'B'

      // 针对列表是否需要取消顶部购物车展示abt配置
      this.showListCardIconAbt = listiconBag?.p?.listiconBag === 'A'
      this.showDetailCardIconAbt = hovercart?.p?.oldcart === 'none'
      // 获取到abt后设置顶部状态值
      this.setCartState()
      this.setPageType(SaPageInfo?.page_name)

      // this.topSucking = TopSucking

      this.abtSearchResultShowCamera = NewPicSearch?.p?.SearchResult === 'A'

      this.showSearchFixed()
      setTimeout(() => {
        if (!this.$route.meta.notNeedCommonHeader) {
          // 曝光头部
          headerAnalysis().then(res => {
            // 店铺页头部曝光处理
            if (this.isStorePageOrSearchPage) {
              // 外露收藏 曝光收藏 + more
              res.exposeWishlist()
              if (!this.isStoreSearchPage) res.exposeMoreFunction()
              return
            }
            res.exposeWishlist()
          })
        }
      }, 3000) // 列表页等待page信息拼接
      window.addEventListener('scroll', this.preSearchScroll)
    },

    // 滑动的时候，处理搜索框悬浮的状态
    showProductListSearchFixed(scrollTop) {
      if (/pdsearch/.test(this.$route?.params?.pathMatch)) return
      let listSearchFixed = false

      if (this.isFiexdRuleShowInput || scrollTop > 40) {
        if (this.isSearchFixed) {
          listSearchFixed = true
        }
      } else {
        listSearchFixed = false
      }
      const curVal = this.catName || this.$route?.meta?.silInfo?.list_cat_name
      if (
        !this.isFiexdRuleShowInput &&
        listSearchFixed &&
        this.searchFixed != listSearchFixed &&
        scrollTop > 0
      ) {
        this.handleProductListSearchWords({
          searchword: curVal,
          transfer: 'scrollup searchFiexd'
        })
      }
      this.searchFixed = listSearchFixed
    },

    // 命中实验，点击筛选悬浮搜索框
    setHeaderStickyState(state) {
      if (!this.isSearchFixed) return
      this.searchFixed = state
      if (state) {
        this.handleProductListSearchWords({
          searchword: this.catName,
          transfer: 'click filter searchFiexed'
        })
      }
    },

    async dealingSearchInput() {
      if (this.isShowInput) {
        const {
          SearchDefaultNew = {},
          SearchHotGD = {},
          newsearch = {}
        } = await getUserAbtData()
        this.loadHeaderPresearch = true
        this.inputNewStyle = newsearch.p?.Listnewsearch === 'new'
        this.abtSearchDefaultNew = SearchDefaultNew
        this.abtSearchHotGD = SearchHotGD

        // 只有商详和首页会走这里触发底纹词的请求
        if (this.isGoodsDetailPage) {
          this.triggerGoodsDetailDefaultWordsReq()
        } else if (this.isConfigIndexPage) {
          this.triggerNormalDefaultWordsReq()
        } else if (this.isSearchResultPage && !this.useNewInput) {
          // 老框这里触发去结果页的pv， 新框在新头部搜索框触发
          this.dealDefaultWords = true
        }

        requestIdleCallback(async () => {
          const analysis = await searchAnalysis()
          if (this.searchEntranceBtnType === 'store') {
            analysis.exposeStoreSearch({
              Searchboxform: 2,
              search_content: ''
            })
          } else if (this.searchEntranceBtnType === 'brand') {
            analysis.exposeBrandSearch({
              Searchboxform: 2,
              search_content: SaPageInfo?.page_param?.search_content
            })
          }
        })
      } else if (!this.$route?.meta?.notNeedCommonHeader) {
        requestIdleCallback(async () => {
          const analysis = await searchAnalysis()
          if (this.searchEntranceBtnType === 'all') {
            if (SaPageInfo?.page_param?.search_content) {
              let payload = Object.assign(SaPageInfo.page_param, {
                currentWord: SaPageInfo.page_param.search_content
              })
              analysis.exposeSearch(payload)
            }
          }
        })
      }
    },

    // 列表场景展示搜索框时，对搜索词的展示处理
    handleProductListSearchWords({ searchword, isDefaultWordsEmpty = false }) {
      isDefaultWordsEmpty && (searchword = this.catName)
      const {
        cat_id,
        select_id,
        picks_id,
        childCatId,
        childSelectId,
        isBackFromDetail
      } = this.catInfo || {}
      const userClickProductLatest = JSON.parse(
        window.sessionStorage?.getItem('userClickProductLatest') || '{}'
      )
      const { cat_id: detailBackCatId, goods_id: detailBackGoodsId } =
        userClickProductLatest || {}

      this.cacheListDefaultWordsKey = `${childCatId || cat_id || ''}_${
        childSelectId || ''
      }_${select_id || ''}_${picks_id || ''}`
      // 如果是商详返回的场景，并且能拿到对应商品的cat_id和goods_id的情况下，需要加上对应商品的catId和goodsId作为缓存的key
      if (isBackFromDetail && detailBackCatId && detailBackGoodsId) {
        this.cacheListDefaultWordsKey = `${this.cacheListDefaultWordsKey}_${detailBackCatId}_${detailBackGoodsId}`
      }
      // 命中底纹词展示策略直接请求底纹词
      const cacheListDefaultWordsValue = JSON.parse(
        window.sessionStorage?.getItem('cacheProductListSearchWordsReq') || '{}'
      )?.[this.cacheListDefaultWordsKey]
      if (cacheListDefaultWordsValue) {
        this.listDefaultWordsProp = cacheListDefaultWordsValue 
      }

      // 策略底纹词返回空的时候需要走页面词兜底，页面词兜底需要验证词是否在黑名单
      if (
        isDefaultWordsEmpty ||
        (Array.isArray(this.listDefaultWordsProp) &&
          this.listDefaultWordsProp?.length === 0)
      ) {
        searchword = searchword || 'search'
        this.triggerSearchWordsBlackReq(searchword)
        return
      }
      // 命中列表策略底纹词实验
      if (this.isHitDefaultWrodsTactics) {
        // 对应入参的请求之前是否请求过
        this.assignRootState({
          searchSelectKeyWord: '',
          mixedKeyword: '',
          currentDefaultKeyword: ''
        })
        this.currentKeyWord = ''
        if (!this.listDefaultWordsProp) {
          window.sessionStorage.removeItem('cacheProductListSearchWordsReq') // 清楚掉旧的缓存
          // 触发列表底纹词请求
          this.triggerListDefaultWordsReq()
        } else if (this.listDefaultWordsProp?.length > 0) {
          // 走缓存展示
          this.showDefaultWordsState = true
          // 这个时候虽然触发了input的底纹词逻辑，但是不会触发请求，因为listDefaultWordsProp传给了input
          this.dealDefaultWords = true
          if (isBackFromDetail && detailBackCatId && detailBackGoodsId) {
            window.sessionStorage.removeItem('userClickProductLatest') // 已经请求过的清除掉，不然在页面进行category 或者 图文导航筛选的时候，因为这个时候 isBackFromDetail 是 true，所以会有问题
          }
        }
      } else {
        // 未命中底纹词策略，但是需要展示搜索框的情况，以页面名称作为底纹词展示，并去底纹词黑名单进行验证
        this.triggerSearchWordsBlackReq(searchword)
      }
    },

    // 触发搜索词黑名单校验逻辑
    triggerSearchWordsBlackReq(searchword) {
      // searchword = 'shein' //mock命中黑名单
      const type = this.$route?.meta?.type
      const searchWordBlackState = searchWordBlackReqCacheMap.get(searchword) //从黑名单缓存中取命中黑名单的词是否存在
      // 未命中底纹词策略 || 命中底纹词策略，底纹词接口返回空的情况
      if (searchword) {
        // 在当前页面，对应的词已经请求过黑名单了，真实列表场景不需要黑名单验证
        if (type === 'entity') {
          this.handleSearchWordsBlack(false, searchword)
          return
        }
        if (searchWordBlackState) {
          this.handleSearchWordsBlack(searchWordBlackState?.state, searchword)
        } else {
          // 获取搜索词黑名单配置
          schttp({
            url: '/api/searchwords/cccSearchWordBlack/get',
            params: { cateId: searchword }
          }).then(res => {
            // 更新当前页面词的map存储
            const isBlackWrod = res && Object.keys(res).length > 0
            searchWordBlackReqCacheMap.set(searchword, { state: isBlackWrod })
            this.handleSearchWordsBlack(isBlackWrod, searchword)
          })
        }
      } else {
        // 没有搜索词的时候，通过改变这个状态，让搜索框展示默认词，比如search/ store search
        this.showDefaultWordsState = true
      }
    },

    // 触发商详页底纹词请求
    triggerGoodsDetailDefaultWordsReq() {
      this.cateIds = ''
      this.selectId = ''
      this.bizScene = ''
      this.abtDefaultWords = this.abtSearchHotGD?.p || {}
      this.showDefaultWordsState = true
      this.dealDefaultWords = true
    },

    // 触发默认底纹词请求（目前只有首页和列表页的兜底会走）
    triggerNormalDefaultWordsReq() {
      this.goodsId = ''
      this.cateIds = ''
      this.selectId = ''
      this.bizScene = ''
      this.abtDefaultWords = this.abtSearchDefaultNew?.p || {}
      this.showDefaultWordsState = true
      this.dealDefaultWords = true
    },

    // 触发真实/选品/picks底纹词请求(这些场景有对应的策略逻辑)
    triggerListDefaultWordsReq() {
      const type = this.$route?.meta?.type
      const regex = /word_(.+)/
      const {
        cat_id,
        select_id,
        picks_id,
        childCatId,
        childSelectId,
        sub_type
      } = this.catInfo || {}
      const userClickProductLatest = JSON.parse(
        window.sessionStorage?.getItem('userClickProductLatest') || '{}'
      )
      // 实验入参需要做一定的转换，识别word_xxx作为接口对应入参，比如实验配置了listshading_word_recall要转换为对应接口的word_recall实验参数入参
      const newRule = {}
      Object.entries(this.abtListShadingWord).forEach(([key, value]) => {
        const match = regex.exec(key)
        if (match) {
          const newKey = match[0]
          newRule[newKey] = value
        }
      })
      this.cateIds = ''
      this.goodsId = ''
      this.selectId = ''
      this.bizScene = {
        entity: 'real',
        selection: 'select',
        picks: 'sheinpick'
      }[type]
      // SelectList
      this.abtDefaultWords = newRule
      // 商详返回到列表的场景
      const { cat_id: detailBackCatId, goods_id: detailBackGoodsId } =
        userClickProductLatest || {}
      if (
        this.catInfo.isBackFromDetail &&
        detailBackCatId &&
        detailBackGoodsId
      ) {
        // 用户浏览过商品的情况下回到了列表场景
        this.cateIds = detailBackCatId
        this.goodsId = detailBackGoodsId
        if (this.cateIds && this.goodsId)
          window.sessionStorage.removeItem('userClickProductLatest') // 已经请求过的清除掉，不然在页面进行category 或者 图文导航筛选的时候，因为这个时候 isBackFromDetail 是 true，所以会有问题
      } else {
        // childCatId / childSelectId 还在的情况，表示在对应的场景做过筛选，这个时候如果去到其它页面返回到列表场景的时候或者页面滑动的时候触发搜索框悬浮的情况还要用筛选过的类目去请求
        if (childCatId) {
          this.cateIds = childCatId
        } else if (childSelectId) {
          this.selectId = childSelectId
        } else {
          if (['entity', 'selection'].includes(type)) {
            if (type === 'entity') {
              this.cateIds = cat_id
            } else if (type === 'selection') {
              this.selectId = select_id
            }
          } else {
            const isSelection = sub_type === 'selection'
            if (isSelection) {
              this.selectId = picks_id
            } else {
              this.cateIds = picks_id
            }
          }
        }
      }
      if (this.cateIds || this.selectId) {
        this.showDefaultWordsState = true
        this.dealDefaultWords = true
      }
    },

    // 缓存列表场景最近请求过的底纹词
    cacheListDefaultWordsReq(args) {
      const { defaultWords } = args || {}
      // 缓存请求过的入参
      window.sessionStorage?.setItem(
        'cacheProductListSearchWordsReq',
        JSON.stringify({ [this.cacheListDefaultWordsKey]: defaultWords })
      )
    },

    // 对用catName作为搜索词是否命中黑名单的处理
    handleSearchWordsBlack(isBlackWrod = false, searchword) {
      // 命中黑名单，请求首页策略的默认底纹词
      if (isBlackWrod) {
        this.assignRootState({
          searchSelectKeyWord: '',
          mixedKeyword: '',
          currentDefaultKeyword: ''
        })
        this.currentKeyWord = ''
        this.isCatNameSearchWrods = false // 是否是用cateName作为搜索词，主要是埋点需要
        // 触发默认兜底底纹词请求
        this.triggerNormalDefaultWordsReq()

        this.isHitBlackListTitle = true
      } else {
        this.isHitBlackListTitle = false

        this.showDefaultWordsState = false
        this.currentKeyWord = searchword
        this.assignRootState({
          searchSelectKeyWord: searchword,
          mixedKeyword: searchword,
          currentDefaultKeyword: searchword
        })
        this.isCatNameSearchWrods = true // 是否是用cateName作为搜索词，主要是埋点需要
        searchAnalysis().then(analysis => {
          // 处理
          if(exposeSearchTextArr.includes(this.currentKeyWord)) {
            return 
          }
          analysis.exposeSearch({
            currentWord: this.currentKeyWord,
            isCatNameSearchWrods: this.isCatNameSearchWrods
          })
          exposeSearchTextArr.push(this.currentKeyWord)
        })
      }
    },

    // 针对泛列表场景顶部的购物车展示状态,https://wiki.dotfashion.cn/pages/viewpage.action?pageId=1171172519
    setCartState() {
      // 品牌店铺页不展示购物车
      if (this.isStorePageOrSearchPage && this.isBrandStore) {
        this.showCartIcon = false
        return
      }
      if (this.$route?.meta?.pageType === 'productDetail') {
        this.showCartIcon = !(!this.IS_RW && this.showDetailCardIconAbt)
      } else if (this.$route?.meta?.pageType === 'campaigns') {
        // 专题配置购物车悬浮组件时，不展示购物车
        if (
          typeof window !== 'undefined' &&
          window.gbCommonInfo.contextForSSR.content?.content?.some(
            item => item.styleType === 'CART_ICON'
          )
        ) {
          this.showCartIcon = false
        }
      } else {
        this.showCartIcon = !(
          !this.IS_RW &&
          LIST_TABLE.includes(this.$route?.meta?.pageType ?? '') &&
          this.showListCardIconAbt
        )
      }
    },
    // 获取多语言
    filter(target, id) {
      return target.some(item => item == id)
    },
    bindEvent() {
      if (
        this.$route &&
        this.$route.meta &&
        this.$route.meta.pageType &&
        DA_CATEGORY_MAP[this.$route.meta.pageType]
      ) {
        this.daCategory = DA_CATEGORY_MAP[this.$route.meta.pageType]
      }
      if (this.$route.name && DA_CATEGORY_MAP2[this.$route.name]) {
        this.daCategory = DA_CATEGORY_MAP2[this.$route.name]
      }
      if (navigator.userAgent.indexOf('Safari') > -1) {
        // safari 手动触发链接跳转
        if ('standalone' in window.navigator && window.navigator.standalone) {
          var noddy
          document.addEventListener(
            'click',
            function (event) {
              noddy = event.target
              while (noddy.nodeName !== 'A' && noddy.nodeName !== 'HTML')
                noddy = noddy.parentNode
              if (
                'href' in noddy &&
                noddy.href.indexOf('http') !== -1 &&
                noddy.href.indexOf(document.location.host) !== -1
              ) {
                event.preventDefault()
                document.location.href = noddy.href
              }
            },
            false
          )
        }
      }

      if (window.SaPageInfo.page_name) {
        this.pageViewed = true
      }
      appEventCenter.$on('pageOnload', () => {
        this.pageViewed = true
        if (this.waitPageViewedToReport) {
          this.waitPageViewedToReport = false
          // this.exposedVisualSearch()
        }
      })
    },
    preSearchScroll: throttle({
      func: function () {
        // 显示快加车时，会将scroll置为0并且锁住滚动
        // 防止触发此函数导致：header search input > shein logo 切换
        const bodyDom = document.body
        const bodyStyle = bodyDom.style ?? {}
        const topValue = (bodyStyle.top ?? '').replace(/[^0-9]/gi, '')
        if (bodyStyle.position === 'fixed' && topValue > 250) return false
        const scrollTop = document.documentElement.scrollTop
        this.headerPresearchScroll = scrollTop > 250
      },
      wait: 100,
      options: { leading: true }
    }),
    setHeaderPresearchScroll(value) {
      this.headerPresearchScroll = !!value
    },
    uploadSuccessHandler(payload) {
      // 搜索框内容
      this.assignRootState({
        commonHeaderInput: payload
      })
      // 跳转到对应的中间页面
      this.goPresearch({ camera: 1, upload: true })
    },
    syncCurKeyword(curKeyword) {
      this.currentKeyWord = curKeyword
    },
    goPresearch(payload = {}) {
      markPoint('toNextPageClick', 'public')
      // 将当前轮换的默认词，显示在搜索页输入框内
      if (payload?.placeholder) {
        gbCommonInfo.keywords = ''
        this.assignRootState({
          currentDefaultKeyword: payload.placeholder.word,
          searchSelectKeyWord: '',
          mixedKeyword: ''
        })
      } else if (payload.currentWord) {
        // 搜索结果页带过去本次搜索的词
        gbCommonInfo.keywords = payload.currentWord
        gbCommonInfo.toPreSearchType = payload.type
      } else {
        gbCommonInfo.keywords = ''
        this.assignRootState({
          mixedKeyword: '',
          searchSelectKeyWord: '',
          currentDefaultKeyword: ''
        })
      }

      // 标识进入新页面
      this.$route.meta.isNewPage = true

      // 点击相机的时候不需要发埋点
      if (payload?.camera) {
        this.$route.meta.cameraFrom = window.SaPageInfo.page_name
        this.$route.meta.upload = payload.upload
      } else {
        this.$route.meta.cameraFrom = null
        searchAnalysis().then(analysis => {
          if (this.searchEntranceBtnType === 'all') {
            if (SaPageInfo?.page_param?.search_content) {
              payload = Object.assign(SaPageInfo.page_param, {
                currentWord: SaPageInfo.page_param.search_content
              })
            }
            Object.assign(payload, {
              isCatNameSearchWrods: this.isCatNameSearchWrods
            })
            requestIdleCallback(() => {
              analysis.clickSearch(payload)
            })
          } else if (this.searchEntranceBtnType === 'brand') {
            const search_content = SaPageInfo?.page_param?.search_content ?? ''
            const payload = {
              search_content,
              search_box_form: search_content ? 2 : 1
            }
            analysis.clickBrandSearch(payload)
          } else if (this.searchEntranceBtnType === 'store') {
            const search_content = SaPageInfo?.page_param?.search_content ?? ''
            const payload = {
              search_content,
              search_box_form: 2
            }
            analysis.clickStoreSearch(payload)
          }
        })
      }
      const pagefrom = window?.SaPageInfo?.page_name
      const query = {
        pageType: this.searchEntranceBtnType,
        pagefrom
      }

      if (this.isGoodsDetailPage) {
        Object.assign(query, {
          goods_id: this.goodsDetailInfo?.goodsId,
          cate_id: this.goodsDetailInfo?.catId
        })
      }

      const src_identifier = getQueryString({ key: 'src_identifier' })
      if (src_identifier) {
        query.src_identifier = src_identifier
      }

      // 店铺搜索传入店铺id
      if (this.searchEntranceBtnType === 'store') {
        query.store_code = getStoreCodeFromUrl()
        query.brand_id = getQueryString({ key: 'brand_id' }) ?? ''
      } else if (this.searchEntranceBtnType === 'brand') {
        // 专题搜索页传tsp code
        query.intent_channel_id =
          getQueryString({ key: 'intent_channel_id' }) ?? ''
      }

      // 搜索按钮状态传入预搜索页
      if (this.abtShowSearchButton) {
        query.search_button = 1
      }
      if (!payload.currentWord && payload.type === 'clickclearBtn') {
        query.show_association = '0'
        daEventCenter.triggerNotice({
          daId: '1-5-3-29',
          extraData: {
            pageType: this.$route?.meta?.pageType
          }
        })
      }

      // 店铺跳转到预搜索 -- 禁止无脑堆砌代码，currentDefaultKeyword 表示框里的底纹词，修改这个就好了，新框无这些乱七八糟的逻辑
      if (payload.hot_word && !this.useNewInput) {
        query.hot_word = payload.hot_word
        const store_code = getStoreCodeFromUrl()
        setSessionStorage({
          key: `STORE_SEARCH_${store_code}`,
          value: payload.hot_index
        })
      }

      // 店铺选品跳转到预搜索 -- 禁止无脑堆砌代码，currentDefaultKeyword 表示框里的底纹词，修改这个就好了，新框无这些乱七八糟的逻辑
      if (this.showStoreDefaultWords && !this.useNewInput) {
        query.hot_word = payload.hot_word
        const store_code = getQueryString({ key: 'store_code' }) ?? ''
        setSessionStorage({
          key: `STORE_SELECTION_${store_code}`,
          value: payload.hot_index
        })
      }
      this.$router.push({
        path: `${this.langPath}/presearch`,
        query
      })
    },
    handleSearchIconClick(payload = {}) {
      searchAnalysis().then(analysis => {
        const result = {
          search_content: payload?.hot_word,
          search_box_form: 2
        }
        analysis.clickStoreSearchIcon(result)
      })
    },
    handelClickMessage() {
      markPoint('toNextPageClick', 'public')
      this.$router.push(`${langPath}/user/message`)
      headerAnalysis().then(res => {
        res.clickMessageNews({
          daCategory: this.daCategory,
          unread_amount: this.existOrderUnRead || 0
        })
      })
    },
    handleClickBag({ extraData } = {}) {
      markPoint('toNextPageClick', 'public')
      headerAnalysis()
        .then(res => res.clickBag(extraData))
        .finally(() => {
          this.$router.push(`${this.langPath}/cart`)
        })
    },
    handleClickBagTip({ extraData } = {}) {
      headerAnalysis()
        .then(res => res.clickBagTip(extraData))
        .finally(() => {
          this.$router.push(`${this.langPath}/cart`)
        })
    },
    handleExposeBag({ extraData } = {}) {
      headerAnalysis().then(res => res.exposeBag(extraData))
    },
    handleExposeBagTip({ extraData } = {}) {
      headerAnalysis().then(res => res.exposeBagTip(extraData))
    },
    handleLinkToFollowedShops(routeQuery = {}) {
      const login = isLogin()
      headerAnalysis()
        .then(res => {
          res.clickMoreFollow()
        })
        .finally(() => {
          if (login)
            return this.$router.push({
              path: `${this.langPath}/store/following`,
              query: {
                page_from: this.$route.name,
                entrance: routeQuery?.entrance
              }
            })
          SHEIN_LOGIN.show({
            show: true,
            cb: () => {
              this.handleLinkToFollowedShops(routeQuery)
            }
          })
        })
    },
    handleLinkToRecentViewed(type = 'expose') {
      headerAnalysis().then(res => {
        if (type === 'epose') res.clickMoreFootprint?.()
        else res.clickFootPrint?.()
      })
      this.$router.push(`${this.langPath}/user/index?rt=rv`)
    },
    updateStoreRightCornerShowMore(value, navs) {
      if (!!value)
        headerAnalysis().then(res => {
          res.clickMoreFunction(navs.map(item => item.trackName).join('/'))
        })
      this.storeRightCornerShowMore = !!value
    },
    handleLinkToSupport() {
      headerAnalysis().then(res => {
        res.clickMoreSupport(this.goodsDetailInfo)
      })
      this.$router.push(`${this.langPath}/user/support`)
    },
    /**
     * 上传图片获取链接
     */
    async getImgLink(fileBlob, file) {
      const picFile = new window.File(
        [fileBlob],
        file.name.replace('webp', 'jpg'),
        { type: file.type.replace('webp', 'jpg') }
      )
      const formData = new FormData()
      formData.append('upload_img', picFile)
      let img = ''
      try {
        if (this.useBffApi?.['picSearch_v1']) {
          const data = await uploadImgBffApi(picFile)
          img = data?.info?.path || ''
          if (data?.code != 0) {
            throw new Error('图搜-上传图片bff-err', data)
          }
        } else {
          const data = await uploadImgApi(formData)
          img = (data?.body?.result || [])[0]?.path
        }
      } catch (err) {
        const data = await uploadImgApi(formData)
        img = (data?.body?.result || [])[0]?.path || ''
      }
      if (img) {
        return transformImg({ img, useWebp: true, isImgCenter: true })
      }
      return ''
    },

    setPageInfo() {
      sa(
        'set',
        'setPageData',
        Object.assign(this.outPointInfo, { start_time: new Date().getTime() })
      )
    },

    showCategory() {
      this.$emit('showcategory')
      headerAnalysis().then(res => res.clickCategory())
    },

    // 搜索结果页固定搜索框
    showSearchFixed() {
      if (/pdsearch/.test(this.$route?.params?.pathMatch)) {
        const { pathMatch = '' } = this.$route.params
        const curVal = pathMatch
          .replace(/(pdsearch)?\//g, '')
          .replaceAll('%25', '%')
        let mixedKeyword = curVal
        const filterWordsArr =
          getQueryString({ key: 'filterWords' })?.split(',') || []
        const filterAttrIdsArr =
          getQueryString({ key: 'filterAttrIds' })?.split('`') || []

        const searchFilterWords = []
        if (filterWordsArr.length) {
          filterWordsArr.forEach(filterWord => {
            const obj = { filterWord }
            const target = filterAttrIdsArr.find(_ => _.includes(filterWord))
            if (target) {
              const arr = target.split(',')
              const attrId = arr[1]
              const attrValueId = arr[2]
              if (attrId) {
                obj.attr_id = attrId
              }
              if (attrValueId) {
                obj.attr_value_id = attrValueId
              }
            }
            searchFilterWords.push(obj)
          })
          mixedKeyword = `${curVal} ${filterWordsArr.join(' ')}`
        }
        this.assignRootState({
          searchSelectKeyWord: curVal,
          mixedKeyword,
          searchFilterWords
        }) // 保证刷新页面值存在
        this.searchFixed = true
      }
    },

    goWishPage(type = '') {
      markPoint('toNextPageClick', 'public')
      headerAnalysis().then(res => {
        if (!type)
          res.clickWishlist({
            daCategory: this.daCategory,
            redpoint: this.redpoint
          })
        else res.clickMoreWishlist()
      })
      this.$routerGoWish({ pf: 'topbar' }, true)
    },

    checkIsPageStore(pageName) {
      const storePageNames = [
        'page_store_items',
        'page_store_brands',
        'page_store_category',
        'page_store_home',
        'page_store',
        'page_store_pre_search'
      ]
      const searchPageType = getQueryString({ key: 'pageType' })
      const isStoreSearch = searchPageType === 'store'
      const isPageStore =
        (storePageNames.includes(pageName) ||
          getQueryString({ key: 'search_type' }) === 'store' ||
          isStoreSearch) &&
        getStoreCodeFromUrl()
      return !!isPageStore
    },
    checkIsPageBrand() {
      const isPageCampaign = getQueryString({ key: 'search_type' }) === 'brand'
      return !!isPageCampaign
    },
    async setPageType(pageName) {
      // isPageStore 在店铺页面和店铺搜索结果页，都会为true
      const isPageStore = this.checkIsPageStore(pageName)
      const isPageCampaign = this.checkIsPageBrand()
      if (!IS_RW && isPageStore) {
        this.searchEntranceBtnType = 'store'
      } else if (isPageCampaign) {
        this.searchEntranceBtnType = 'brand'
      } else {
        this.searchEntranceBtnType = 'all'
      }
    },

    closeAddScreen() {
      this.addScreenLanguage = null
    },
    handleShare(type = 'goods') {
      switch (type) {
        case 'goods':
          headerAnalysis().then(res => {
            res.clickShare(this.goodsDetailInfo)
          })
          window.vBus && window.vBus.$emit('handleHeaderShareForGoodsDetail')
          break
        case 'store':
        case 'storeMore':
          headerAnalysis().then(res => {
            if (type === 'storeMore') res.clickMoreShare()
            else res.clickShare(this.goodsDetailInfo)
          })
          window.appEventCenter &&
            window.appEventCenter.$emit('handleHeaderShareForStore')
          break
        // eslint-disable-next-line no-fallthrough
        default:
          break
      }
    },
    pdsearchExposure() {
      // 进入店铺搜索页曝光外露 收藏/足迹
      headerAnalysis().then(res => {
        res.exposeWishlist()
      })
    },
    setCatInfo(catInfo) {
      this.catInfo = Object.assign({}, catInfo)
    },
    fetchStoreSearchData(data) {
      return schttp({
        url: '/api/searchwords/abcKeywords/get',
        params: data
      })
    },
    async handleStoreSearchData() {
      if (this.routeName != 'page_store') {
        return
      }
      const { StoreSearch = {} } = await getUserAbtData()
      const store_code = getStoreCodeFromUrl()
      const scene = 'store'
      const defaultRuleId = {
        word_recall: StoreSearch?.p?.StoreSearch
      }
      const { keywords: defaultWordsData } = await this.fetchStoreSearchData({
        word_type: 1,
        rule_id: defaultRuleId,
        store_code,
        scene
      })
      if (defaultWordsData.length) {
        this.storeDefaultWords = defaultWordsData.map(el => el.word)
      }
    },
    async handleStoreIconAbt() {
      const { storeiconchange = {} } = await getUserAbtData()
      this.abtNewStoreiconchange = storeiconchange?.p?.storeiconchange === 'new'
    },
    initStoreData() {
      this.handleStoreSearchData()
      this.handleStoreIconAbt()
    },
    stretchStoreSearchToRight({ isStretched }) {
      this.storeSearchStretched = isStretched
      // 走强化关注入口实验 & 店铺搜索框新样式 则不隐藏右侧按钮
      this.abtFollowBtnEntry =
        this.Results?.storeAbtResult?.followenhance?.p?.topfollowbutton === 'B'
      if (!this.abtFollowBtnEntry || !this.searchStoreNewStyle) {
        this.hideHeaderRight = isStretched
      }
    },
    /**
     *
     * @param {string} listType entity | selection
     * @param {string} oldAbtValue 旧的实验配置值 包含真实和选品
     * @param {string} newAbtValue 新的实验配置值 真实和选品 单独配置
     * @param {string} fromPageType 来源页面类型 home | category
     * @param {string} entranceType 入口类型 h1 | sbc
     * @returns
     */
    isShowSearchHead({
      listType,
      oldAbtValue,
      newAbtValue,
      fromPageType,
      entranceType
    }) {
      let showSearchHead = false

      // 实验配置'c&sc'，不区分入口
      if (
        oldAbtValue === 'c&sc' ||
        newAbtValue === (listType === 'entity' ? 'clist' : 'sc')
      )
        showSearchHead = true
      // 实验配置'Category'，category过来的
      if (
        fromPageType === 'category' &&
        (oldAbtValue === 'Category' || newAbtValue === 'Category')
      )
        showSearchHead = true
      // 实验配置'home'，首页过来的
      if (
        fromPageType === 'home' &&
        (oldAbtValue === 'shop' || newAbtValue === 'shop')
      )
        showSearchHead = true
      // 实验配置'h1'，首页h1区域过来的
      if (
        entranceType === 'h1' &&
        (oldAbtValue === 'h1' || newAbtValue === 'h1')
      )
        showSearchHead = true
      // 实验配置'sbc'，首页sbc区域点击过来的
      if (
        entranceType === 'sbc' &&
        (oldAbtValue === 'sbc' || newAbtValue === 'sbc')
      )
        showSearchHead = true

      return showSearchHead
    }
  }
}
</script>
