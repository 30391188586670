
// ssr页面 路由
export const createWishlistSsrRouter = (langPath = '') => {
  return [
    {
      path: `${langPath}/wishlist`,
      name: 'UserWishlist',
      component: () => import(/* webpackChunkName: "user_wishlist_comp" */'../../user_wishlist/wish/container.vue'),
      meta: {
        pageType: 'wishlist',
        isCssInVue: true,
        notNeedCommonHeader: true,
        keepAlive: true,
        monitorTag: 'wishlist',
      }
    }
  ]
}

export const WISHLIST_USER_ROUTER = [
  {
    path: 'wishlist',
    redirect: '/wishlist' // 兜底用户本地保存的url地址user/wishlist 现接入ssr后过渡重定向到wishlist页面 (后期可以删除， 过渡方案)
  },
  {
    path: 'wishlist/groups-select',
    name: 'UserWishlistProduct',
    component: () => import(/* webpackChunkName: "wish-groups-select" */'../../user/child_pages/wishlist_v2/group_select/container.vue'),
    meta: {
      isCssInVue: true,
      pageType: 'groupSelect',
      needLogin: false,
    }
  },
  {
    path: 'wishlist/groups/share',
    name: 'wishlistGroupShare',
    component: () => import(/* webpackChunkName: "wish-group-share" */ '../../user/child_pages/wishlist_v2/group_share/container.vue'),
    meta: {
      isCssInVue: true,
      pageType: 'wishlistShare',
      notNeedCommonHeader: false,
      needLogin: false,
      keepAlive: true,
    }
  },
  {
    path: 'wishlist/groups/:group_id',
    name: 'UserWishlistGroup',
    component: () => import(/* webpackChunkName: "wish-group-details" */ '../../user/child_pages/wishlist_v2/group_detail/container.vue'),
    meta: {
      isCssInVue: true,
      pageType: 'wishlistGroup',
      needLogin: false,
      keepAlive: true,
    }
  },
]
