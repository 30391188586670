const { langPath, language } = gbCommonInfo
const designerListRoutes = [
  {
    path: `${langPath}/designer_list`,
    component: () => import(/* webpackChunkName: "designers" */ '../../designer_list/container.vue'),
    name: 'designerList',
    meta: {
      isCssInVue: true,
      pageType: 'designers',
      scrollTop: false,
      keepAlive: true,
      notNeedCommonHeader: true,
      // navTitle: language.SHEIN_KEY_PWA_19254,
      navTitle: 'SHEIN X',
      toSavePosition: ['designerDetail']
    }
  },
  {
    path: `${langPath}/designer/:bdid`,
    component: () => import(/* webpackChunkName: "designerDetail" */ '../../config_designer_detail/designerDetail/index.vue'),
    name: 'designerDetail',
    meta: {
      pageType: 'designerDetail',
      scrollTop: false,
      keepAlive: false,
      notNeedCommonHeader: true,
      navTitle: language.SHEIN_KEY_PWA_19308,
      isCssInVue: true,
    }
  },
  {
    path: `${langPath}/designer/:bdid/:displayName`,
    redirect: `${langPath}/designer/:bdid`
  }
]

export {
  designerListRoutes
}
