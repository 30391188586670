import { getCurrentInstance, computed } from 'vue'

export const useStore = () => {
  const vm = getCurrentInstance()
  if (!vm) throw new Error('must be called in setup')
  return vm.proxy.$store
}

export const useGlobalMapState = (stateKeys) => {
  const store = useStore()
  return Object.fromEntries(
    stateKeys.map(key => [
      key,
      computed(() => store.state[key])
    ])
  )
}

export const useGlobalActions = (actionKeys) => {
  const store = useStore()
  return Object.fromEntries(
    actionKeys.map(action => [
      action,
      (...args) => store.dispatch(`${action}`, ...args)
    ])
  )
}

export const useGlobalMapMutations = (mutationKeys) => {
  const store = useStore()
  return Object.fromEntries(
    mutationKeys.map(mutation => [
      mutation,
      value => store.commit(`${mutation}`, value)
    ])
  )
}

