import { prefetchResource } from 'public/src/services/prefetchResource/index.js'
export const createAdvertisingRoute = langPath => ({
  path: `${langPath}/global/marketing.html`,
  name: 'page_advertising',
  meta: {
    pageType: 'page_advertising',
    keepAlive: true,
    isCssInVue: true,
  },
  component: prefetchResource.importAsyncComponent({
    chunkName: 'advertising-page', // 跟webpackChunkName保持一致
    componentFactory: () => import(/* webpackChunkName: "advertising-page" */ '../../advertising/container.vue')
  })
})
