
import { ref, computed, onMounted, getCurrentInstance } from 'vue'
import { markPoint } from 'public/src/services/mark/index.js'
import { preloadGoodsRequest } from 'public/src/pre_requests/modules/productList/spaForHydration.js'
import {
  hasRobotEntrance,
  gotoRobot
} from 'public/src/pages/common/robotEntrance'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { isLogin as isLoginFn } from 'public/src/pages/common/utils/index.js'
import { useGlobalMapState } from './store.js'

export default function useCategory({ route, isStorePage, isStoreTopTabSticky }) {
  const showCategory = computed(() => {
    // 店铺页并且店铺tab吸顶时不显示
    if(isStorePage.value && isStoreTopTabSticky.value){
      return false
    }
    const excludePages = ['page_category']
    return !excludePages.includes(route.value.name)
  })
  const bSlideCategory = ref(null)
  const { proxy } = getCurrentInstance()

  daEventCenter.addSubscriber({ modulecode: '1-14-1' })
  const { unReadChatNum } = useGlobalMapState(['unReadChatNum'])
  
  const windowOneTrust = computed(
    () => typeof window !== 'undefined' && window.OneTrust
  )
  const isSupportOneTrust = computed(() => gbCommonInfo.IS_SUPPORT_ONETRUST)
  
  const handleOntrustClick = data => {
    bSlideCategory.value?.closeDrawer()
  }
  
  function preload(url, type) {
    if (!url) return
    if (type === 'pointerover') {
      preloadGoodsRequest.triggerNotice(url)
    } else {
      preloadGoodsRequest.click(url)
    }
  }
  
  function goRobot() {
    let page = ''
    if (proxy.$route.name == 'page_store') {
      page = 'storePage'
      daEventCenter.triggerNotice({
        daId: '1-14-1-17',
        extraData: {
          store_code: gbCommonInfo.robotStoreData?.storeCode || '',
          page_name: 'page_store'
        }
      })
    }
    if (proxy.$route.name == 'page_goods_detail') page = 'productDetailPage'
    gotoRobot(page)
  }
  
  async function gotoRouter(path) {
    markPoint('toNextPageClick', 'public')
    const isLogin = isLoginFn()
    if (!isLogin && path.indexOf('/user/wishlist') === -1) {
      // const bextra = route === `${langPath}/user/wishlist` ? 'me' : 'other'
      SHEIN_LOGIN.show({
        show: true,
        from: 'other',
        bextra: 'me',
        loaded() {
          setTimeout(() => {
            bSlideCategory.value?.closeDrawer()
          }, 1000)
        },
        cb: () => {
          proxy.$router.push(path)
        }
      })
    } else {
      bSlideCategory.value?.closeDrawer()
      if (path.indexOf('/user/wishlist') > -1) {
        // location.href = route
        proxy.$routerGoWish()
      } else {
        proxy.$router.push(path)
      }
    }
  }
  
  function goPage(page) {
    if (page === 'gotoRobot') {
      goRobot()
    } else {
      gotoRouter(page)
    }
  }
  onMounted(() => {
    setTimeout(() => {
      appEventCenter.$on('refreshCategoryCrowdInfo', () => {
        if (bSlideCategory.value) {
          bSlideCategory.value.refreshTopNav()
        }
      })
    })
  })


  return { bSlideCategory, showCategory, windowOneTrust, unReadChatNum, isSupportOneTrust, handleOntrustClick, goPage, preload }
}
