import { throttle } from '@shein/common-function'

/**
 * 滚动方法使用
 * new Scroll({
 *    callback: 滚动回调,
 *    delay: 滚动延时(默认200ms)
 * })
 */ 

class Scroll {
  constructor({ callback, delay, document } = {}) {
    if (!window) return
    this.document = document || window.document.body
    this.delay = delay
    this.beforeScrollTop = -1
    window.addEventListener('scroll', this._handleScroll())
    this.fn = callback
  }

  _handleScroll() {
    if (!this.delay) {
      return () => {
        if (this.stop) return
        this.stop = true
        requestAnimationFrame(() => {
          this._scroll()
          this.stop = false
        })
      }
    }

    return throttle({
      func: () => {
        this._scroll()
      },
      wait: this.delay
    })
  }

  _scroll() {
    const bodyTop = Math.abs(document.body.style?.top?.replace('px', '')) // 在对话框、弹框弹起时，获取body的top值。 不然固定后的值为0px
    let afterScroll = bodyTop || this.document.scrollTop || window.document.documentElement.scrollTop
    const delta = afterScroll - this.beforeScrollTop
    if (delta > 0) {
      if (afterScroll > 0) {
        this.fn({
          scrollTop: afterScroll, 
          type: 'down'
        })
      }
    } 
    if (delta < 0) {
      this.fn({
        scrollTop: afterScroll, 
        type: 'up'
      })
    }
    this.beforeScrollTop = afterScroll
  }

  removeEventListener() {
    this.stop = false
    window.removeEventListener('scroll', this._handleScroll)
  }
}

export default Scroll
