const { langPath } = gbCommonInfo
const buyprimeRoutes = [
  {
    path: `${langPath}/buyprime/:productCode`,
    name: 'page_buy_prime',
    meta: {
      keepAlive: false,
      pageType: 'buy_prime',
      notNeedCommonHeader: true,
      orderType: 'buyprime',
      isCssInVue: true
    },
    component: () => import(/* webpackChunkName: "buy-prime-checkout" */ '../../buy_prime/container.vue')
  }
]

export {
  buyprimeRoutes
}
