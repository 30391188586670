export const createSecondaryHomeRoute = (langPath = '') => {
  return {
    path: `${langPath}/secondaryhome`,
    name: 'secondary_home',
    component: () => import(/* webpackChunkName: "native_category_landing_pagex" */ '../../secondary_home/SecondaryHome.vue'),
    meta: {
      keepAlive: true,
      pageType: 'index',
      monitorTag: 'index',
      isCssInVue: true,
      notNeedCommonHeader: true,    // 不需要公共头部
    }
  }
}
