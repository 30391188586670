// import { queryCartABT, queryCartPromotion, fetchMallShippingInfo } from 'public/src/pages/cartNew/utils/fetcher.js'
import { queryMallInfo } from 'public/src/pages/product_app/store/modules/cart_new/api.js'
import { prefetchResource } from 'public/src/services/prefetchResource/index.js'
import { preloadImg } from 'public/src/pages/ccc-campaigns/components/common/utils.js'
import schttp from 'public/src/services/schttp'

const { langPath } = gbCommonInfo
let pendingding
let ready
if (location.pathname.endsWith('/cart')) {
  window.fetchCartContentInfo = null
}
const cartRoutes = [
  {
    path: `${langPath}/cart`,
    name: 'cart',
    // component: () => import(/* webpackChunkName: "cart_index_new" */ '../../cartNew/container.vue'),
    component: prefetchResource.importAsyncComponent({
      chunkName: 'cart_index_new', // 跟webpackChunkName保持一致
      componentFactory: () => import( /* webpackChunkName: 'cart_index_new' */ '../../cartNew/container.vue')
        .then(r => ready || !pendingding ? Promise.resolve(r) : pendingding.then(() => { pendingding = null; return Promise.resolve(r) }))
    }),
    beforeEnter: (to, from, next) => {
      if (from.name && from.name != 'cart') {
        if (window.fetchCartContentInfo !== null && gbCommonInfo.CART_PRERENDER_SWITCH === 'on') {
          ready = !!window.fetchCartContentInfo
          pendingding = (window.fetchCartContentInfo || schttp({ url: `/api/cart/get?prefetch=1&gbSuiSsrData=${window.gbSuiSsrData?.join?.() || ''}` })).then((res) => {
            return new Promise(resolve => {
              let { contextForSSR, ssrHTML, ssrVueCSS, appendedSelector, ssrSuiCSS } = res || {}
              if (contextForSSR) {
                if (ssrHTML && ssrVueCSS) {
                  if (ssrSuiCSS && appendedSelector) {
                    document.querySelector('#ssrSuiCSS').textContent += ssrSuiCSS
                    window.gbSuiSsrData = [... new Set((window.gbSuiSsrData || []).concat(appendedSelector))]
                  }
                  window._gb_app_.$store.state.rootStatus.exchangingSpaScene = true;
                  [document.querySelector('#header-app'), document.querySelector('#top-banner')].forEach(ele => {
                    ele && (ele.style.display = 'none')
                  })
                  document.querySelector('#prerender-app').appendChild(document.createRange().createContextualFragment(ssrHTML).querySelector('#cart-app'))
                  document.querySelector('#prerender-css-cart').innerHTML = ssrVueCSS
                  document.querySelector('#prerender-app #cart-app').setAttribute('data-server-rendered', true)
                  let goodsImgs
                  const imgs = Array.from(document.querySelectorAll('#prerender-app #cart-app .hydrate_img')).map(ele => ele.src)
                  if (imgs.length) goodsImgs = preloadImg([... new Set(imgs)])
                  requestAnimationFrame(() => { // 上屏前
                    window.cartSsrData = contextForSSR
                    goodsImgs
                      ? goodsImgs.then(() => requestAnimationFrame(() => resolve()))
                      : requestAnimationFrame(() => resolve())
                  })
                } else {
                  window.cartSsrData = contextForSSR
                  resolve()
                }
              } else {
                resolve()
              }
            })
          }).then(() => ready && next()).catch(() => location.reload())
          window.fetchCartContentInfo = null
          ready || next()
        } else {
          const cartInfo = window._gb_app_?.$store?.state?.cartInfo || {}
          const carts = cartInfo.carts || []
          if(carts.length){
            for(let i = 0; i < Math.min(4, carts.length); i++){
              let goods_img = carts[i].product?.goods_img || ''
              if(goods_img){
                goods_img = goods_img.replace(/^https?\:/, '')
                let isSupportWeb = gbCommonInfo.RESOURCE_SDK?.isSupportWeb || ''
                let img = new Image()
                img.src = Boolean(isSupportWeb) ? goods_img.replace(/\b(\.jpg|\.png|\.gif)\b/, '.webp') || '' : goods_img
              }
            }
          }
          
          to.meta.fetchCartFirstScreenSourcePromise = Promise.all([queryMallInfo()])
          next()
        }
      } else {
        next()
      }
    },
    meta: {
      keepAlive: true,
      pageType: 'cart',
      // toSavePosition: ['page_goods_detail'],
      notNeedCommonHeader: true,
      monitorTag: 'cart',
      isCssInVue: true,
    }
  },
  {
    path: `${langPath}/cart/share/landing`,
    name: 'cartShareLanding',
    component: () => import(/* webpackChunkName: "cart_share" */ '../../cart_share_landing/container.vue'),
    meta: {
      notNeedCommonHeader: true,
    },
  },
  {
    path: `${langPath}/cart/add_on_item`,
    name: 'cartAddOnItem',
    component: () => import(/* webpackChunkName: "cart_add_on_item" */'../../cart_add_on_item/container.vue'),
    meta: {
      keepAlive: false,
      pageType: 'cart',
      notNeedCommonHeader: true,
      monitorTag: 'cart-add-on-item',
      isCssInVue: true,
    },
  }
]

export {
  cartRoutes
}
