const { langPath, IS_RW } = gbCommonInfo
const smsUnsubscribeRoutes = [
  {
    path: `${langPath}/sms_unsubscribe`,
    component: () => import('../../sms_unsubscribe/container.vue'),
    name: 'page_sms_unsubscribe',
    meta: {
      navTitle: IS_RW ? 'ROMWE' : 'SHEIN',
      notNeedCommonHeader: true,
      pageType: 'sms_unsubscribe',
      isCssInVue: true
    }
  },
  {
    path: `${langPath}/sms_unsubscribe/result`,
    component: () => import('../../sms_unsubscribe/result.vue'),
    name: 'page_sms_unsubscribe_result',
    meta: {
      navTitle: IS_RW ? 'ROMWE' : 'SHEIN',
      notNeedCommonHeader: true,
      isCssInVue: true
    }
  }
]

export {
  smsUnsubscribeRoutes
}
