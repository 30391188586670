/**
 * 全场景购物车入口丰富利诱点信息
 * wiki.pageId=1184179869
 */

import { cartTagTip } from '@shein-aidc/bs-sdk-cart-tag-tip'
import { isObject } from '@shein/common-function'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import schttp from '@/public/src/services/schttp'
import { getLanguage } from '@/public/src/services/api/index.js'
import Cache from './cache'
import Free from './free'
import Save from './save'
import Gift from './gift'
import ReturnCoupon from './returnCoupon'
import BestCoupon from './bestCoupon'
import Flash from './flash'
import PriceReduced from './priceReduced'
import Lowstock from './lowstock'
import { getQuickCart, getHeaderCart, getFooterCart } from './getCart'

import ScrollEventListener from './scrollEventListener'

// import { v2Test as testdata } from './testDataByCartTagTips'

// 监听页面滚动时，打断动画
new ScrollEventListener(bool => {
  if (bool) {
    // FR-16257 需求去掉
    // const quickCart = getQuickCart()
    // if (quickCart) quickCart.clearAnimate?.()

    const footerCart = getFooterCart()
    if (footerCart) footerCart.clear?.()

    const headerCart = getHeaderCart()
    if (headerCart) headerCart.clear?.()
  }
})

let updateAbortController

class PromiseActuator {
  constructor() {
    this.promise = null
    this.resolve = null
    this.state = 'pending'

    this.init()
  }

  init() {
    this.promise = new Promise(r => {
      this.resolve = (d) => {
        this.state = 'fulfilled'
        r(d)
      }
    })
  }
}

class CartTagTips {
  constructor() {
    // 最高优的利诱信息
    this.cache = new Cache('cart_tag_tips__main', {
      initCallback: val => {
        if (typeof window === 'undefined') return
        window?._gb_app_?.$store?.commit('changeCartTagTips', val)
      },
      setCallback: val => {
        if (typeof window === 'undefined') return
        window?._gb_app_?.$store?.commit('changeCartTagTips', val)
      }
    })
    this.cartTagTip = cartTagTip
    // 所有利诱信息
    this.cache4info = new Cache('cart_tag_tips__info')
    // 缓存手动调用展示过的气泡
    this.cache4tipsHistory = new Cache('cart_tag_tips__tips_history')
    // 当前展示气泡的利诱信息
    this.cache4tips = new Cache('cart_tag_tips__tips')
    this.tipTotalCache = new Cache('cart_tag_tips__tip_total')
    this.cache4cartList = new Cache('cart_tag_tips__cart_list')
    this.onceTipCache = new Cache('cart_tag_tips__once_tip')
    this.abtCartentranceinfo = this.getAbt()
    this.apolloConfig = {
      maxTipTime: 3, // 最多只能展示气泡次数,初始化时获取
      stockLimit: 5,
    }
    this.initPromiseActuator = new PromiseActuator()
    this.updatePromiseActuator = null
    this.preRequestPromiseActuator = null

    const language = new Promise((r, j) => {
      if (typeof window === 'undefined') return r({})
      getLanguage('cart_tag_tips').then(res => {
        r(res?.language || {})
      }).catch(j)
    })

    this.free = new Free({ language, turnOnCouponFree: true })
    this.save = new Save({ language })
    this.gift = new Gift({ language })
    this.returnCoupon = new ReturnCoupon({ language })
    this.bestCoupon = new BestCoupon({ language })
    this.flash = new Flash({ language })
    this.priceReduced = new PriceReduced({ language })
    this.lowstock = new Lowstock({ language })

    this.init()
  }

  async initialize() {
    const abt = await this.abtCartentranceinfo
    if (abt.isBff === 'on') {
      const isManualCall = await this.isManualCall()
      this.cartTagTip.initialize({
        disabledTagTip: abt.switch !== 'on',
        needUpdateCartNum: false,
        autoUseCoupon: abt.autoCouponSwitch === 'on',
        requestType: isManualCall ? 2 : 1,
        getCountryId() {
          let addressCookie = localStorage.getItem('addressCookie')
          let countryId = ''
          try {
            addressCookie = JSON.parse(addressCookie)
            countryId = addressCookie?.countryId ?? ''
          } catch {
            return countryId
          }
          return countryId
        },
      })
    }
  }

  // 业务层通过调用 window._gb_cart_tag_tips_.update() 触发此方法
  async update(config = {
    excludeScroll: false,
    noTips: false,
    cartInfo: null,
    needUpdateCartNum: false, // BFF 新增
    callback: null, // BFF 新增
  }) {
    try {
      if (typeof window === 'undefined') return 'window is not defined'

      if (await this.isManualCall()) return '当前页面不允许调用 update'

      const abt = await this.abtCartentranceinfo
      if (abt.switch !== 'on' && abt.listSwitch !== 'on') return 'abt Cartentranceinfo is off'

      // TODO: 兼容 BFF
      if (abt.isBff === 'on') {
        return cartTagTip.update({
          ...config,
          needUpdateCartNum: false, // 统一由 vuex mutations changeCartInfo 更新
        })
      }

      // 获取 free gift save 信息
      const info = await this.getApi(abt)
      this.cache4info.set(info)

      if (!info) {
        this.cache.set(null)
        this.callAnimation(abt) // 有利诱信息状态变为无利诱信息状态，过渡动画
        return 'info is null'
      }

      // 获取最高展示优先级的 tag
      let newTag = this.getTag(abt, info)
      // 没找到就是abt没配置对应的利诱信息展示
      if (!newTag) {
        this.cache.set(null)
        this.callAnimation(abt)
        return 'tag is null'
      }

      // 如果业务要求不提示，
      // 或者 tipTotalCache.data 达到最大次数，
      // 则 不满足提示条件
      const noTips = !!config.noTips || this.isOverMaxTip()
      // 缓存气泡
      const history = this.cache4tipsHistory.get() || []

      // 如果满足提示条件，
      // 并且 newTag 与 cache.data 类型一致，
      // 并且 newTag 存在 history 中,
      // 则 过滤 cache.data 和 history 类型获取新的 newTag
      const cacheData = this.cache.get()
      if (
        !noTips && (
          cacheData?.type === newTag.type ||
          history.find(f => f.type === newTag.type)
        )
      ) {
        const tag = this.getTag(abt, info, true)
        // 满足气泡提示
        if (tag && tag.tips && !tag.noTips) {
          this.callAnimation(abt, [
            {
              ...tag,
              excludeScroll: config.excludeScroll,
            }, {
              ...cacheData,
              noTips: true,
            }
          ])
          return 'callAnimation type1'
        }
        if (newTag.tag || (newTag.tips && !newTag.noTips)) {
          this.cache.set(newTag)
          this.callAnimation(abt, [
            {
              ...newTag,
              excludeScroll: config.excludeScroll,
            }
          ])
          return 'callAnimation type2'
        }
        return 'after filter, tag is null'
      } else {
        if (noTips) newTag.noTips = true
        // newTag 与 currentTag 类型不一致，直接赋值，触发动画方法
        this.cache.set(newTag)
        this.callAnimation(abt, [
          {
            ...newTag,
            excludeScroll: config.excludeScroll,
          }
        ])
        return 'callAnimation type3'
      }
    } catch (error) {
      return error
    }
  }

  async showtime(config = {}) {
    if (typeof window === 'undefined') {
      config.callback?.()
      return 'window is not defined'
    }

    const abt = await this.abtCartentranceinfo
    if (abt.switch !== 'on') {
      config.callback?.()
      return 'abt Cartentranceinfo is off'
    }

    if (!await this.isManualCall()) {
      config.callback?.()
      return '当前页面不允许调用 showtime'
    }

    // TODO: 兼容 BFF
    if (abt.isBff === 'on') return cartTagTip.showtime(config)

    let info

    if (this.preRequestPromiseActuator) {
      info = await this.preRequestPromiseActuator.promise
      this.preRequestPromiseActuator = null
    } else {
      info = await this.getApi(abt, { diffCache: false })
    }

    this.cache4info.set(info)

    // 过滤后，最高优的标签气泡
    const tipsRes = this.getTips(abt, info, true) // 返回 freeshipping/save/gift/lowerafteradd/lowstock/flash/cartcoupon 中的一种
    // 最高优的标签
    const tagRes = this.getTag(abt, info) // 返回 freeshipping/save/gift 中的一种
    const noTips = this.isOverMaxTip()

    // 缓存气泡
    const history = this.cache4tipsHistory.get() || []

    // 有标签/气泡展示时
    if (!noTips && tipsRes) {
      history.push(tipsRes)
      this.cache4tipsHistory.set(history)

      // 如果 tipsRes 有 tag 时，正常展示 tag 和 tip
      if (tipsRes.tag) {
        // 如果不相等，说明 tagRes 优先级高于 tipsRes
        if (tagRes && tagRes.type !== tipsRes.type) {
          // [高-低-高]
          this.cache.set(tagRes)
          this.callAnimation(abt, [tipsRes, tagRes], config)
          return '有标签+翻转/有气泡'
        } else {
          // [无-高] 或 [高-高]
          this.cache.set(tipsRes)
          this.callAnimation(abt, [tipsRes], config)
          return '有标签/有气泡'
        }
      } else {
        // 当 tagRes 有 tag 时，需要把 tagRes.tips 替换为更高优的 tipsRes
        if (tagRes?.tag) {
          const data = {
            ...tagRes,
            tips: tipsRes,
          }
          this.cache.set(data)
          this.callAnimation(abt, [data], config)
          return '有标签/有气泡'
        } else {
          // 兜底，只展示 tipsRes 的 tip
          this.cache.set(tipsRes)
          this.callAnimation(abt, [tipsRes], config)
          return '无标签/有气泡'
        }
      }
    } else {
      if (tagRes) {
        const data = {
          ...tagRes,
          noTips: true,
        }
        this.cache.set(data)
        this.callAnimation(abt, [data], config)
        return '有标签/无气泡'
      } else {
        this.cache.set(null)
        this.callAnimation(abt, null, config)
        return '无标签/无气泡'
      }
    }
  }

  /**
   * 利诱数据预取
   * 当调用 preRequest 后, 再调用 showtime 时, showtime 不再调用 getApi,
   * 而是拿 preRequest 预取的数据
   */
  async preRequest() {
    // TODO: 兼容 BFF
    const abtInfo = await this.abtCartentranceinfo
    if (abtInfo.isBff === 'on') return cartTagTip.preRequest()

    // 已经有一个 Promise 在 pending 中, 直接返回上一个 Promise
    if (this.preRequestPromiseActuator?.state === 'pending') return this.preRequestPromiseActuator.promise
    // 没有 Promise 或者 Promise 已经 fulfilled, 创建一个新的 Promise
    if (!this.preRequestPromiseActuator || this.preRequestPromiseActuator.state === 'fulfilled') this.preRequestPromiseActuator = new PromiseActuator()

    // 如果本次预请求还在初始化阶段，直接用初始化的数据
    if (this.initPromiseActuator.state === 'pending') {
      const info = await this.initPromiseActuator.promise
      this.preRequestPromiseActuator.resolve(info)
      return this.preRequestPromiseActuator.promise
    }

    const abt = await this.abtCartentranceinfo
    // abt 关闭时, 不预请求数据, 直接返回空
    if (abt.switch !== 'on') {
      this.preRequestPromiseActuator.resolve(null)
      return this.preRequestPromiseActuator.promise
    }

    const info = await this.getApi(abt, { diffCache: false })
    this.preRequestPromiseActuator.resolve(info)

    return this.preRequestPromiseActuator.promise
  }

  // 获取最高利诱信息
  async getCurrentTag() {
    // TODO: 兼容 BFF
    const abtInfo = await this.abtCartentranceinfo
    if (abtInfo.isBff === 'on') return cartTagTip.getCurrentTag()

    // 首次访问时，需要等到初始化获取最新状态后，再返回
    await this.initPromiseActuator.promise
    return this.cache.get()
  }

  // 获取所有利诱信息
  async getAllTag() {
    // 首次访问时，需要等到初始化获取最新状态后，再返回
    await this.initPromiseActuator.promise
    return this.cache4info.get()
  }

  // 返回埋点需要字段值信息，针对于0305需求：MR-15042 新增埋点参数添加
  async getExposeData() {
    // TODO: 兼容 BFF
    const abtInfo = await this.abtCartentranceinfo
    if (abtInfo.isBff === 'on') return cartTagTip.getExposeData()

    // 首次访问时，需要等到初始化获取最新状态后，再返回
    await this.initPromiseActuator.promise
    let exposeMatchTable = {
      'freeshipping': 'freeshipping',
      'save': 'activitypoint',
      'gift': 'freegift',
    }

    // 处理标签埋点相关
    let cache4infoData = this.cache4info.get() ?? {}
    let actual_point = Object.keys(cache4infoData).map(key => cache4infoData[key]?.tag && exposeMatchTable[key]).filter(f => f)

    // 处理气泡埋点相关
    let available_point_tip = []
    let actual_point_tip = '-'
    Object.entries(cache4infoData).forEach(arrItem=> {
      let [key, val] = arrItem
      // 有tip的
      if(val?.tips) {
        if(val?.category === 'coupon' && key === 'freeshipping') {
          available_point_tip.push('freecoupon')
        } else {
          available_point_tip.push(exposeMatchTable[key] ?? key)
        }
      }
    })
    const cache4tipsData = this.cache4tips.get() ?? {}
    if(cache4tipsData.tips) {
      if (isObject(cache4tipsData.tips)) {
        actual_point_tip = cache4tipsData.tips.type
      } else {
        actual_point_tip = cache4tipsData.category === 'coupon' ? 'freecoupon' : (exposeMatchTable[cache4tipsData.type] || cache4tipsData.type)
      }
    }
    return {
      available_point: actual_point.length ? actual_point.join(',') : '-', // 可展示标签类型
      actual_point: exposeMatchTable[this.cache.data?.type] || '-', // 实际可展示的标签类型
      actual_point_tip: actual_point_tip, // 实际可展示的气泡类型
      available_point_tip: available_point_tip.join(',') || '-' // 可展示气泡类型
    }
  }

  // 调用业务动画
  callAnimation(abt, stepList, config = {}) {
    const [ step1 = { type: 'default' }, step2 ] = stepList || []
    if (typeof window === 'undefined') return 'window is not defined'
    this.cache4tips.set(step1) // 当前展示气泡的利诱信息（不一定有气泡提示）
    const footerCart = getFooterCart() // 底部购物车实例
    const headerCart = getHeaderCart() // 顶部购物车实例
    const quickCart = getQuickCart()  // 购物车浮窗实例
    const listSwitch = abt?.listSwitch === 'on'
    const gbSwitch = abt?.switch === 'on'

    // 购物车浮窗
    if (listSwitch && quickCart) {
      if (step1.tips && !step1.noTips) this.tipTotalCache.set(this.tipTotalCache.data + 1)
      if(gbSwitch && headerCart) {
        // 购物车浮窗出发动画
        quickCart.animateProfit(
          [step1, step2],
          {
            callback: () => {
              if (!step2) {
                headerCart.setTag([
                  {
                    ...step1,
                    noTips: true
                  }
                ])
              }

              config.callback?.()
            }
          }
        )
        return 'quick cart'
      }
      quickCart.animateProfit([step1, step2], { callback: config.callback })
      return 'quick cart'
    }

    if (gbSwitch && footerCart) {
      if (step1.tips && !step1.noTips) this.tipTotalCache.set(this.tipTotalCache.data + 1)
      if (headerCart) {
        footerCart.setTag(
          [step1, step2],
          {
            callback: () => {
              if (!step2) {
                headerCart.setTag([
                  {
                    ...step1,
                    noTips: true,
                  }
                ])
              }

              config.callback?.()
            },
          }
        )
      } else {
        footerCart.setTag(
          [step1, step2],
          {
            callback: () => {
              config.callback?.()
            },
          }
        )
      }
      return 'footer'
    }

    if (gbSwitch && headerCart) {
      if (step1.tips && !step1.noTips) this.tipTotalCache.set(this.tipTotalCache.data + 1)
      headerCart.setTag(
        [step1, step2],
        {
          callback: () => {
            config.callback?.()
          },
        }
      )
      return 'header'
    }
  }

  /**
   * 获取最高优先级的 tag
   * @param {object} abt 利诱权重
   * @param {object} res 利诱信息
   * @param {boolean} filterCache 是否过滤 cache 和 cache4tipsHistory
   * @returns tag
   */
  getTag(abt, res, filterCache = false) {
    if (!res) return
    if (abt.switch !== 'on' && abt.listSwitch !== 'on') return false
    const filterTags = ['lowstock', 'lowerafteradd', 'flash', 'cartcoupon', 'returncoupon']
    const cacheData = this.cache.get()
    // 缓存气泡
    const history = this.cache4tipsHistory.get() || []

    // 找到abt配置中利诱级最高的
    const type = abt.priority?.find(f => {
      if (filterTags.includes(f)) return false
      if (
        filterCache && (
          f === cacheData?.type ||
          history.find(h => h.type === f)
        )
      ) return false
      return !!res[f]
    })
    return type && res[type]
  }

  /**
   * 获取最高优先级的 tips
   * @param {object} abt 利诱权重
   * @param {object} res 利诱信息
   * @param {boolean} filterCache 是否过滤 cache.data
   * @returns tips
   */
  getTips(abt, res, filterCache) {
    if (!res) return
    // 找到abt配置中利诱级最高的
    const type = abt.priority?.find(f => {
      if (filterCache && this.cache4tipsHistory.get()?.find(item => item.type === f)) return false
      const tip = res[f]
      if (tip?.tips) return true
      return false
    })
    return type && res[type]
  }

  async init({ skipCache } = {}) {
    // TODO: 兼容 BFF
    const abtInfo = await this.abtCartentranceinfo
    if (abtInfo.isBff === 'on') return

    // 有缓存，不需要获取最新状态
    if (!skipCache && this.cache.data) {
      this.initPromiseActuator.resolve()
      return
    }

    const abt = await this.abtCartentranceinfo
    if (abt.switch !== 'on' && abt.listSwitch !== 'on') {
      this.cache.set({ type: 'default' })
      this.cache4info.set(null)
      this.initPromiseActuator.resolve()
      return
    }

    let info
    try {
      info = await this.getApi(abt)
      const newTag = this.getTag(abt, info)
      this.cache4info.set(info)
      if (newTag) {
        this.cache.set(newTag)
      } else {
        this.cache.set({ type: 'default' })
      }
    } finally {
      this.initPromiseActuator.resolve(info)
    }
  }

  async getApi(abt, config) {
    if (!abt) return
    const countryId = this.getCountryId()
    const isNewuserStyle = abt.newuserStyle === 'on'
    let res
    try {
      updateAbortController?.abort()
      updateAbortController = new SchttpAbortCon()

      if (!this.updatePromiseActuator || this.updatePromiseActuator.state === 'fulfilled') this.updatePromiseActuator = new PromiseActuator()
      res = await schttp({
        url: '/api/cart/getCartAllInfo/get',
        method: 'POST',
        data: {
          countryId,
          autoUseCoupon: abt.autoCouponSwitch === 'on' ? 1 : 0
        },
        signal: updateAbortController?.signal,
      })
      this.cache4cartList.set(res?.cartBriefInfo?.item_list ?? [])
      this.updatePromiseActuator.resolve()
    } catch (e) {
      if (e.code !== 'ERR_CANCELED') {
        this.cache4cartList.set([])
        this.updatePromiseActuator.resolve()
      }
    }
    if (!res || res.code === -1) return null

    if (config?.onlyAjax) return res

    // TODO 写死测试数据，本地开发用
    // res = testdata.shift()
    // res = testdata[0]
    // console.log('testData', res)

    this.apolloConfig.maxTipTime = res.APOLLO_CONFIG?.CART_MAX_TIP_TIME ?? 3 // 从中间层获取到Apollo配置的气泡最大展示次数
    this.apolloConfig.stockLimit = res.APOLLO_CONFIG?.CART_INDUCEMENT_LIMIT?.stock_limit ?? 5 // 从中间层获取到Apollo配置的气泡最大展示次数
    const result = {}
    const freeData = await this.free.getData(res, config)
    if (freeData?.tag) {
      result.freeshipping = {
        type: 'freeshipping',
        ...freeData,
      }
    }
    const saveData = await this.save.getData(res.cartBriefInfo, config)
    if (saveData?.tag) {
      result.save = {
        type: 'save',
        ...saveData,
      }
    }
    const giftData = await this.gift.getData(res.cartBriefInfo, { ...config, isNewuserStyle })
    if (giftData?.tag) {
      result.gift = {
        type: 'gift',
        ...giftData,
      }
    }

    if (config?.diffCache === false) {
      const returnCouponData = await this.returnCoupon.getData(res, { isNewuserStyle })
      if (returnCouponData?.tips) {
        result.returncoupon = {
          type: 'returncoupon',
          ...returnCouponData,
        }
      }
      const bestCouponData = await this.bestCoupon.getData(res, { isNewuserStyle })
      if (bestCouponData?.tips) {
        result.cartcoupon = {
          type: 'cartcoupon',
          ...bestCouponData,
        }
      }
      const flashData = await this.flash.getData(res)
      if (flashData?.tips) {
        result.flash = {
          type: 'flash',
          ...flashData,
        }
      }
      const priceReducedData = await this.priceReduced.getData(res)
      if (priceReducedData?.tips) {
        result.lowerafteradd = {
          type: 'lowerafteradd',
          ...priceReducedData,
        }
      }
      const lowstockData = await this.lowstock.getData(res, this.apolloConfig)
      if (lowstockData?.tips) {
        result.lowstock = {
          type: 'lowstock',
          ...lowstockData,
        }
      }
    }

    // 这里统一过滤 abt 中没有配置的利诱
    if (abt.priority?.length) {
      const obj = abt.priority.reduce((pre, cur) => {
        if (result[cur]) pre[cur] = result[cur]
        return pre
      }, {})
      return Object.keys(obj).length ? obj : null
    }
    return null
  }

  /**
   * 获取国家 ID
   * @returns {String}
   */
  getCountryId() {
    let addressCookie = localStorage.getItem('addressCookie')
    let countryId = ''
    try {
      addressCookie = JSON.parse(addressCookie)
      countryId = addressCookie?.countryId ?? ''
    } catch {
      return countryId
    }
    return countryId
  }

  async getAbt() {
    try {
      if (typeof window === 'undefined') {
        return {
          autoCouponSwitch: 'off',
          switch: 'off',
          listSwitch: 'off',
          isBff: 'off',
        }
      }
      // !!!!!经列表产品确认，列表悬浮购物车有值时，值保持和 顶部和底部的 配置值一致
      const { Cartshowcoupon, Cartentranceinfo, CartEntranceInfoSwitchNew, CartEntranceInfoNewuser, listcartinfoSwitch } = await getUserAbtData()
      let switchNew = CartEntranceInfoSwitchNew?.param?.cart_entrance_info_switch_new || 'off'
      if (switchNew !== 'off') {
        switchNew = CartEntranceInfoSwitchNew?.param?.cart_entrance_info_switch_new?.split(',').reduce((pre, cur) => {
          const [key, value] = cur.split('=')
          pre[key] = value
          return pre
        }, {}) || {}
      }
      return {
        autoCouponSwitch: Cartshowcoupon?.param?.cart_show_coupon_switch || 'off',
        switch: Cartentranceinfo?.param?.cart_entrance_info_switch || 'off',
        switchNew,
        newuserStyle: CartEntranceInfoNewuser?.param?.cart_entrance_info_newuser || 'off',
        priority: Cartentranceinfo?.param?.cart_entrance_info_priority?.split(',') || null,
        listSwitch: listcartinfoSwitch?.param?.list_entrance_info_switch || 'off',
        isBff: gbCommonInfo.IS_NEW_HEAD ? 'on' : 'off',
      }
    } catch (error) {
      return {
        autoCouponSwitch: 'off',
        switch: 'off',
        switchNew: 'off',
        listSwitch: 'off',
        isBff: 'off',
      }
    }
  }

  async refresh() {
    const abt = await this.abtCartentranceinfo
    if (abt.isBff === 'on') {
      this.cartTagTip.refresh({ needUpdateCartNum: false })
      return
    }

    await this.init({ skipCache: true })
    const footerCart = getFooterCart()
    footerCart?.setCurrentData?.(this.cache.data)
  }

  async isManualCall() {
    const abt = await this.abtCartentranceinfo
    const map = {
      config_index: 'home',
      page_category: 'category',
      campaigns: 'new',
      UserIndex: 'me',
    }
    const key = map[_gb_app_.$route.name]

    // 不是底部导航栏的四个页面时，则判断当前页面是否有新的悬浮购物车
    if (!key) {
      return !!document.querySelector('.j-quick-cart-container')
      // return !!getNewQuickCart()
    }

    if (abt.switchNew === 'off') return false

    return abt.switchNew[key] === 'on'
  }

  isOverMaxTip() {
    return this.tipTotalCache.get() >= this.apolloConfig.maxTipTime
  }

  /**
   * 当用户首次访问网页时，显示一次利诱气泡
   */
  async onceTip() {
    if (this.onceTipCache.get()) return
    this.onceTipCache.set(true)

    const abt = await this.abtCartentranceinfo
    if (abt.isBff === 'on') return

    const isManualCall = await this.isManualCall() // 是否由外围触发利诱气泡
    const isOverMaxTip = window._gb_cart_tag_tips_.isOverMaxTip() // 是否超过了最大气泡显示次数

    if (isOverMaxTip || isManualCall) return
    const step = await this.getCurrentTag()
    this.callAnimation(abt, [step])
  }
}

const cartTagTips = new CartTagTips()

export default cartTagTips
