const { langPath } = gbCommonInfo

const shareEarnRoutes = [
  {
    path: `${langPath}/shareandearn`,
    name: 'shareandearn',
    meta: {
      keepAlive: false,
      noNeedEmitPageUnload: true,
      notNeedCommonHeader: true,
      pageType: 'shareAndEarn',
      isCssInVue: true
    },
    component: () => import(/* webpackChunkName: "shareearn_index" */'../../shareandearn/container.vue')
  },
  {
    path: `${langPath}/shareandearn/rewards`,
    name: 'shareandearnRewards',
    meta: {
      isCssInVue: true,
      keepAlive: false,
      noNeedEmitPageUnload: true,
      notNeedCommonHeader: true,
      pageType: 'shareAndEarn'
    },
    component: () => import(/* webpackChunkName: "shareandearnRewards" */'../../shareandearn/rewardsList.vue')
  },
  {
    path: `${langPath}/shareandearn/shareinvite`,
    name: 'shareandearnLanding',
    meta: {
      keepAlive: false,
      noNeedEmitPageUnload: true,
      notNeedCommonHeader: true,
      pageType: 'shareAndEarn',
      isCssInVue: true
    },
    component: () => import(/* webpackChunkName: "shareandearnLanding" */'../../shareandearn/shareLanding.vue')
  }
]

export {
  shareEarnRoutes
}
