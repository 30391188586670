const { langPath } = gbCommonInfo
import { prefetchResource } from 'public/src/services/prefetchResource/index.js'

const preSearchRoutes = [
  {
    path: `${langPath}/presearch`,
    name: 'pre_search',
    // component: () => import(/* webpackChunkName: "pre_search" */ '../../common/search_words/search.vue'),
    component: prefetchResource.importAsyncComponent({
      chunkName: 'pre_search',
      componentFactory: () => import(/* webpackChunkName: "pre_search" */ '../../common/search_words/search.vue')
    }),
    meta: {
      keepAlive: true,
      pageType: 'preSearch',
      isCssInVue: true,
      notNeedCommonHeader: true
    }
  }
]

export {
  preSearchRoutes
}
