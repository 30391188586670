import { getMessageLanguageApi } from './api'
import { isSwitchBffApiVersion } from 'public/src/pages/common/bff-new/index.js'
export async function getLang (langPath, callback) {
  //异步获取apollo是否切流开启bff
  const useMessageBffApi = isSwitchBffApiVersion(['userMessage_v1'])
  const useBffApi = useMessageBffApi?.['userMessage_v1'] || false
  let res = await getMessageLanguageApi({
    'pages': ['coupon_item', 'message']
  }, useBffApi)
  const result = useBffApi ? res?.info?.result : res
  callback(result)
}

