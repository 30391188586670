export default class Cache {
  #cache = new Map()

  set(key, value, { timeout = 1000 * 60 * 5 } = {}) {
    const cacheItem = {
      value,
      timeout: Date.now() + timeout
    }
    this.#cache.set(key, cacheItem)
  }

  get(key) {
    const cacheItem = this.#cache.get(key)
    if (!cacheItem) return null
    if (cacheItem.timeout < Date.now()) {
      this.clear(key)
      return null
    } else {
      return cacheItem.value
    }
  }

  clear(key) {
    this.#cache.delete(key)
  }

}
