import schttp from 'public/src/services/schttp'
import { abtservice } from 'public/src/services/abt'
import getCccRecommendConfig from 'public/src/pages/common/getCccRecommendConfig'
// import { mockNewUserBanner } from './mock'
import { LanguageKeys } from 'public/src/pages/user_index/service/consts'
const { SiteUID, appLanguage } = gbCommonInfo

export const fetchFirstScreenData = () => new Promise((resolve) => {
  Promise.all([
    getLanguageDataSer(LanguageKeys),
    getCenterFirstPartInfo(),
    getCenterSecondPartInfo(),
    getAbtInfo({ forceUpdate: true }),
    getCccCommunalSwitch(),
  ]).then(([language, baseInfo, baseOtherInfo, abtInfo, cccInfo]) => {
    resolve({
      language,
      baseInfo: baseInfo?.info || {},
      baseOtherInfo: baseOtherInfo?.info || {},
      abtInfo: abtInfo || {},
      cccInfo: cccInfo || {},
    })
  }).catch(() => {
    resolve()
  })
})

export const fetchFooterRcmdData = async ({
  getTrendCardFlow,
  trendFlowPageNum,
}) => {
  return await schttp({
    url: '/api/user/trend/flow/get',
    method: 'GET',
    params: { 
      getTrendCardFlow,
      fdPageNum: trendFlowPageNum,
    }
  })
}

/**
 * 获取多语言数据
 * @param {多语言key数组} params 
 * @returns 
 */
export const getLanguageDataSer = async (keys) => {
  if (!keys?.length) return
  const response = await schttp({
    url: '/system/configs/multi_language',
    method: 'POST',
    data: {
      languageKeys: keys,
    },
    useBffApi: true
  })
  const { code, info } = response || {}
  if (code === '0' && !!info?.result) {
    return info.result
  }
}

// base 数据
export const getCenterFirstPartInfo = ({ blackbox = '' } = {}) => {
  try {
    return schttp({
      url: '/user/center/first_part_info',
      method: 'GET',
      useBffApi: true,
      params: {
        blackbox: blackbox || undefined,
      }
    })
  } catch (e) {
    console.log('api err', e)
  }
}

// 额外补充数据
export const getCenterSecondPartInfo = () => {
  try {
    return schttp({
      url: '/user/center/second_part_info',
      method: 'GET',
      useBffApi: true,
    })
  } catch (e) {
    console.log('api err', e)
  }
}

// abt 数据
export const getAbtInfo = (() => {
  let pending = null
  return async ({
    forceUpdate = false
  } = {}) => {
    if (forceUpdate || !pending) {
      pending = schttp({
        url: '/abt/get_abt_info',
        method: 'GET',
        useBffApi: true,
      })
    }
    const response = await pending
    if (response?.code !== '0' && !response.info) return {}
    return response.info
  }
})()

// ccc 数据
export const getCccCommunalSwitch = () => {
  return schttp({
    url: '/ccc/communal/switch',
    method: 'GET',
    useBffApi: true,
  })
}

export const getNewsListCccConfigApi = async () => {
  const response = await schttp({
    url: '/ccc/common_component?cccPageType=personalCenterNewsPage&timeZone=GMT+8',
    method: 'GET',
    useBffApi: true,
  })
  if (response?.code !== '0' || !response.info) return
  return response.info
}

export const getNewUserBenefitData = async () => {
  return schttp({
    url: '/user/center/get_new_customer_right?cccPageType=personalCenterPageNew',
    method: 'GET',
    useBffApi: true,
  })
}

/**
 * 获取底部ccc推荐组件数据
 */
export async function fetchFooterRcmdListCccConfig() {
  try {
    // eslint-disable-next-line @shein-aidc/abt/abt
    const abtData = await abtservice.getUserAbtResult({ newPosKeys: 'pwameRecommend' })
    if (abtData?.pwameRecommend?.p?.pwameRecommend !== 'On') return
    const [context, language] = await getCccRecommendConfig(
      'personalCenterPage',
      'personalCenterPage',
      {
        contextParams: {
          goods_ids: '',
          cate_ids: '',
        }
      })

    if (!context?.content?.length || !context.content[0]?.composeRecommendFlow?.list?.info?.products?.length) {
      return
    }
    return {
      context,
      language,
    }
  } catch (e) {
    return
  }
}

// 暂时没用上
// 获取b2b2c文案
export const queryOrderStatusText = () => {
  let storageKey = 'ORDER_STATUS_TEXT'
  let textKey = `${SiteUID}_${appLanguage}_key`
  let allSiteCacheData = null
  let curSiteCacheData = {
    textInfo: [],
    currentTime: 0
  }
  try {
    allSiteCacheData = JSON.parse(localStorage.getItem(storageKey))
    curSiteCacheData = {
      ...curSiteCacheData,
      textInfo: allSiteCacheData?.[textKey] || [],
      currentTime: allSiteCacheData?.currentTime || 0
    }
  } catch (err) {
    curSiteCacheData = {
      textInfo: [],
      currentTime: 0
    }
  }
  return new Promise((resolve, reject) => {
    let { currentTime = 0, textInfo = [] } = curSiteCacheData
    let expired = currentTime + 2 * 60 * 60 * 1000 < new Date().getTime()
    if (textInfo?.length && !expired) {
      resolve(textInfo)
    } else {
      schttp({
        url: '/api/orders/base/getOrderStatusText/get'
      })
        .then(res => {
          if (res?.code == '1000') {
            let result = allSiteCacheData || {}
            if (!expired) {
              result = {
                ...result,
                [textKey]: res?.data?.orderStatusNoticeDetails || []
              }
            } else {
              result = {
                ...result,
                [textKey]: res?.data?.orderStatusNoticeDetails || [],
                currentTime: new Date().getTime()
              }
            }
            localStorage.setItem('ORDER_STATUS_TEXT', JSON.stringify(result))
            resolve(result?.[textKey] || [])
          } else {
            resolve([])
          }
        })
        .catch(() => {
          reject([])
        })
    }
  })
}

export const getUnionOrderListPageData = async (statusType) => {
  const res = await schttp({
    method: 'POST',
    url: '/api/orders/base/unionOrderListPageData/get',
    data: {
      status_type: statusType,
    },
    schttp: { needLogin: true }
  })
  return res
}
