export const createStoreFlashSaleRoute = (langPath = '') => {
  return {
    path: `${langPath}/store/flash-sale`,
    name: 'store_flash_sale',
    meta: {
      keepAlive: true,
      pageType: 'store_flash_sale',
      monitorTag: 'store-flash-sale',
      isCssInVue: true,
      notNeedCommonHeader: true,
    },
    component: () => import( /* webpackChunkName: "store_flash_sale" */ '../../store_flash_sale/container.vue'),
  }
}
