export const createBrandZonePromotionRoute = (langPath = '') => {
  return {
    path: `${langPath}/brand-zone-promotion`,
    name: 'brand_zone_promotion',
    meta: {
      keepAlive: true,
      pageType: 'brand_zone_promotion',
      monitorTag: 'brand_zone_promotion',
      isCssInVue: true,
      notNeedCommonHeader: true,
    },
    component: () => import( /* webpackChunkName: "brand_zone_promotion" */ '../../brand_zone_promotion/index.vue'),
  }
}
