<template>
  <SPopover
    v-model="isPopoverShow"
    class="store-header__more"
    trigger="unset"
    trigger-type="user"
    theme="light"
    placemen="bottom-end"
    :prop-style="{
      zIndex: 301,
      transform: 'translate3d(0, 0, 301px)'
    }"
    append-to-body
  >
    <template slot="reference">
      <div 
        ref="headerMoreIcon" 
        class="store-header__more-wrapper"
        @click.stop="handleHeaderMoreIcon"
      >
        <i 
          class="suiiconfont sui_icon_more_20px store-header__more-icon"
        ></i>
      </div>
    </template>
    <ul class="store-header__more-list">
      <li
        v-for="(item, index) in navList"
        :key="index"
        class="header-more__list-item haptics-feedback"
        @click.stop="handleListItem(item, index)"
      >
        <Icon 
          :name="item.icon" 
          size="18px" 
        />
        <span class="">{{ item.name }}</span>
      </li>
    </ul>
  </SPopover>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'StoreHeaderMore',
})
</script>
<script setup>
import { ref, computed, watch } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'
import { Popover as SPopover } from '@shein/sui-mobile'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import { markPoint } from 'public/src/services/mark/index.js'
import { getDefaultConfig } from './storeHeaderUtils.js'
import { useStorePageData } from '../../hooks/useStoreHeader.js'


const { language, langPath, router, route, routerGoWish } = useStorePageData()
const isPopoverShow = ref(false)
const headerMoreIcon = ref(null)

const navList = computed(()=>{
  return getDefaultConfig().map((item)=>{
    const { i18nKey, defaultName, ...rest } = item || {}
    return {
      ...rest,
      name: language.value[i18nKey],
    }
  })
})

const handleClosePopver = ()=>{
  isPopoverShow.value = false
}
const touchOutside = (e)=>{
  if(headerMoreIcon.value && headerMoreIcon.value.contains(e.target)) return
  handleClosePopver()
}

const bindEvnets = ()=>{
  window.addEventListener('scroll', handleClosePopver, false)
  window.addEventListener('touchend', touchOutside, false)
}


const cleanEvents = ()=>{
  window.removeEventListener('scroll', handleClosePopver, false)
  window.removeEventListener('touchend', touchOutside, false)
}

watch(()=>isPopoverShow.value, (val)=>{
  if(val){
    bindEvnets()
  }else{
    cleanEvents()
  }
})

const handleHeaderMoreIcon = ()=>{
  isPopoverShow.value = !isPopoverShow.value
}
const handleListItem = (item)=>{
  const { fnName } = item
  if(listItemEvents[fnName]){
    listItemEvents[fnName](item)
  }
  isPopoverShow.value = false
}
// 店铺关注页
const handleLinkToFollowedShops = ()=>{
  daEventCenter.triggerNotice({
    daId: '2-9-13',
  })
  const login = isLogin()
  if (!login){
    return SHEIN_LOGIN.show({
      show: true,
      cb: () => {
        handleLinkToFollowedShops()
      }
    })
  }
  router.push({
    path: `${langPath}/store/following`,
    query: {
      page_from: 'page_store',
      entrance: 'following',
    },
  })
}
// 收藏
const handleWishlistClick = ()=>{
  markPoint('toNextPageClick', 'public')
  daEventCenter.triggerNotice({
    daId: '2-9-14',
  })
  if(routerGoWish){
    routerGoWish({ pf: 'topbar' }, true)
  }
    
}
// 足迹
const handleLinkToRecentViewed = ()=>{
  daEventCenter.triggerNotice({
    daId: '2-9-10',
  })
  router.push(`${langPath}/user/index?rt=rv`)
}
// 分享
const handleShareClick = ()=>{
  daEventCenter.triggerNotice({
    daId: '2-9-16',
  })
  window.appEventCenter &&  window.appEventCenter.$emit('handleHeaderShareForStore')
}
// 客服
const handleLinkToSupport = ()=>{
  daEventCenter.triggerNotice({
    daId: '2-9-17',
  })
  router.push(`${langPath}/user/support`)
}

const listItemEvents = {
  handleLinkToFollowedShops,
  handleWishlistClick,
  handleLinkToRecentViewed,
  handleShareClick,
  handleLinkToSupport,
}


</script>
<style lang="less">
.store-header__more{
    display: block;
}
.store-header__more-wrapper {
    padding-left: 12px;
}
.store-header__more-icon{
    font-size: 24px!important;
}
.store-header__more-list {
    font-size: 14px;
    padding: 2/37.5rem 0;
    position: relative;
    left: -.32rem;

    .header-more__list-item {
        display: flex;
        align-items: center;
        height: 44/37.5rem;
        padding: 4/37.5rem 0 4/37.5rem .32rem;
        color: #666;
        position: relative;
        .sui-icon-common__wrap {
            margin-right: 8/37.5rem;
        }
    }
    .header-more__dot {
        position: relative;
        &::after {
            content: '';
            position: absolute;
            width: 6/37.5rem;
            height: 6/37.5rem;
            border-radius: 50%;
            background: @sui_color_guide;
            top: 0;
            right: -.2rem;
        }
    }
}

</style>
