import schttp from 'public/src/services/schttp'
import { jumpToRobot } from '@customerService/botLogic'
import { isLogin } from 'public/src/pages/common/utils/index.js'

function hasOpen (entrance, position) {
  var status = false
  if (position && entrance && entrance.length) {
    status = entrance.some(item => {
      if (item.position == position && item.open == 1) {
        return true
      }
    })
  }
  return status
}

function hasRobotEntrance (position, cb) {
  // 判断入口是配置开启
  const { robotCustomerChannel } = gbCommonInfo
  var status = false
  if (robotCustomerChannel && robotCustomerChannel.entrance) {
    status = hasOpen(robotCustomerChannel.entrance, position)
    cb(status)
  } else {
    schttp({
      url: '/api/robot/channel/init/query',
      params: {
        notLang: 1
      }
    }).then(json => {
      if (json.getCustomerChannel && json.getCustomerChannel.code == 0 && json.getCustomerChannel.info) {
        const getCustomerChannel = json.getCustomerChannel.info
        gbCommonInfo.robotCustomerChannel = getCustomerChannel

        status = hasOpen(getCustomerChannel.entrance, position)
      }
      cb(status)
    })
  }
}

function gotoRobot (page) {
  if (!isLogin()) {
    SHEIN_LOGIN.show({
      show: true,
      bi: 'livechat',
      ga: 'livechat',
      toRobot: true,
      cb: () => {
        jumpToRobot(page)
      }
    })
  } else {
    jumpToRobot(page)
  }
}

export {
  hasRobotEntrance,
  gotoRobot
}
