export const homeRouter = {
  path: `/:langPath?/:id([A-Za-z_0-9?]+)?`,
  name: 'config_index',
  // component: () => import(/* webpackChunkName: "config_index" */ '../../config_index/components/app_v2.vue'),
  component: () => import(/* webpackChunkName: "config_index" */ '../../config_index/Home.vue'),
  meta: {
    keepAlive: true,
    pageType: 'index',
    monitorTag: 'index',
    isCssInVue: true,
  }
}
