const { langPath } = gbCommonInfo
const lookbookRoutes = [
  {
    path: `${langPath}/lookbook/:serial_nos`,
    component: () => import(/* webpackChunkName: "lookbook_detail" */ '../../lookbook_detail/container.vue'),
    name: 'page_lookbook',
    meta: {
      pageType: 'lookbook',
      scrollTop: true,
      isCssInVue: true
    }
  }
]

export {
  lookbookRoutes
}
