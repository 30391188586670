import { COUPON_OFF_TYPE } from './constants'

export function formatCouponItem(item) {
  if (!item) return
  const formatted = {}
  formatted['couponId'] = item.id || item.couponId || item.coupon || ''
  formatted['applyFor'] = item.applyFor || item.apply_for || ''
  formatted['startTime'] = formatDate(item.startTimestamp || item.start_date || item.startTime || item.start_time || item.startDate || '')
  formatted['endTime'] = formatDate(item.endTimestamp || item.end_date || item.endTime || item.end_time || item.endDate || '')
  formatted['couponCode'] = item.coupon || item.couponCode || item.couponcode || item.coupon_code || ''
  formatted['couponRule'] = formatRule(item.rule || item.coupon_rule || item?.other_coupon_rule || [])
  formatted['couponDimension'] = item.couponDimension || item.coupon_dimension || ''
  // 优惠券类型，1: 减元券，2/5:折扣券
  const discountType = String(item.rule_dimension || item.ruleDimension || item.coupon_type_id || item.couponTypeId || item.exportType || '')
  formatted['ruleDimension'] = discountType
  const firstCouponRule = formatted.couponRule[0] || {}
  formatted['discountPercent'] = [
    COUPON_OFF_TYPE.PercentOfOriginPrice,
    COUPON_OFF_TYPE.PercentOfPriceShouldPay,
  ].includes(discountType) ? firstCouponRule.value?.amount : 0
  formatted['discountAmount'] = discountType === COUPON_OFF_TYPE.Cash ? firstCouponRule.value?.amount || 0 : 0
  formatted['minOrderAmount'] = firstCouponRule.min?.amount || 0
  // 是否命中排除规则字段  默认为0 （表示不命中）
  formatted['filterRule'] = item.filterRule || item.filter_rule || 0
  // 劵真实类型
  formatted['realTypeId'] = item.real_type_id || item.realTypeId || ''
  formatted['rightsInfo'] = item.rightsInfo || ''
  formatted['categoryName'] = item.categoryName || item.category_name || []
  formatted['shippingDiscountType'] = item.shippingDiscountType ?? item.shipping_discount_type ?? ''
  formatted['typeId'] = item.typeId || item.type_id || ''
  formatted['selfStatus'] = item.selfStatus // 券自身状态 (0:可用状态 1:无效状态 2:停用状态 3:终止状态")
  formatted['timeStatus'] = item.timeStatus // 券时间状态 (0:有效状态 1:未开始状态 2:即将过期状态 3:已过期状态 4:新到账")
  return formatted
}

function formatDate (date) {
  return Number(date) == date
    ? date
    : (new Date(date.replace(/-/gi, '/')).getTime()) / 1000 // .replace(/-/gi, '/') 兼容safari转换
}

function formatRule(rules) {
  if (rules.length < 1) {
    return []
  }
  const itemRules = rules.map(_ => {
    return {
      value: {
        amount: _?.price?.price || _?.valueAmount?.amount || _?.value?.amount,
        amountWithSymbol: _?.price?.priceSymbol || _?.valueAmount?.amountWithSymbol || _?.value?.amountWithSymbol
      }, 
      min: {
        amount: _?.min_order?.price || _?.minAmount?.amount || _?.min?.amount,
        amountWithSymbol: _?.min_order?.priceSymbol || _?.minAmount?.amountWithSymbol || _?.min?.amountWithSymbol
      }, 
      max: {
        amount: _?.max_order?.price || _?.maxAmount?.amount || _?.max?.amount,
        amountWithSymbol: _?.max_order?.priceSymbol || _?.maxAmount?.amountWithSymbol || _?.max?.amountWithSymbol
      },
      coupon_gift_id: _?.coupon_gift_id || _?.couponGiftId || ''
    }
  })

  return itemRules
}
