import { throttle } from '@shein/common-function'

class ScroolEventListener {
  constructor(callback) {
    this.callback = callback
    this.eventListenerCallback = null
    this.timer = null
    this.scrolling = false
    this.init()
  }

  init() {
    if (typeof window === 'undefined') return
    if (this.eventListenerCallback) return

    const callback = throttle({
      func: () => {
        if (this.timer) this.clear()
        this.scrolling = true
        this.callback?.(true)
        this.timer = setTimeout(() => {
          this.scrolling = false
          this.callback?.(false)
        }, 600)
      },
      wait: 500,
      options: {
        trailing: false
      }
    })

    this.eventListenerCallback = callback

    window.addEventListener('scroll', callback)
  }

  clear() {
    clearTimeout(this.timer)
    this.timer = null
  }
}

export default ScroolEventListener
