
import { computed } from 'vue'
import { markPoint } from 'public/src/services/mark/index.js'
export default function useBack({ route }) {
  const showBack = computed(() => {
    const $route = route.value
    const isNewCampaignPage = $route.name === 'config_activity' && $route?.query?.from_tabbar_new === '1'
    const excludeList = ['config_index', 'page_category', 'wishlistGroupShare']
    // 专题 new 频道需要隐藏后退按钮
    return !excludeList.includes($route.name) && !isNewCampaignPage
  })

  const handleGoBack = () => {
    markPoint('toNextPageClick', 'public')
  }

  return {
    showBack,
    handleGoBack
  }
}
