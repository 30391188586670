import { deactivateAccountRoutes } from './deactivate-account'
import { getQueryString } from '@shein/common-function'
import { WISHLIST_USER_ROUTER } from './wishlist-router'
import dashboardServcie from 'public/src/pages/user/child_pages/dashboard/service'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import schttp from 'public/src/services/schttp'
import { markPoint } from 'public/src/services/mark/index.js'
import { getBffVersion } from 'public/src/pages/user/child_pages/user_bff_version/index.js'
import { prefetchResource } from 'public/src/services/prefetchResource/index.js'

const { language } = gbCommonInfo
const isFromExchange = getQueryString({ key: 'from' }) === 'exchange'

const routes = [
  {
    path: 'index',
    component: userIndexRouteComponent,
    name: 'UserIndex',
    beforeEnter: (to, from, next) => {
      markPoint('getPageData', 'public')
      if (!getBffVersion('userCenter_v1')) {
        to.meta.prefetch = dashboardServcie.useMainData({
          cache: false,
          isLogin: isLogin(),
        })
      }
      next()
    },
    meta: {
      isCssInVue: true,
      keepAlive: true,
      pageType: 'UserIndex',
      clearPosition: true,
      monitorTag: 'me',
    },
  },
  {
    path: 'blacklist_pwreset',
    name: 'blacklist_pwreset',
    component: () => import('../../user/child_pages/blacklist_pwreset/container.vue'),
    meta: {
      isCssInVue: true,
      navTitle: language.SHEIN_KEY_PWA_17711,
      needLogin: true,
    },
  },
  {
    path: 'setting',
    name: 'Setting',
    component: () => import('../../user/child_pages/setting_new/container.vue'),
    meta: {
      isCssInVue: true,
      navTitle: language.SHEIN_KEY_PWA_15667,
      monitorTag: 'user-setting',
    },
  },
  {
    path: 'setting/personalized',
    name: 'SettingPersonalized',
    component: () => import('../../user/child_pages/setting_new/pages/Personalized.vue'),
    meta: {
      isCssInVue: true,
      navTitle: language.SHEIN_KEY_PWA_33310,
    },
  },
  {
    path: 'setting/security',
    name: 'SettingSecurity',
    component: () => import('../../user/child_pages/setting_new/pages/security.vue'),
    meta: {
      navTitle: language.SHEIN_KEY_PWA_17201,
      needLogin: true,
      isCssInVue: true,
    },
  },
  {
    path: 'account_setting',
    name: 'Account_setting',
    component: () => import('../../user/child_pages/setting_new/pages/account.vue'),
    meta: {
      isCssInVue: true,
      navTitle: language.SHEIN_KEY_PWA_15366,
      needLogin: true,
    },
  },
  {
    path: 'body_shape',
    component: () => import('../../user/child_pages/setting_new/pages/bodyShape.vue'),
    meta: {
      navTitle: language.SHEIN_KEY_PWA_15259,
    },
  },
  {
    path: 'change_user_info',
    component: () => import('../../user/child_pages/setting_new/pages/changeUserInfo.vue'),
    meta: {
      isCssInVue: true,
      navTitle: language.SHEIN_KEY_PWA_15259,
      needLogin: true,
    },
  },
  {
    path: 'security/email_validate',
    name: 'SettingEmailValidate',
    component: () =>  {
      return getBffVersion('email_validate_v1')
        ? import('../../user/child_pages/setting_new/pages/emailValidate.vue')
        : import('../../user/child_pages/email_validate/index.vue')
    },
    meta: {
      isCssInVue: true,
      // needLogin: true,
    },
  },
  {
    path: 'dsr/export',
    name: 'DsrExport',
    component: () => import('../../user_dsr/index.vue'),
    meta: {
      isCssInVue: true,
      // needLogin: true,
    },
  },
  {
    path: 'orders/recycle',
    name: 'UserOrdersRecycle',
    component: () => import('../../user/child_pages/orders/recycle/container.vue'),
    meta: {
      isCssInVue: true,
      needLogin: true,
    },
  },
  {
    path: 'orders/findorder',
    name: 'findorder',
    component: () => import('../../user/child_pages/orders/find_order/container.vue'),
    meta: {
      isCssInVue: true,
      needLogin: true,
    },
  },
  // {
  //   path: 'orders/detail/:billno',
  //   name: 'UserOrdersDetail',
  //   component: () => import(/* webpackChunkName: "OrderDetail" */'../../user/child_pages/orders/detail/container.vue'),
  //   meta: {
  //     isCssInVue: true,
  //     needLogin: true,
  //     monitorTag: 'order-detail',
  //   }
  // },
  {
    path: 'orders/shipping/:billno',
    name: 'UserOrdersShipping',
    component: () => import('../../user/child_pages/orders/detail/order_shipping.vue'),
    meta: {
      isCssInVue: true,
      needLogin: true,
      navTitle: language.SHEIN_KEY_PWA_15081
    }
  },
  {
    path: 'orders/detailPaid',
    name: 'UserOrdersDetailPaid',
    component: () => import('../../user/child_pages/orders/detailPaid/container.vue'),
    meta: {
      needLogin: true,
      isCssInVue: true,
      monitorTag: 'order-detailPaid',
    },
  },
  {
    path: 'orders/selfExchange',
    name: 'UserOrdersSelfExchange',
    component: () => import('../../user/child_pages/orders/detail/selfExchange/container.vue'),
    meta: {
      isCssInVue: true,
      needLogin: true,
    },
  },
  {
    path: 'orders/customsInterception/:billno',
    name: 'CustomsInterception',
    component: () =>
      import('../../user/child_pages/orders/customs_interception_list/container.vue'),
    meta: {
      isCssInVue: true,
      needLogin: true,
      noNeedEmitPageUnload: true,
    },
  },
  // {
  //   path: 'orders/virtualDetail/:billno',
  //   name: 'UserOrdersVirtualDetail',
  //   component: () => import('../../user/child_pages/orders/virtualDetail/container.vue'),
  //   meta: {
  //     needLogin: true,
  //   },
  // },
  {
    path: 'orders/order_review',
    component: () => import('../../user/child_pages/orders/order_review/container.vue'),
    meta: {
      isCssInVue: true,
      needLogin: true,
    },
  },
  {
    path: 'orders/review_already',
    name: 'Review_already',
    component: () => import('../../user/child_pages/orders/review_already/container.vue'),
    meta: {
      isCssInVue: true,
      navTitle: language.SHEIN_KEY_PWA_16290,
      needLogin: true,
    },
  },
  {
    path: 'orders/refund/:billno',
    component: () => import('../../user/child_pages/orders/refund/container.vue'),
    meta: {
      isCssInVue: true,
      navTitle: language.SHEIN_KEY_PWA_15714,
      needLogin: true,
    },
  },
  {
    path: 'orders/track/:billno',
    name: 'UserOrdersTrack',
    component: () => import(/* webpackChunkName: "orders_track" */ '../../trackNew/container.vue'),
    meta: {
      isCssInVue: true,
      needLogin: true,
      monitorTag: 'track',
    },
    redirect: to => {
      return { name: 'ordersTrack', query: { billno: to.params?.billno, ...to.query } }
    }
  },
  {
    path: 'orders/combinedOrder/:billno',
    name: 'combinedOrder',
    component: () => import('../../user/child_pages/orders/order_combined/container.vue'),
    meta: {
      isCssInVue: true,
      navTitle: language.SHEIN_KEY_PWA_14596,
      needLogin: true,
    },
  },
  {
    path: 'support', //support
    name: 'Support',
    component: () => import('../../user/child_pages/supportCommon/support_index.vue'),
    meta: {
      navTitle: language.SHEIN_KEY_PWA_15039,
      isCssInVue: true,
    },
  },
  {
    path: 'support/invoice', //support/invoice
    name: 'SupportInvoice',
    component: () => import('../../user/child_pages/support_invoice/SupportInvoice.vue'),
    meta: {
      navTitle: language.SHEIN_KEY_PWA_28856,
      isCssInVue: true,
    },
  },
  {
    path: 'support/serviceProgress', //support
    name: 'SupportProgress',
    component: () => import('../../user/child_pages/support_service_progress/ServiceProgress.vue'),
    meta: {
      navTitle: language.SHEIN_KEY_PWA_33409,
      isCssInVue: true,
    },
  },
  {
    path: 'support/serviceProgress/detail', //support
    name: 'SupportProcessDetail',
    component: () => import('../../user/child_pages/support_progress_detail/ProgressDetail.vue'),
    meta: {
      navTitle: language.SHEIN_KEY_PWA_33412,
      isCssInVue: true,
    },
  },
  {
    path: 'payment',
    name: 'Payment',
    component: () => import('../../user/child_pages/payment/container.vue'),
    meta: {
      isCssInVue: true,
      navTitle: language.SHEIN_KEY_PWA_15304,
      needLogin: true,
    },
  },
  {
    path: 'wallet',
    name: 'UserWallet',
    component: () => import('../../user/child_pages/wallet/container.vue'),
    meta: {
      isCssInVue: true,
      navTitle: language.SHEIN_KEY_PWA_15109,
      needLogin: true,
    },
  },
  {
    path: 'gift_card',
    component: () => import('../../user/child_pages/gift_card/container.vue'),
    meta: {
      isCssInVue: true,
      navTitle: language.SHEIN_KEY_PWA_15846
    }
  },
  {
    path: 'rut',
    name: 'Rut',
    component: () => {
      return getBffVersion('setting_v3') 
        ? import('../../user/child_pages/setting_new/pages/rut.vue')
        : import('../../user/child_pages/account/components/rut.vue')
    },
    meta: {
      isCssInVue: true,
      navTitle: language.SHEIN_KEY_PWA_16727,
      needLogin: true,
    },
  },
  {
    path: 'preference',
    component: () => {
      return getBffVersion('setting_v2') 
        ? import('../../user/child_pages/setting_new/pages/preference.vue')
        : import('../../user/child_pages/account/components/preference.vue')
    },
    meta: {
      isCssInVue: true,
      navTitle: language.SHEIN_KEY_PWA_16050,
    },
  },
  {
    path: 'reference',
    name: 'UserReference',
    redirect() {
      location.href = `${gbCommonInfo.langPath}/404.html`
    },
    meta: {
      navTitle: language.SHEIN_KEY_PWA_16943,
    },
  },
  {
    path: 'marketing/reference',
    name: 'UserMarketingReference',
    component: () => import('../../user/child_pages/marketing/reference.vue'),
    meta: {
      needLogin: true,
      navTitle: language.SHEIN_KEY_PWA_16943,
      pageType: 'userMarketing',
      subPageType: 'userMarketing',
      isCssInVue: true,
    },
  },
  {
    path: 'marketing/ambassador',
    name: 'UserMaketingAmbassador',
    component: () => import('../../user/child_pages/marketing/ambassador.vue'),
    meta: {
      needLogin: true,
      navTitle: language.SHEIN_KEY_PWA_17637,
      pageType: 'userMarketing',
      subPageType: 'userMarketing',
      isCssInVue: true,
    },
  },
  {
    path: 'marketing/ambassador/commission/estimate', // type: estimate,settlement
    redirect: () => {
      location.replace(`${gbCommonInfo.langPath}/404.html`)
    },
  },
  {
    path: 'marketing/ambassador/commission/settlement', // type: estimate,settlement
    name: 'UserMaketingAmbassadorCommission',
    component: () => import('../../user/child_pages/marketing/commissionStatement.vue'),
    meta: {
      needLogin: true,
      navTitle: language.SHEIN_KEY_PWA_17637,
      pageType: 'userMarketing',
      subPageType: 'userMarketing',
      isCssInVue: true,
    },
  },
  {
    path: 'marketing/ambassador/commission/detail', // type: detail
    name: 'UserMaketingAmbassadorCommissionDetail',
    component: () => import('../../user/child_pages/marketing/commissionDetails.vue'),
    beforeEnter: async (to, from, next) => {
      if (!to.meta.navTitle) {
        // eslint-disable-next-line require-atomic-updates
        to.meta.navTitle = (await getAmbassadorLanguage()).SHEIN_KEY_PWA_26439
      }
      next()
    },
    meta: {
      needLogin: true,
      pageType: 'userMarketing',
      subPageType: 'userMarketing',
      isCssInVue: true,
    },
  },
  {
    path: 'coupon',
    name: 'Coupon',
    component: () => import('../../user/child_pages/coupon/container.vue'),
    meta: {
      navTitle: language.SHEIN_KEY_PWA_15797,
      needLogin: true,
      isCssInVue: true,
    },
  },
  {
    path: 'coupon/add-items',
    name: 'AddItems',
    component: () => import('../../user/child_pages/add_items/container.vue'),
    meta: {
      needLogin: true,
      isCssInVue: true,
    },
  },
  {
    path: 'points',
    name: 'Points',
    component: () => import('../../user/child_pages/points/container.vue'),
    meta: {
      navTitle: language.SHEIN_KEY_PWA_15421,
      needLogin: true,
      isCssInVue: true,
    },
  },
  {
    path: 'points/prev_points',
    name: 'PrevPoints',
    component: () => import('../../user/child_pages/points/prevPoints.vue'),
    meta: {
      isCssInVue: true,
      navTitle: language.SHEIN_KEY_PWA_17658,
      needLogin: true,
    },
  },
  {
    path: 'points/points_source',
    name: 'PointsSource',
    component: () => import('../../user/child_pages/points/pointsSource.vue'),
    meta: {
      isCssInVue: true,
      navTitle: language.SHEIN_KEY_PWA_18116,
      needLogin: true,
    },
  },
  {
    path: 'measurement',
    name: 'Measurement',
    component: () => {
      return getBffVersion('setting_v2')
        ? import('../../user/child_pages/setting_new/pages/measurement.vue')
        : import('../../user/child_pages/measurement/container.vue')
    },
    meta: {
      isCssInVue: true,
      navTitle: language.SHEIN_KEY_PWA_15798,
      needLogin: true,
    },
  },
  // security该路由地址是为了方便维护机器人业务配置的三端统一链接增加的
  {
    path: 'security',
    component: () => {
      return getBffVersion('setting_v2')
        ? import('../../user/child_pages/setting_new/pages/changePassword.vue')
        : import('../../user/child_pages/edit_password/container.vue')
    },
    meta: {
      isCssInVue: true,
      navTitle: language.SHEIN_KEY_PWA_15287,
      needLogin: true,
    },
  },
  {
    path: 'edit-password',
    component: () => {
      return getBffVersion('setting_v2')
        ? import('../../user/child_pages/setting_new/pages/changePassword.vue')
        : import('../../user/child_pages/edit_password/container.vue')
    },
    meta: {
      isCssInVue: true,
      navTitle: language.SHEIN_KEY_PWA_15287,
      needLogin: true,
    },
  },
  {
    path: 'service-phone',
    name: 'UserServicePhone',
    meta: {
      isCssInVue: true,
      navTitle: language.SHEIN_KEY_PWA_15759,
      needLogin: true,
    },
    component: async () => {
      // component加载速度比vue-router的跳转增加参数要快，导致页面跳转后参数丢失，所以这里改成直接跳转到bot页面
      return import(/* webpackChunkName: "robotNew" */ '../../common/robotNew/bot/BotContainer')
    }
  },
  {
    path: 'tickets',
    name: 'Tickets',
    component: () => import('../../user/child_pages/tickets/ticketsIndex.vue'),
    meta: {
      isCssInVue: true,
      navTitle: language.SHEIN_KEY_PWA_15377,
      needLogin: true,
    },
    children: [
      {
        path: '/',
        name: 'Tickets',
        component: () => import('../../user/child_pages/tickets/ticketListPage.vue'),
        meta: {
          isCssInVue: true,
          needLogin: true,
          navTitle: language.SHEIN_KEY_PWA_15377,
        },
      },
      {
        path: 'store',
        name: 'Tickets',
        component: () => import('../../user/child_pages/tickets/store/ticketStoreList.vue'),
        meta: {
          isCssInVue: true,
          needLogin: true,
          navTitle: language.SHEIN_KEY_PWA_15377,
        },
      },
      {
        path: 'detail/:id',
        name: 'TicketDetail',
        component: () => import('../../user/child_pages/tickets/detail/detailIndex.vue'),
        meta: {
          isCssInVue: true,
          navTitle: language.SHEIN_KEY_PWA_24246,
          needLogin: true,
        },
        alias: ['detail'],
      },
      {
        path: 'reply/:id',
        name: 'TicketDetailReply',
        component: () => import('../../user/child_pages/tickets/detail/detailReply.vue'),
        meta: {
          isCssInVue: true,
          navTitle: language.SHEIN_KEY_PWA_24251,
          needLogin: true,
        },
      },
    ],
  },
  {
    path: 'addressbook',
    name: 'AddressBook',
    component: () => import('../../user/child_pages/addressbook/container.vue'),
    meta: {
      isCssInVue: true,
      navTitle:
        isFromExchange && getQueryString({ key: 'addressId' }) ? '' : language.SHEIN_KEY_PWA_15670,
      needLogin: true,
    },
  },
  {
    path: 'orders/giftcardlist',
    component: () => import('../../user/child_pages/orders/giftcardlist/container.vue'),
    name: 'UserGiftcardOrdersList',
    meta: {
      isCssInVue: true,
      keepAlive: true,
      needLogin: true,
      navTitle: language.SHEIN_KEY_PWA_14873,
    },
  },
  {
    path: 'orders/giftcard_detail/:billno',
    component: () => import('../../user/child_pages/orders/giftcarddetail/container.vue'),
    name: 'UserGiftCardDetail',
    meta: {
      isCssInVue: true,
      needLogin: true,
    },
  },
  {
    path: 'student-verification', // 改路由已经下掉, 换新的/student-verification
    component: () => import('../../user/child_pages/student_verification/container.vue'),
    name: 'student-verification',
    meta: {
      isCssInVue: true,
      navTitle: 'Student Discount',
      keepAlive: true,
      needLogin: true,
    },
  },
  {
    path: 'student-verification/student_verification_iframe',
    component: () => import('../../user/child_pages/student_verification/container.vue'),
    redirect: { name: 'student-verification', isCssInVue: true },
  },
  {
    path: 'student/studentDiscount',
    component: () => import('../../user/child_pages/StudentDiscount/index.vue'),
    name: 'StudentDiscount',
    meta: {
      isCssInVue: true,
      navTitle: language.SHEIN_KEY_PWA_27336,
      needLogin: true
    }
  },
  {
    path: 'student/studentbeans',
    component: () => import('../../user/child_pages/StudentDiscount/StudentBeans.vue'),
    name: 'StudentBeans',
    meta: {
      isCssInVue: true,
      navTitle: 'StudentBeans',
      needLogin: true
    }
  },
  {
    path: 'my_qrCode',
    component: () => import('../../user/child_pages/qrcode/container.vue'),
    name: 'student-verification',
    meta: {
      needLogin: true,
      isCssInVue: true,
    },
  },
  {
    path: 'survey',
    component: () => import('../../user/child_pages/survey/container.vue'),
    meta: {
      navTitle: language.SHEIN_KEY_PWA_16269,
      needLogin: true,
      isCssInVue: true,
    },
  },
  {
    path: 'membership',
    component: () => import('../../user/child_pages/membership/container.vue'),
    meta: {
      isCssInVue: true,
      navTitle: language.SHEIN_KEY_PWA_18142,
      needLogin: true,
    },
  },
  {
    path: 'sheinvip',
    name: 'sheinvip',
    component: () => import('../../user/child_pages/shein_vip/container.vue'),
    meta: {
      needLogin: true,
      isCssInVue: true,
    },
  },
  {
    path: 'sheinvip_giftlist',
    name: 'sheinvip_giftlist',
    component: () => import('../../user/child_pages/shein_vip/container_giftlist.vue'),
    meta: {
      needLogin: true,
      isCssInVue: true,
    },
  },
  {
    path: 'sheinvip/sheinvip_record',
    name: 'sheinvip_record',
    component: () => import('../../user/child_pages/shein_vip/container_record.vue'),
    meta: {
      needLogin: true,
      isCssInVue: true,
    },
  },
  {
    path: 'sheinvip/sheinvip_address',
    name: 'sheinvip_address',
    component: () => import('../../user/child_pages/shein_vip/container_address.vue'),
    meta: {
      isCssInVue: true,
      needLogin: true,
    },
  },
  {
    path: 'romwevip',
    name: 'romwevip',
    component: () => import('../../user/child_pages/romwe_vip/container.vue'),
    meta: {
      isCssInVue: true,
      needLogin: true,
    },
  },
  {
    path: 'romwevip/romwevip_record',
    name: 'romwevip_record',
    component: () => import('../../user/child_pages/romwe_vip/container_record.vue'),
    meta: {
      isCssInVue: true,
      needLogin: true,
    },
  },
  {
    path: 'share_earn',
    name: 'shareEarn',
    component: () => import('../../user/child_pages/share_earn/container.vue'),
    meta: {
      isCssInVue: true,
      navTitle: language.SHEIN_KEY_PWA_16636,
      needLogin: true,
    },
  },
  {
    path: 'order_return/:billno',
    name: 'orderReturn',
    component: () => import('../../user/child_pages/order_return/returns/container.vue'),
    meta: {
      isCssInVue: true,
      needLogin: true,
    },
  },
  {
    path: 'order_return/order_return_label/:billno',
    name: 'orderReturnLabel',
    component: () => import('../../user/child_pages/order_return/labels/containerNew.vue'),
    meta: {
      needLogin: true,
    },
  },
  {
    path: 'order_return/return_label/:billno',
    name: 'orderReturnLabel',
    component: () => import('../../user/child_pages/order_return/labels/containerNew.vue'),
    meta: {
      needLogin: true,
    },
  },
  {
    path: 'order_return/return_refund_list/:billno',
    name: 'orderReturnRefundRecord',
    component: () => import('../../user/child_pages/order_return/records/container.vue'),
    meta: {
      isCssInVue: true,
      needLogin: true,
    },
  },
  {
    path: 'order_return/return_modify/:billno',
    name: 'page_edit_returnitems',
    component: () => import('../../user/child_pages/order_return/modify/container.vue'),
    meta: {
      isCssInVue: true,
      navTitle: language.SHEIN_KEY_PWA_15385,
      needLogin: true,
    },
  },
  {
    path: 'privacy_request',
    name: 'privacyRequest',
    component: () => import('../../user/child_pages/privacy_request/container.vue'),
    meta: {
      isCssInVue: true,
      navTitle: language.SHEIN_KEY_PWA_18542,
    },
  },
  {
    path: 'orders/state',
    name: 'page_order_state',
    component: () => import('public/src/pages/user/child_pages/orders/state/container.vue'),
    meta: {
      isCssInVue: true,
      needLogin: true,
    },
  },
  {
    path: 'notification',
    name: 'notification',
    component: () => import('../../user/child_pages/notification/container.vue'),
    meta: {
      // needLogin: true,
      navTitle: language.SHEIN_KEY_PWA_18913,
      isCssInVue: true,
    },
  },
  {
    path: 'remind',
    name: 'remind',
    component: () => import('../../user/child_pages/notification/remind.vue'),
    meta: {
      isCssInVue: true,
      needLogin: true,
      navTitle: language.SHEIN_KEY_PWA_18591,
    },
  },
  // 订单发票详情
  {
    path: 'order_invoices/:billno',
    component: () => import('../../user/child_pages/order_invoices/container.vue'),
    name: 'page_invoice_center',
    meta: {
      isCssInVue: true,
      // navTitle: language.SHEIN_KEY_PWA_15714,
      needLogin: true,
    },
  },
  {
    path: 'phone/change',
    name: 'userPhoneChange',
    component: () => import('../../user/child_pages/phone_manage/phone_change.vue'),
    meta: { isCssInVue: true, needLogin: true },
  },
  {
    path: 'phone/bind',
    name: 'userPhoneBind',
    component: () => import('../../user/child_pages/phone_manage/phone_bind.vue'),
    meta: { isCssInVue: true, needLogin: true },
  },
  {
    path: 'orders/rejectReason/:billno',
    name: 'rejectReason',
    component: () => import('../../user/child_pages/order_reject_reason/container.vue'),
    meta: {
      isCssInVue: true,
      navTitle: language.SHEIN_KEY_PWA_18862,
      needLogin: true,
    },
  },
  {
    path: 'review_center',
    name: 'UserReviewCenter',
    component: () => import('../../user/child_pages/review_center/container.vue'),
    meta: {
      isCssInVue: true,
      needLogin: true,
    },
  },
  {
    path: 'verification',
    name: 'verification',
    component: () => import('../../user/child_pages/verification/container.vue'),
    meta: {
      isCssInVue: true,
      navTitle: language.SHEIN_KEY_PWA_19352,
    },
  },
  {
    path: 'friendship_coupon_earned_points',
    name: 'FriendshipCouponEarnedPoints',
    component: () => import('../../friendship_coupon/earnedPoints.vue'),
    meta: {
      isCssInVue: true,
      needLogin: true,
    },
  },
  {
    path: 'token/bind',
    name: 'page_user_token_bind',
    component: () =>
      import(
        /* webpackChunkName: "token-bind" */ '../../user/child_pages/token_bind/container.vue'
      ),
    meta: {
      isCssInVue: true,
      navTitle: 'SHEIN',
      isHideBack: true,
      needLogin: true,
    },
  },
  {
    path: 'prime',
    name: 'Prime',
    component: () => import('../../user/child_pages/prime/middleContainer.vue'),
    meta: {
      isCssInVue: true,
      needLogin: true,
    },
  },
  {
    path: 'prime/preview',
    name: 'PrimePreview',
    component: () => import('../../user/child_pages/prime_v2/containerPreview.vue'),
    meta: {
      isCssInVue: true,
      // needLogin: true,
    },
  },
  {
    path: 'prime/credit_record',
    name: 'PrimeCreditRecord',
    component: () => import('../../user/child_pages/prime_credit_record/container.vue'),
    meta: {
      isCssInVue: true,
      needLogin: true,
    },
  },
  {
    path: 'prime/record',
    name: 'PrimeRecord',
    component: () => import('../../user/child_pages/prime/primeRecord.vue'),
    meta: {
      isCssInVue: true,
      needLogin: true,
      navTitle: language.SHEIN_KEY_PWA_21777,
    },
  },
  {
    path: 'prime/saving',
    name: 'PrimeSaving',
    component: () => import('../../user/child_pages/prime/primeSaving.vue'),
    meta: {
      isCssInVue: true,
      needLogin: true,
    },
  },
  {
    path: 'tickets/complain',
    name: 'complain',
    component: () => import('../../user/child_pages/complaint/container.vue'),
    meta: {
      isCssInVue: true,
      needLogin: true,
      navTitle: language.SHEIN_KEY_PWA_25003,
    },
  },
  {
    path: 'primefreetrial',
    name: 'primeFreeTrial',
    component: () => import('../../user/child_pages/prime_free_trial/container.vue'),
    meta: {
      isCssInVue: true,
      needLogin: true,
    },
  },
  {
    path: 'support_video',
    name: 'Support_video',
    component: () => import('../../user/child_pages/support_video/support-video.vue'),
    meta: {
      isCssInVue: true,
      navTitle: language.SHEIN_KEY_PWA_24900,
    },
  },
  {
    path: 'sheinsaver',
    name: 'sheinsaver',
    component: () => import('../../user/child_pages/saver/container.vue'),
    meta: {
      needLogin: true,
    },
  },
  ...WISHLIST_USER_ROUTER,
  ...deactivateAccountRoutes,
]

function transfromRoutes(routes) {
  return routes.map(route => {
    let { path, component, children = [], name = '', meta = {}, ...props } = route
    if (children && children.length) {
      children = transfromRoutes(children, path)
    }
    // 根据路径转化为驼峰
    // path = parentPath + path
    name = name || path.replace(/(\/|-)(\w)/ig, (m, p1, p2) => p2.toUpperCase()).replace('/', '')

    // if(typeof meta.pageType === 'undefined') meta.pageType = 'userPage'
    meta.pageType = 'userPage'
    if (typeof meta.notNeedCommonHeader === 'undefined') meta.notNeedCommonHeader = true

    return {
      // path: langPath + path,
      path: path,
      name,
      component,
      meta,
      children,
      ...props
    }
  })
}
const userRoutes = transfromRoutes(routes)

async function getAmbassadorLanguage() {
  const res = await schttp({
    url: `/api/common/language/get`,
    params: {
      page: 'ambassador',
    },
  })
  return res?.language
}

function userIndexRouteComponent() {
  return !getBffVersion('userCenter_v1') ? 
    prefetchResource.importAsyncComponent({
      chunkName: 'user-index', // 跟webpackChunkName保持一致
      componentFactory: () => import(/* webpackChunkName: "user-index" */ '../../user/child_pages/dashboard/container_new.vue')
    })() : 
    prefetchResource.importAsyncComponent({
      chunkName: 'user-index', // 跟webpackChunkName保持一致
      componentFactory: () => import(/* webpackChunkName: "user-index" */ 'public/src/pages/user_index/container.vue')
    })()
}

export {
  userRoutes,
  userIndexRouteComponent,
}
