<template>
  <transition
    :name="transitionName"
    @after-enter="afterEnter"
  >
    <template v-if="show">
      <span
        v-if="current === 'freeshipping' && category === 'activity'"
        key="free"
        class="footer-cart-tips free"
        @click.stop="onClick"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="14"
          viewBox="0 0 16 14"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.5564 2.18968V0H0.757812V3.4838V7.72003V10.8386H2.07074C2.42981 9.58132 3.58742 8.66089 4.96001 8.66089C6.33259 8.66089 7.4902 9.58132 7.84928 10.8386H8.7127C9.07178 9.58132 10.2294 8.66089 11.602 8.66089C12.9744 8.66089 14.132 9.58117 14.4912 10.8383H15.4271V3.45511L11.5564 2.18968ZM11.5564 6.33808V3.76778L13.9268 4.54258V6.33808H11.5564Z"
            fill="white"
          />
          <circle
            cx="11.6375"
            cy="11.665"
            r="1.5"
            fill="white"
          />
          <circle
            cx="4.96045"
            cy="11.665"
            r="1.5"
            fill="white"
          />
        </svg>
        <span
          class="footer-cart-tips__text"
          v-html="data"
        ></span>
      </span>
      <span
        v-if="current === 'freeshipping' && category === 'coupon'"
        key="free-coupon"
        class="footer-cart-tips free-coupon"
        @click.stop="onClick"
      >
        <span class="mask-box">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="14"
            viewBox="0 0 16 14"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.5564 2.18968V0H0.757812V3.4838V7.72003V10.8386H2.07074C2.42981 9.58132 3.58742 8.66089 4.96001 8.66089C6.33259 8.66089 7.4902 9.58132 7.84928 10.8386H8.7127C9.07178 9.58132 10.2294 8.66089 11.602 8.66089C12.9744 8.66089 14.132 9.58117 14.4912 10.8383H15.4271V3.45511L11.5564 2.18968ZM11.5564 6.33808V3.76778L13.9268 4.54258V6.33808H11.5564Z"
              fill="white"
            />
            <circle
              cx="11.6375"
              cy="11.665"
              r="1.5"
              fill="white"
            />
            <circle
              cx="4.96045"
              cy="11.665"
              r="1.5"
              fill="white"
            />
          </svg>
          <span class="line">&nbsp;</span>
          <span
            class="footer-cart-tips__text"
            v-html="data"
          ></span>
        </span>
      </span>
      <span
        v-else-if="current === 'save'"
        key="save"
        class="footer-cart-tips save"
        @click.stop="onClick"
      >
        <span
          class="footer-cart-tips__text"
          v-html="data"
        ></span>
      </span>
      <span
        v-else-if="current === 'gift'"
        key="gift"
        class="footer-cart-tips gift"
        @click.stop="onClick"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.7409 4.25046L12.5055 2.64027L11.4944 1.53223L9.00739 3.80161L6.59929 1.5396L5.57231 2.6329L7.29434 4.25046H1.75V8.75046H2.75V16.2505H15.25V8.75046H16.25V4.25046H10.7409ZM13.75 8.75046H4.25V14.7505H13.75V8.75046ZM14.75 7.25046V5.75046H3.25V7.25046H14.75Z"
            fill="white"
          />
        </svg>
        <span
          class="footer-cart-tips__text"
          v-html="data"
        ></span>
      </span>
    </template>
  </transition>
</template>

<script>
import { daEventCenter } from '@/public/src/services/eventCenter'

export default {
  name: 'AppFooterCartTips',
  data() {
    return {
      show: false,
      transitionName: '',
      current: '',
      category: '',
      data: null,
      callback: null,
    }
  },
  methods: {
    open(step, config = {}) {
      this.callback = config.callback

      this.current = step.type
      this.category = step.category
      this.data = step.tips
      this.transitionName = 'footer-cart-tips-jump'
      this.show = true

      window?._gb_cart_tag_tips_?.getExposeData().then(extraData => {
        daEventCenter.triggerNotice({
          daId: '2-15-2',
          extraData,
        })
      })
    },
    // 动画执行结束
    afterEnter() {
      this.transitionName = ''
      this.show = false

      if (typeof this.callback === 'function') {
        this.callback()
        this.callback = null
      }
    },
    // 打断动画
    clear() {
      this.afterEnter()
    },
    onClick() {
      window?._gb_cart_tag_tips_?.getExposeData().then(extraData => {
        daEventCenter.triggerNotice({
          daId: '2-15-3',
          extraData,
        })
      })
      window._gb_app_.$router.push({
        name: 'cart',
      })
    }
  }
}
</script>

<style lang="less">
@space: 6px;
@jump: 10px;

.index-footer {
  @keyframes footer-cart-tips-jump {
    0% {
      opacity: 0;
    }
    12% {
      opacity: 0;
      transform: translateX(-50%) translateY(calc(-100% - @space - @jump));
    }
    31.75% {
      opacity: 1;
      transform: translateX(-50%) translateY(calc(-100% - @space));
    }
    51.5% {
      transform: translateX(-50%) translateY(calc(-100% - @space - @jump));
    }
    71.25% {
      transform: translateX(-50%) translateY(calc(-100% - @space));
    }
    91% {
      opacity: 1;
      transform: translateX(-50%) translateY(calc(-100% - @space - @jump));
    }
    100% {
      opacity: 0;
      transform: translateX(-50%) translateY(calc(-100%));
    }
  }

  .footer-cart-tips-jump-enter-active { /* stylelint-disable-line */
    animation: footer-cart-tips-jump 2.2s;
  }

  .footer-cart-tips {
    position: absolute;
    top: 0;
    opacity: 0;
    transform: translateX(-50%) translateY(calc(-100% - @space - @jump));
    display: inline-flex;
    align-items: center;
    padding: 16/75rem;
    width: 352/75rem;
    color: #fff;
    border-radius: 2px;

    &__text {
      font-size: 12px;
      line-height: 14px;
      text-align: left;
    }

    &.save, &.gift {
      background: #fa6338;

      &::before {
        background: #fa6338;
      }
    }

    &.free {
      background: #1b8157;

      &::before {
        background: #1b8157;
      }
    }

    &.free-coupon {
      padding: 0;
      width: 352/75rem;

      &::before {
        background: #1b8157;
      }

      .mask-box { /* stylelint-disable-line */
        display: inline-flex;
        align-items: center;
        padding: 16/75rem 12/75rem 16/75rem 16/75rem;
        background: #1b8157;
        background-image: url(/pwa_dist/images/cart-tag-tips/coupon_discount-e1ba3f7406.png);
        background-repeat: no-repeat;
        background-position: right;
        mask-image: radial-gradient(circle at 8/75rem 8/75rem,transparent 8/75rem,#fff 8/75rem);
        mask-position: 55/75rem -8/75rem;
      }

      svg {
        margin: 0 2px;
        width: 14px;
        height: 14px;
      }

      .line { /* stylelint-disable-line */
        display: inline-block;
        margin-left: 16/75rem;
        margin-right: 12/75rem;
        width: 0;
        line-height: 24px;
        border-left: 1px dashed rgba(#fff, .3);
      }

      .footer-cart-tips__text { /* stylelint-disable-line */
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -4px;
      margin-bottom: calc(-@space / 2);
      width: 8px;
      height: 8px;
      transform: rotateZ(45deg);
    }

    svg {
      flex-shrink: 0;
      margin-right: 12/75rem;
      width: 36/75rem;
      height: 36/75rem;
    }
  }
}
</style>
