const { langPath } = gbCommonInfo
const freeTrialRoutes = [
  {
    path: '/free-trial-center/index',
    name: 'home',
    meta: {
      isCssInVue: true,
      code: 'home',
      topHeaderShowRight: true,
      titleLanguageKey: 'SHEIN_KEY_PWA_16268',
      keepAlive: true,
      toSavePosition: ['next-notice', 'over', 'report', 'report-detail', 'address-select', 'my-center', 'page_goods_detail'],
      notNeedCommonHeader: true,
      pageType: 'freeTrial',
      monitorTag: 'free-trial-center'
    },
    component: () => import(/* webpackChunkName: "free-trial-index" */ '../../free_trial_center/child-page/home.vue')
  },
  {
    path: '/free-trial-center/next-notice',
    name: 'next-notice',
    meta: {
      isCssInVue: true,
      code: 'next-notice',
      topHeaderShowRight: false,
      titleLanguageKey: 'SHEIN_KEY_PWA_16247',
      keepAlive: true,
      notNeedCommonHeader: true,
      toSavePosition: ['page_goods_detail']
    },
    component: () => import(/* webpackChunkName: "free-trial-next-notice" */ '../../free_trial_center/child-page/next-notice.vue')
  },
  {
    path: '/free-trial-center/over',
    name: 'over',
    meta: {
      isCssInVue: true,
      code: 'over',
      topHeaderShowRight: false,
      titleLanguageKey: 'SHEIN_KEY_PWA_15056',
      keepAlive: true,
      notNeedCommonHeader: true,
      toSavePosition: ['page_goods_detail']
    },
    component: () => import(/* webpackChunkName: "free-trial-over" */ '../../free_trial_center/child-page/over.vue')
  },
  {
    path: '/free-trial-center/report',
    name: 'report',
    meta: {
      isCssInVue: true,
      code: 'report',
      topHeaderShowRight: false,
      titleLanguageKey: 'SHEIN_KEY_PWA_15207',
      keepAlive: true,
      toSavePosition: ['report-detail', 'page_goods_detail'],
      notNeedCommonHeader: true
    },
    component: () => import(/* webpackChunkName: "free-trial-report" */ '../../free_trial_center/child-page/report.vue')
  },
  {
    path: '/free-trial-center/report-detail/:report_id',
    name: 'report-detail',
    meta: {
      isCssInVue: true,
      code: 'report-detail',
      topHeaderShowRight: false,
      titleLanguageKey: 'SHEIN_KEY_PWA_15207',
      keepAlive: false,
      notNeedCommonHeader: true
    },
    component: () => import(/* webpackChunkName: "free-trial-report-detail" */ '../../free_trial_center/child-page/report-detail.vue')
  },
  {
    path: '/free-trial-center/address-select',
    name: 'address-select',
    meta: {
      isCssInVue: true,
      code: 'address-select',
      topHeaderShowRight: false,
      titleLanguageKey: 'SHEIN_KEY_PWA_15670',
      keepAlive: true,
      notNeedCommonHeader: true
    },
    component: () => import(/* webpackChunkName: "free-trial-address-select" */ '../../free_trial_center/child-page/address-select.vue')
  },
  {
    path: '/free-trial-center/my-center',
    name: 'my-center',
    meta: {
      isCssInVue: true,
      code: 'my-center',
      topHeaderShowRight: false,
      titleLanguageKey: 'SHEIN_KEY_PWA_16225',
      keepAlive: false,
      notNeedCommonHeader: true
      // toSavePosition: ['post-report']
    },
    component: () => import(/* webpackChunkName: "free-trial-my-center" */ '../../free_trial_center/child-page/my-center.vue')
  },
  {
    path: '/free-trial-center/post-report',
    name: 'post-report',
    meta: {
      isCssInVue: true,
      code: 'post-report',
      topHeaderShowRight: false,
      titleLanguageKey: 'SHEIN_KEY_PWA_16228',
      keepAlive: false,
      notNeedCommonHeader: true
    },
    component: () => import(/* webpackChunkName: "free-trial-post-report" */ '../../free_trial_center/child-page/post-report.vue')
  }
]

// 站点设置
freeTrialRoutes.forEach((router) => {
  router.path = `${langPath}${router.path}`
  router.meta.pageType = 'freeTrial'
})

export {
  freeTrialRoutes
}
