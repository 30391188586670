// Description: 客服机器人DNS预加载

export function prefetchSdk() {
  const { IS_RW, PUBLIC_CDN, IS_US_INDEPENDENT, SITE_EUR_INDEPENDENT_SWITCH, IS_EUR_DATA_CENTER } = gbCommonInfo
  const preload = [`${PUBLIC_CDN}/pwa_dist/libs/zendesk_sdk/zendesk_sdk.1.11.2.js`]
  const dnsPrefetch = IS_RW ? {
    'us': {
      api: '//im-api-chat-us.romwe.com',
      gateway: '//im-gateway-chat-us.romwe.com'
    },
    'eur': {
      api: '//im-api-chat-eur.romwe.com',
      gateway: '//im-gateway-chat-eur.romwe.com'
    },
    'central': {
      api: '//im-api-chat-central.romwe.com',
      gateway: '//im-gateway-chat-central.romwe.com'
    }
  } : {
    'us': {
      api: '//im-api-chat-us.shein.com',
      gateway: '//im-gateway-chat-us.shein.com'
    },
    'eur': {
      api: '//im-api-chat-eur.shein.com',
      gateway: '//im-gateway-chat-eur.shein.com'
    },
    'central': {
      api: '//im-api-chat-central.shein.com',
      gateway: '//im-gateway-chat-central.shein.com'
    }
  }
  let site = 'central'

  if (IS_US_INDEPENDENT) site = 'us'
  if (SITE_EUR_INDEPENDENT_SWITCH == 1 && IS_EUR_DATA_CENTER) site = 'eur'

  const entries = [
    preload[0],
    dnsPrefetch[site].api,
    dnsPrefetch[site].gateway
  ]

  const fragment = document.createDocumentFragment()
  const links = []
  for (const entry of entries) {
    const link = document.createElement('link')
    if (entry === preload[0]) {
      link.rel = 'preload'
      link.as = 'script'
    } else { 
      link.rel = 'dns-prefetch'
    }
    
    link.href = entry
    links.push(link)
    fragment.appendChild(link)
  }
  document.head.appendChild(fragment)

  setTimeout(() => {
    for (const link of links) {
      document.head.removeChild(link)
    }
  }, 2000)
}
