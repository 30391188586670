var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"bs-search-box"},[(_vm.searchBoxUI.component === 'DefaultSearchBox')?_c(_setup.DefaultSearchBox,{ref:"defaultSearchBoxRef",staticClass:"default-search-box__wrapper",style:(_vm.searchBoxUI.variableStyle),attrs:{"showCameraIcon":_vm.searchBoxUI.showCameraIcon,"boxType":_vm.searchBoxUI.boxType,"searchButtonType":_vm.searchBoxUI.boxType,"showSearchIcon":_vm.searchBoxUI.showSearchIcon,"showSearchButton":_vm.searchBoxUI.showSearchButton,"fetchSearchTermsParams":_vm.searchBoxUI.fetchSearchTermsParams,"autoFetch":_vm.searchBoxUI.autoFetch,"fixedFirstTerm":_vm.searchBoxUI.fixedFirstTerm,"slideItems":_vm.searchBoxUI.slideItems,"emptySearchTermsText":_vm.searchBoxUI.emptySearchTermsText,"showTermIcon":_vm.searchBoxUI.showTermIcon,"hotTermIndex":_vm.searchBoxUI.hotTermIndex},on:{"searchTermClick":_setup.handleSearchTermClick,"cameraIconClick":_setup.handleCameraIconClick,"searchButtonClick":_setup.handleSearchButtonClick,"searchTermTransitionEnd":_setup.handleSearchTermTransitionEnd,"resolveSearchTerms":_setup.resolveSearchTerms},scopedSlots:_vm._u([(_vm.searchBoxUI.isCameraUploadImg)?{key:"cameraIcon",fn:function(){return [_c('UploadImg',{staticStyle:{"display":"flex","align-items":"center"},attrs:{"uploadConfig":{
          fileSize: 300,
          fileType: 'image/jpeg',
          fileMaxWidth: 541,
          fileMaxHeight: 928
        },"on-success":_setup.handleUploadSuccess}},[_c('i',{staticClass:"sh_m_sui_icon_search_camara_24px"})])]},proxy:true}:null],null,true)}):_vm._e(),_vm._v(" "),(_vm.searchBoxUI.component === 'ActiveSearchBox')?_c(_setup.ActiveSearchBox,{style:(_vm.searchBoxUI.variableStyle),attrs:{"value":_vm.searchSelectKeyWord,"boxType":_vm.searchBoxUI.boxType,"showCameraIcon":_vm.searchBoxUI.showCameraIcon,"isTagMode":_vm.searchBoxUI.isTagMode,"tagList":_vm.searchBoxUI.tagList,"showSearchButton":_vm.searchBoxUI.showSearchButton,"searchButtonType":_vm.searchBoxUI.searchButtonType,"showClearIcon":_vm.searchBoxUI.showClearIcon},on:{"tagClick":_setup.handleTextClick,"textClick":_setup.handleTextClick,"cameraIconClick":_setup.handleCameraIconClick,"clearClick":_setup.handleClearClick,"closeTagClick":_setup.handleCloseTagClick,"searchButtonClick":_setup.handleSearchButtonClick},scopedSlots:_vm._u([(_vm.searchBoxUI.isCameraUploadImg)?{key:"cameraIcon",fn:function(){return [_c('UploadImg',{staticStyle:{"display":"flex","align-items":"center"},attrs:{"uploadConfig":{
          fileSize: 300,
          fileType: 'image/jpeg',
          fileMaxWidth: 541,
          fileMaxHeight: 928
        },"on-success":_setup.handleUploadSuccess}},[_c('i',{staticClass:"sh_m_sui_icon_search_camara_24px"})])]},proxy:true}:null],null,true)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }