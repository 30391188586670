import { template } from '@shein/common-function'

export const mapStickyServiceBeltLabelInfo = (servicePolicyLabels, language) => {
  return servicePolicyLabels.map(label => {
    switch(label.type) {
      case 'LocalShip':
        return {
          iconClass: 'localship',
          titleHTML: template(`<span class="highlight-text">${language.SHEIN_KEY_PWA_29496}</span>`, language.SHEIN_KEY_PWA_31667),
        }
      case 'QuickShip':
        return {
          iconClass: 'quickship',
          titleHTML: template(`<span class="highlight-text">${language.SHEIN_KEY_PWA_29497}</span>`, language.SHEIN_KEY_PWA_31669),
        }
      case 'FreeShipping':
        return {
          iconClass: 'freeshipping',
          titleHTML: template(`<span class="highlight-text">${language.SHEIN_KEY_PWA_29495}</span>`, language.SHEIN_KEY_PWA_31670),
        }
      case '100Authentic':
        return {
          iconClass: 'authentic',
          titleHTML: template(`<span class="highlight-text">${language.SHEIN_KEY_PWA_29498}</span>`, language.SHEIN_KEY_PWA_31668),
        }
      case 'PriceProtect':
        return {
          iconClass: 'price-protect',
          titleHTML: template(`<span class="highlight-text">${language.SHEIN_KEY_PWA_30142}</span>`, language.SHEIN_KEY_PWA_31671),
        }
      case 'Refundpolicy':
        return {
          iconClass: 'refundpolicy',
          titleHTML: template(`<span class="highlight-text">${language.SHEIN_KEY_PWA_33363}</span>`, language.SHEIN_KEY_PWA_33364),
        }
    }
  }).filter(Boolean)
}
