import schttp from 'public/src/services/schttp'
import getCccRecommendConfig from 'public/src/pages/common/getCccRecommendConfig'
import { abtservice } from 'public/src/services/abt'

/**
 * 获取 Dashboard 聚合接口信息
 */
export async function fetchDashboardBaseData({ fetchQueue = [], params = {} } = {}, ) {
  return schttp({
    url: '/api/user/dashboard/base/get',
    method: 'POST',
    data: {
      ...params,
      fetchQueue,
      clientTime: Date.now(),
    }
  })
}

/**
 * 获取底部ccc推荐组件数据
 */
export async function fetchFooterRcmdListCccConfig() {
  try {
    // eslint-disable-next-line @shein-aidc/abt/abt
    const abtData = await abtservice.getUserAbtResult({ newPosKeys: 'pwameRecommend' })
    if (abtData?.pwameRecommend?.p?.pwameRecommend !== 'On') return
    const [context, language] = await getCccRecommendConfig(
      'personalCenterPage',
      'personalCenterPage', {
        contextParams: {
          goods_ids: '',
          cate_ids: '',
        }
      })
    if (!context?.content?.length) return
    const products = getRcmdProductsFromCccContext(context)
    if (!products.length) return
    return {
      context,
      language,
    }
  } catch(e) {
    console.log('error', e.message)
    return
  }
}

export const getRcmdProductsFromCccContext = context => context?.content?.[0]?.composeRecommendFlow?.list?.info?.products || []

/**
 * 获取用户信息
 */
export function fetchAccInfo() {
  return schttp({
    url: '/api/user/account_setting/getAccInfo/get',
  }).then(result => {
    if(result?.code == 300206) {
      gbCommonInfo.user = {
        email: ''
      }
    }
    return result
  })
}
