import { computed } from 'vue'
import { getQueryString } from '@shein/common-function'
import { getStoreCodeFromUrl } from 'public/src/pages/store_pages/js/utils.js'

const checkIsPageStore = (pageName) => {
  const storePageNames = ['page_store_items', 'page_store_brands', 'page_store_category', 'page_store_home', 'page_store', 'page_store_pre_search']
  const searchPageType = getQueryString({ key: 'pageType' })
  const isStoreSearch = searchPageType === 'store'
  const isPageStore = (storePageNames.includes(pageName) || getQueryString({ key: 'search_type' }) === 'store' || isStoreSearch) && getStoreCodeFromUrl()
  return !!isPageStore
}

export default function useIs({ route }) {
  const isStorePage = computed(() => {
    return route.value.name === 'page_store'
  })
  const isStoreSearchPage = computed(() => {
    return route.value.path?.includes('/pdsearch') && route.value.query?.search_type === 'store'
  })
  const isStorePageOrSearchPage = computed(() => {
    return checkIsPageStore(route.value.name) || isStoreSearchPage.value
  })
  return {
    isStorePage,
    isStorePageOrSearchPage,
    isStoreSearchPage,
  }
}
