import { ref, computed, onMounted } from 'vue'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
// 店铺结果页老样式 没有...,有❤️
// 店铺选品页老样式 ... 和❤️都有
// 二者新样式 ...和❤️都没有
export default function useSave({ pageScene }) {
  const searchStoreNewStyle = ref(false)
  const wishListEntry = ref(null)
  const showSave = computed(() =>{
    if (['storeSearchResult', 'storeSelectionList'].includes(pageScene.value)) {
      return !searchStoreNewStyle.value
    }
    return !['goodsDetail', 'storePage'].includes(
      pageScene.value
    )
  })

  onMounted(async () => {
    const { storelistsearch } = await getUserAbtData()
    searchStoreNewStyle.value = storelistsearch.p?.storelistsearch === 'B'
    showSave.value && wishListEntry.value?.exposeReport()
    appEventCenter.$on('pageOnload', () => {
      wishListEntry.value?.exposeReport()
    })
  })
  return {
    showSave,
    wishListEntry
  }
}
