<template>
  <div>
    <Transition
      name="storeIconEntry"
      mode="out-in"
    >
      <div
        v-if="showFollowHandleBtn"
        key="storeFollowIcon"
        v-expose="{
          id: '2-22-20',
          data: { item: analysisData }
        }"
        v-tap="{
          id: '2-22-21',
          data: { item: analysisData }
        }"
        class="store-header-follow"
        :class="followBtnStart ? 'store-header-follow__btn_mini' : ''"
        @click="handleClickFollow"
      >
        <BaseImg
          :first-screen="true"
          :is-support-webp="true"
          :img-design-width="60"
          :img-src="storeData.logo"
          class="store-header-follow__logo"
        />
        <div class="store-header-follow__btn">
          <TransitionGroup
            name="storeIconHandle"
            mode="out-in"
            @after-enter="onAfterEnter"
            @after-leave="onAfterLeave"
          >
            <Icon
              v-show="followBtnStart && followBtnEnd"
              key="followed"
              name="sui_icon_selected_12px"
              size="0.32rem"
              color="#fff"
              class="store-header-follow__btn-followed"
            />
            <Icon
              v-show="!followBtnStart"
              key="follow"
              name="sui_icon_add_12px"
              size="0.32rem"
              color="#fff"
              class="store-header-follow__btn-add"
            />
          </TransitionGroup>
        </div>
      </div>
      <StoreFollowEntry v-else />
    </Transition>
  </div>
</template>
  
<script>
export default {
  name: 'StoreFollowIcon'
}
</script>
<script setup>
/**
   * 店铺关注交互图标
   * @description
   *  1. 未关注时展示
   *  2. 监听关注状态变化（未关注 -> 已关注）
   *  3. 点击关注按钮，触发关注
   */
import { onMounted, onUnmounted, ref } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg'
import StoreFollowEntry from './StoreFollowEntry.vue'
import { useStorePageData } from '../../hooks/useStoreHeader.js'


const { storeCode, isFollowed, storeData } = useStorePageData()
  
const showFollowHandleBtn = ref(!isFollowed.value)
const followBtnStart = ref(false) // 关注按钮【+】开始动画
const followBtnEnd = ref(false) // 关注按钮【+】结束动画，开始&结束均完成
const analysisData = {
  storeCode: storeCode.value,
  followsStatus: 0,
  scene: 3
}

const onFollowStateChange = ({ followState, storeCode }) => {
  if (followState) {
    followBtnStart.value = true
  } else {
    // 未关注则恢复初始状态
    initFollowStatus()
  }
}
  
onMounted(() => {
  // 更新关注状态
  appEventCenter?.$on?.(
    'store_pages:storeFollowStateChange',
    onFollowStateChange
  )
})
  
onUnmounted(() => {
  appEventCenter?.$off?.('store_pages:storeFollowStateChange', onFollowStateChange)
})
  
const initFollowStatus = () => {
  showFollowHandleBtn.value = true
  followBtnStart.value = false
  followBtnEnd.value = false
}
  
const handleClickFollow = () => {
  appEventCenter.$emit('FlowStoreFolloingClickFollow', {
    storeCode: storeCode.value
  })
}
  
// 【+】过渡动画离开完成后执行
const onAfterLeave = () => {
  followBtnEnd.value = true
}
// 【√】过渡动画进入完成后执行（【+】默认展示则无进入过渡动画）
const onAfterEnter = () => {
  setTimeout(() => {
    showFollowHandleBtn.value = false
  }, 450)
}
</script>
  
  <style lang="less" scoped>
  .follow-icon-btn() {
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0.32rem;
    border-radius: 0.16rem;
    background-color: #000;
    .flexbox();
    .align-center();
    .pack-center();
  }
  
  .store-header-follow {
    position: relative;
    height: 0.9067rem;
    margin-left: .16rem /* 6/37.5 */;
  
    &::after {
      content: '';
      z-index: 1;
      .follow-icon-btn();
    }
  
    &__btn_mini {
      &::after {
        animation: 0.3s linear 0s store-follow-btn-bg;
      }
    }
  
    @keyframes store-follow-btn-bg {
      0%,
      100% {
        transform: scale(1);
      }
      50% {
        transform: scale(0.85);
      }
    }
  
    &__logo {
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 50%;
      transform: scale(1);
  
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        right: auto;
        bottom: auto;
        width: 200%;
        height: 200%;
        border-radius: 999px;
        border: 1px solid #e5e5e5;
        transform: translate(-50%, -50%) scale(0.5);
        transform-origin: center;
      }
    }
  
    &__btn {
      z-index: 2;
      .follow-icon-btn();
      background-color: transparent;
    }
  
    &__btn-followed,
    &__btn-add {
      position: absolute;
      top: 50%;
      bottom: auto;
      left: 50%;
      right: auto;
      z-index: 3;
      transform: translate(-50%, -50%);
    }
  }
  
  .storeIconHandle-enter-active,
  .storeIconHandle-leave-active {
    transition: all 0.15s ease;
  }
  
  .storeIconHandle-enter-from,
  .storeIconHandle-leave-to {
    opacity: 0;
  }
  
  .storeIconHandle-leave-to.store-header-follow__btn-add {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  
  .storeIconEntry-enter-active,
  .storeIconEntry-leave-active {
    transition: opacity 0.3s ease;
  }
  
  .storeIconEntry-enter-from,
  .storeIconEntry-leave-to {
    opacity: 0;
  }
  </style>
  
