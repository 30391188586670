class BestCoupon {
  constructor({ language }) {
    this.language = language
  }

  async getData({ cartBriefInfo } = {}, config) {
    const language = await this.language

    let tips = ''
    let cartCouponList = []

    const couponInfos = cartBriefInfo?.coupon_infos
    if (couponInfos?.length) {
      cartCouponList = cartBriefInfo.coupon_infos
      if (config?.isNewuserStyle && couponInfos.find(f => gbCommonInfo.NEW_USER_BEST_COUPON_TYPE_IDS.includes(f.type_id))) {
        tips = language.SHEIN_KEY_PWA_33201
      } else {
        tips = language.SHEIN_KEY_PWA_31506
      }
    }

    return {
      tag: false,
      isPopover: true,
      tips,
      cartCouponList,
      cartCouponDiscountTotal: cartBriefInfo?.coupon_discount_total,
    }
  }
}

export default BestCoupon
