export default ({ sizeText, goodsInfo, delimiter = '/' }) => {
  /**
   *  goodsInfo 商品数据字段，其中包含size, goods_attr, isOts, sku_sale_attr
   *  delimiter分隔符，例如skuInfoList返回多条属性，将其中的属性分割开来
   *  sizeText 如果skuInfoList为空取goods_attr。 isOts=1的话取多语言库size:,
   * isOts详情： https://wiki.dotfashion.cn/pages/viewpage.action?pageId=285279488
   * 字段详情： https://wiki.dotfashion.cn/pages/viewpage.action?pageId=839228472
  */
  const getAttributeString = (item) => {
    const { attr_std_value, attr_value_name } = item || {}
    return attr_std_value ? `${attr_value_name}-${attr_std_value}` : attr_value_name
  }

  const getSkuInfoSize = () => {
    const { skuInfoList, sku_info_list, sku_sale_attr } = goodsInfo || {}
    const list = skuInfoList || sku_info_list || sku_sale_attr || []
    const skuList = JSON.parse(JSON.stringify(list)).sort((a, b) => b.is_main - a.is_main)
    return skuList.map((item) => getAttributeString(item)).join(delimiter) || ''
  }

  const getLocationSize = (type = 1)=> {
    const { attr_value_en, attr_value_location } = goodsInfo || {}
    const sizeRuleList = attr_value_location?.size_rule_list || []
    let str = ''
    // 本地尺码
    sizeRuleList.forEach(item => {
      if (item.name == attr_value_en) {
        str += type ? `(${item.correspond})` : item.correspond
      }
    })
    return str
  }

  const getGoodAttrSize = () => {
    const { goods_attr, isOts, attr_value_std_name } = goodsInfo || {}
    if(goods_attr){
      if(isOts){
        const stdName = attr_value_std_name ? `${attr_value_std_name}${delimiter}` : ''
        const goodsAttr = goods_attr.includes(':') ? goods_attr.split(':')[1] : goods_attr
        return `${sizeText}${stdName}${goodsAttr}`
      }else{
        return goods_attr.replace(':', `: ${attr_value_std_name ? attr_value_std_name + delimiter : ''}`)
      }
    }
    return ''
  }

  const getGoodSkuSize = () => {
    const sizeStr = getSkuInfoSize()
    return sizeStr ? sizeStr : getGoodAttrSize() + getLocationSize()
  }
  return {
    getGoodSkuSize
  }
}
