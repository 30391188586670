export const createTredRoute = (langPath = '') => {
  return [
    {
      path: `${langPath}/top-trend`,
      name: 'top_trend',
      meta: {
        keepAlive: true,
        pageType: 'top_trend',
        monitorTag: 'top_trend',
        isCssInVue: true,
        notNeedCommonHeader: true,
      },
      component: () => import( /* webpackChunkName: "user_news" */ '../../trend/pages/TopTrend.vue'),
    },
    {
      path: `${langPath}/trend-landing`,
      name: 'trend_landing',
      meta: {
        keepAlive: true,
        pageType: 'trend_landing',
        monitorTag: 'trend_landing',
        isCssInVue: true,
        notNeedCommonHeader: true,
      },
      component: () => import( /* webpackChunkName: "trend_landing" */ '../../trend/pages/TrendLanding.vue'),
    },
    {
      path: `${langPath}/trend-outfits`,
      name: 'trend_outfits',
      meta: {
        keepAlive: true,
        pageType: 'trend_outfits',
        monitorTag: 'trend_outfits',
        isCssInVue: true,
        notNeedCommonHeader: true,
      },
      component: () => import( /* webpackChunkName: "user_news" */ '../../trend/pages/TrendOutfits.vue'),
    }
  ]
}
