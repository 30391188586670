const routes = [{
  path: `/*coupon-product.html`,
  name: 'page_coupon_product',
  meta: {
    keepAlive: true,
    isCssInVue: true,
    pageType: 'productList',
    toSavePosition: ['page_goods_detail'],
    monitorTag: 'coupon-product'
  },
  component: () => import(/* webpackChunkName: "coupon_product" */'../../coupon_product/container.vue')
}]

export default routes
