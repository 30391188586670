
const deactivateAccountRoutes = [
  {
    path: 'deactivate(/token)?',
    component: () => import('../../deactivate_account/main.vue'),
    meta: {
      needLogin: true,
      pageType: 'deactivateAccount',
      notNeedCommonHeader: true,
      isCssInVue: true
    },
    children: [
      {
        path: '',
        name: 'deactivateAccountToken',
        component: () => import('../../deactivate_account/deactivateToken.vue'),
        meta: {
          needLogin: true,
          pageType: 'deactivateAccount',
          notNeedCommonHeader: true
        }
      },
      {
        path: 'index',
        name: 'deactivateAccountIndex',
        component: () => import('../../deactivate_account/index.vue'),
        meta: {
          needLogin: true,
          pageType: 'deactivateAccount',
          notNeedCommonHeader: true,
          isCssInVue: true
        }
      },
      {
        path: 'protocol',
        name: 'deactivateAccountProtocol',
        component: () => import('../../deactivate_account/protocol.vue'),
        meta: {
          needLogin: true,
          pageType: 'deactivateAccount',
          notNeedCommonHeader: true,
          isCssInVue: true
        }
      },
      {
        path: 'confirm',
        name: 'deactivateAccountConfirm',
        component: () => import('../../deactivate_account/confirm.vue'),
        meta: {
          needLogin: true,
          pageType: 'deactivateAccount',
          notNeedCommonHeader: true,
          isCssInVue: true
        }
      },
      {
        path: 'verify',
        name: 'deactivateAccountVerify',
        component: () => import('../../deactivate_account/sendMessage.vue'),
        meta: {
          needLogin: true,
          pageType: 'deactivateAccount',
          notNeedCommonHeader: true,
          isCssInVue: true
        }
      },
      {
        path: 'result',
        name: 'deactivateAccountResult',
        component: () => import('../../deactivate_account/result.vue'),
        meta: {
          needLogin: true,
          pageType: 'deactivateAccount',
          notNeedCommonHeader: true,
          isCssInVue: true
        }
      }
    ]
  }
]

export {
  deactivateAccountRoutes
}
