/* globals */
const saModule = {
  handlePageOnload () {
    try {
      sa('set', 'setPageData', window.SaPageInfo)
      sa('send', 'pageEnter', { start_time: Date.now() })
    } catch (e) { console.log(e) }
  },
  handlePageUnload () {
    try {
      sa('send', 'pageLeave', { end_time: Date.now() })
    } catch (e) { console.log(e) }
  }
}
export default saModule
