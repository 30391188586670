class ControlHeader {
  constructor({ components = [] }) {
    this.components = components
  }

  init(route) {
    this.components.forEach(component => {
      const show = component.onCheck(route)
      if (show) {
        this.componentUpdate(component)
      }
    })
  }

  componentUpdate(component) {
    if (component.init) return // 防止重复初始化
    component.init = true
    component.dom = document.querySelector(component.displayDom)
    component.install()
  }

  update(route) {
    this.components.forEach(component => {
      const isShow = component.onCheck(route)

      if (isShow) {
        this.componentUpdate(component)
        if (component.dom) {
          component.dom.style.display = 'block'
        }
      } else if (!isShow && component.dom) {
        component.dom.style.display = 'none'
      }
    })
  }
}


export default ControlHeader
