import { template } from '@shein/common-function'
import { CccComponentStyleType, COUPON_APPLY_FOR, COUPON_OFF_TYPE } from 'public/src/pages/user/child_pages/dashboard/service/constants'
import { formatCouponItem } from './format-coupon-item'

export const handleNewUserBenefits = (newUserBenefits) => {
  if (!newUserBenefits) return
  const { styleType, newUserCoupons, attachGoods } = newUserBenefits
  if (styleType === CccComponentStyleType.NEW_USER_COUPON) {
    if (!newUserCoupons?.length) return
    const formattedCoupons = newUserCoupons
      .map(coupon => {
        const formatted = formatCouponItem(coupon)
        return {
          ...formatted,
          couponRule: formatted?.couponRule?.slice(0, 3),
        }
      })
      .filter(coupon => isAvailableCoupon(coupon))
    if (!formattedCoupons.length) return
    // const mockedCoupons = mockCouponList(formattedCoupons)
    const sortedCoupons = sortCoupons(formattedCoupons).slice(0, 3)
    const styledCoupons = setCouponsStyles(sortedCoupons)
    newUserBenefits.newUserCoupons = styledCoupons
  }
  if (styleType === CccComponentStyleType.DELIVER_GOODS && !attachGoods?.length) return
  return newUserBenefits
}

const setCouponsStyles = (coupons) => {
  if (coupons.length === 1) {
    return coupons.map(coupon => ({
      couponData: coupon,
      couponStyle: {
        couponWidthRem: 335 / 37.5,
        discountFontSizeClass: 'new-user-benefits__coupon-discount-fz14',
        discountLineHeightRem: (isSingleGearCoupon(coupon) ? 14 : 16) / 37.5,
        ruleTipsLineHeightRem: 10 / 37.5,
      },
    }))
  }
  const hasMultiGearCoupon = coupons.some(coupon => isMultiGearCoupon(coupon))
  if (hasMultiGearCoupon) {
    return coupons.map(coupon => ({
      couponData: coupon,
      couponStyle: {
        couponWidthRem: (isMultiGearCoupon(coupon) ? 277 : 155) / 37.5,
        discountFontSizeClass: isMultiGearCoupon(coupon)
          ? 'new-user-benefits__coupon-discount-fz12'
          : 'new-user-benefits__coupon-discount-fz14',
        discountLineHeightRem: 16 / 37.5,
        ruleTipsLineHeightRem: 8 / 37.5,
      }
    }))
  }
  return coupons.map(coupon => ({
    couponData: coupon,
    couponStyle: {
      couponWidthRem: (coupons.length === 2 ? 165 : 155) / 37.5,
      discountFontSizeClass: 'new-user-benefits__coupon-discount-fz14',
      discountLineHeightRem: 14 / 37.5,
      ruleTipsLineHeightRem: 10 / 37.5,
    }
  }))
}

export const getCouponApplyRuleTips = (couponRule, couponItem, language) => {
  const { min, max } = couponRule ?? {}
  const minAmountWithSymbol = min?.amountWithSymbol
  const maxAmountWithSymbol = max?.amountWithSymbol
  if (couponItem?.couponRule?.length > 1) {
    return template(minAmountWithSymbol, language.SHEIN_KEY_PWA_27134)
  }
  if (maxAmountWithSymbol) {
    return template(minAmountWithSymbol, maxAmountWithSymbol, language.SHEIN_KEY_PWA_18572)
  }
  const minAmountStr = min?.amount || 0
  if (+minAmountStr > 0) {
    return template(minAmountWithSymbol, language.SHEIN_KEY_PWA_27134)
  } else if (couponItem.applyFor !== COUPON_APPLY_FOR.ABPrice) {
    return language.SHEIN_KEY_PWA_27510
  }
  return ''
}

export const getCouponDiscountText = (couponRule, couponItem, language, GB_cssRight) => {
  const { ruleDimension } = couponItem
  if (ruleDimension === COUPON_OFF_TYPE.Cash) {
    const amountWithSymbol = couponRule.value?.amountWithSymbol || ''
    return `${!GB_cssRight ? '-' : ''}${amountWithSymbol}${GB_cssRight ? '-' : ''}`
  }
  const amount = couponRule?.value?.amount || couponRule?.value
  const discountNum = amount * 100 / 100
  const offText = language.SHEIN_KEY_PWA_27135
  return `${discountNum}% ${offText}`
}

const isAvailableCoupon = (coupon) => {
  const { selfStatus, timeStatus } = coupon || {}
  return selfStatus === '0' && ['0', '2'].includes(timeStatus)
}

const sortCoupons = (coupons) => {
  if (coupons.length === 1) return coupons
  const singleGearDiscountAmountCoupons = coupons.filter(coupon => isSingleGearCoupon(coupon) && isDiscountAmountCoupon(coupon))
  const singleGearDiscountPercentCoupons = coupons.filter(coupon => isSingleGearCoupon(coupon) && isDiscountPercentCoupon(coupon))
  const multiGearDiscountAmountCoupons = coupons.filter(coupon => isMultiGearCoupon(coupon) && isDiscountAmountCoupon(coupon))
  const multiGearDiscountPercentCoupons = coupons.filter(coupon => isMultiGearCoupon(coupon) && isDiscountPercentCoupon(coupon))
  return [
    ...sortSameTypeCoupons(singleGearDiscountPercentCoupons),
    ...sortSameTypeCoupons(singleGearDiscountAmountCoupons),
    ...sortSameTypeCoupons(multiGearDiscountPercentCoupons),
    ...sortSameTypeCoupons(multiGearDiscountAmountCoupons),
  ]
}

const sortSameTypeCoupons = (coupons) => {
  if (!coupons?.length) return []
  const discountField = coupons[0].ruleDimension === COUPON_OFF_TYPE.Cash
    ? 'discountAmount'
    : 'discountPercent'
  return coupons.sort((a, b) => {
    if (b[discountField] !== a[discountField]) {
      // 优惠力度大排前面
      return b[discountField] - a[discountField]
    } else {
      // 使用门槛低排前面
      return a.minOrderAmount - b.minOrderAmount
    }
  })
}

// const mockCouponList = (rawCouponList) => {
//   const singleGearCoupon = rawCouponList.find(coupon => isSingleGearCoupon(coupon))
//   const multiGearCoupon = rawCouponList.find(coupon => isMultiGearCoupon(coupon))
//   const allPossibleResult = []
//   if (singleGearCoupon) {
//     allPossibleResult.push(
//       [singleGearCoupon],
//       [singleGearCoupon, singleGearCoupon],
//       [singleGearCoupon, singleGearCoupon, singleGearCoupon])
//   }
//   if (multiGearCoupon) {
//     allPossibleResult.push([multiGearCoupon], [multiGearCoupon, multiGearCoupon])
//   }
//   if (singleGearCoupon && multiGearCoupon) {
//     allPossibleResult.push([singleGearCoupon, multiGearCoupon])
//   }
//   const randIndex = Math.floor(allPossibleResult.length * Math.random())
//   console.log('rand index', randIndex)
//   return allPossibleResult[randIndex]
// }

const isSingleGearCoupon = coupon => coupon.couponRule.length === 1
const isMultiGearCoupon = coupon => coupon.couponRule.length > 1
const isDiscountAmountCoupon = coupon => coupon.ruleDimension === COUPON_OFF_TYPE.Cash
const isDiscountPercentCoupon = coupon => [
  COUPON_OFF_TYPE.PercentOfPriceShouldPay,
  COUPON_OFF_TYPE.PercentOfOriginPrice,
].includes(coupon.ruleDimension)

