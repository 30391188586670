import { isLogin as isLoginFn } from 'public/src/pages/common/utils/index.js'
import { getLocalStorage, setLocalStorage } from '@shein/common-function'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'

const memberId = UserInfoManager.get({ key: 'memberId' })
const toLoginForBack = (langpath, loginPath = '/user/auth/login') => {
  loginPath = loginPath.slice(0, 1) === '/' ? loginPath : `/${loginPath}`
  langpath = langpath ? langpath.slice(0, 1) === '/' ? langpath : `/${langpath}` : ''
  const pathname = window.location.pathname
  const search = window.location.search
  const hash = window.location.hash
  const path = `${pathname}${search || ''}${hash || ''}`
  const fullLoginPath = `${langpath}${loginPath}`
  window.location.href = `${fullLoginPath}?redirection=${encodeURIComponent(path)}`
}

const isLogin = () => isLoginFn()
const getReadInfo = () => {
  try{
    return JSON.parse(getLocalStorage(`${memberId}-readInfo`)) || {}
  }catch(e){
    return {}
  }
}
const setReadInfo = (info) => {
  setLocalStorage({ key: `${memberId}-readInfo`, value: JSON.stringify(info) })
}
export {
  toLoginForBack,
  isLogin,
  getReadInfo,
  setReadInfo
}

export function createBFFMixinRequestHelper({
  originRequest: { request: originRequest, formatResponse: originFormatResponse },
  bffRequest: { request: bffRequest, formatResponse: bffFormatResponse }
}) {
  return async function mixinRequest({
    useBffApi,
    requestArgs: [requestArgs, bffRequestArgs] = []
  }) {
    const request = useBffApi ? bffRequest : originRequest
    const params = useBffApi ? bffRequestArgs || requestArgs : requestArgs
    const formatResponse = useBffApi ? bffFormatResponse : originFormatResponse
    const result = await request(params)

    return formatResponse?.(result) ?? result
  }
}

/**
 * 统一 promise 返回帮助函数 
 * @param {Promise} promise 
 * @returns {Promise<{result?: any, err?: any}>}
 */
export function fa(promise) {
  return promise.then(
    (val) => ({ result: val, err: void 0 }),
    (err) => {
      return { result: void 0, err }
    },
  )
}
