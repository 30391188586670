const { langPath } = gbCommonInfo
const infringementComplaintRoutes = [
  {
    path: `${langPath}/ip-complaint`,
    name: 'infringementComplaint',
    meta: {
      keepAlive: false,
      pageType: 'infringementComplaint',
      notNeedCommonHeader: true,
      isCssInVue: true,
    },
    component: () => import(/* webpackChunkName: "infringement-complaint" */ '../../infringement_complaint/container.vue')
  }
]

export {
  infringementComplaintRoutes
}
