const { langPath } = window.gbCommonInfo
const storeRoutes = [{
  path: `${langPath}/store-comment`,
  name: 'storeComment',
  component: () => import('../../store/comment.vue'),
  meta: {
    notNeedCommonHeader: true,
  }
}]

export {
  storeRoutes
}
