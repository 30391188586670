export const createSuperDealsRoute = (langPath = '') => {
  return {
    path: `${langPath}/super-deals`,
    name: 'thrifty_find',
    meta: {
      keepAlive: true,
      pageType: 'thrifty_find',
      monitorTag: 'thrifty-find',
      isCssInVue: true,
      notNeedCommonHeader: true,
    },
    component: () => import( /* webpackChunkName: "thrifty_find" */ '../../thrifty_find/container.vue'),
  }
}
