<template>
  <SearchBox
    v-if="isMounted"
    :searchBoxAbtStatus="searchBoxAbtStatus"
    :pageType="pageType"
    :searchBoxUI="getSearchBoxUI"
    :isShowInput="isShowInput"
    @searchTermClick="$emit('clickSearchBar', $event)"
    @textClick="$emit('clickSearchBar', $event)"
    @cameraIconClick="clickVisualSearch"
    @uploadImgSuccess="$emit('uploadSuccessHandler', $event)"
    @clearClick="$emit('clickSearchBar',{ type: 'clickclearBtn' })"
    @slideChangeTransitionEnd="slideChangeTransitionEnd"
    @resolveEmptySearchTerms="handleResolveEmptySearchTerms"
    @resolveSearchTerms="handleResolveSearchTerms"
  />
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import analysis from '../analysis'
import { getQueryString } from '@shein/common-function'
import SearchBox from '../../../SearchBox'

const { language } = gbCommonInfo

daEventCenter.addSubscriber({ modulecode: '1-5-3' })

const exposeSearchTextArr = []

export default {
  name: 'LegacyCommonHeaderInput',
  components: {
    SearchBox,
  },
  props: {
    startDeal: { //【头部】控制切换底纹词 ---> fun dealDefaultWords
      type: Boolean,
      default: false
    },
    abtDefaultWords: { //【头部】请求底纹词的实验，分商详和非商详情况【可优化】
      type: Object,
      default: () => ( {} )
    },
    goodsId: { //【头部】商详goodsid
      type: String,
      default: ''
    },
    commonHeaderBg: { //【头部】沉浸式头部配置
      type: Object,
      default: () => ({
        backgroundColor: '', 
        searchInputBgColor: '', // 搜索框背景色
        topTextColor: '',
        showBlock: false
      })
    },
    showDefaultWordsState: {  // 搜索框是否按照新的底纹词策略展示底纹词
      type: Boolean,
      default: false
    },
    defaultWordsProp: {  // 传入的底纹词
      type: Array,
      default: () => [] 
    },
    isCatNameSearchWrods: {  // 是否用catName作为搜索词
      type: Boolean,
      default: false
    },
    cateIds: {   // 底纹词策略接口需要的字段
      type: String,
      default: ''
    },
    selectId: { // 底纹词策略接口需要的字段
      type: String,
      default: ''
    },
    bizScene: {  // 给底纹词接口的场景名称
      type: String,
      default: ''
    },
    pageType: {  // 页面类型 all, store, brand
      type: String,
      default: 'all'
    },
    useBff: {
      type: Boolean,
      default: false
    },
    isShowInput: {
      type: Boolean,
      default: false
    },
    isHitBlackListTitle: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isMounted: false,
      searchTerms: [],
      searchTermsForStoreSelectionList: [],
      searchBoxAbtStatus: 'idle',
      // 搜索框实验
      searchBoxAbt: {
        SearchBorderNew: {},
        HomeSearch: {},
        NewPicSearch: {},
        PicSearchUpgrade: {},
        BackToSugg: {},
        newsearch: {},
        storelistsearch: {},
        SearchDefaultNew: {},
        SearchHotGD: {},
        searchtrendpic: {},
        StoreSearch: {},
        ListShadingWord: {},
      },
      fetchSearchTermsParams: {
        word_type: 1,
        scene: 'home'
      }
    }
  },
  computed: {
    // currentDefaultKeyword：框里的底纹词 searchSelectKeyWord：框里的搜索词 mixedKeyword：主搜词 +【分布式筛选词 (有就会拼上)】
    ...mapState(['currentDefaultKeyword', 'mixedKeyword', 'searchSelectKeyWord', 'searchFilterWords']),
    searchInputScene () { // 搜索框当前场景值
      const { $route = {} } = this
      const { query = {}, meta = {} } = $route
      const { search_type: searchType } = query || {}
      const { pageType, type: listType } = meta || {}
      switch(pageType) {
        case 'index':
          return 'homeIndex' // 首页
        case 'productDetail':
          return 'goodsDetail' // 商详
        case 'page_store':
          return 'storePage' // 店铺
        case 'productList': // 列表
          switch(listType) { 
            case 'entity':
              return 'entityList' // 真实列表页
            case 'picks': // picks列表页
              return 'picksList'
            case 'selection': // 选品列表页
              if(searchType === 'store'){
                return 'storeSelectionList' // 店铺选品页
              }
              return 'selectionList' // 普通选品
            case 'search': // 搜索结果页
              if (searchType === 'store') {
                return 'storeSearchResult' // 店铺结果
              }
              if (searchType === 'brand'){ 
                return 'brandSearchResult' // 专题/品牌结果
              }
              return 'allSearchResult' // 全局结果
            default:
              return 'other'
          }
        default:
          return 'other'
      }
    },
    getSearchBoxUI() {
      const scene = this.searchInputScene
      console.log(`scene ::`, scene)

      // 当 abt 请求失败时，需要展示兜底的搜索框
      if (this.searchBoxAbtStatus !== 'resolved') {
        return {}
      }

      const { PicSearchUpgrade } = this.searchBoxAbt
      if (scene === 'homeIndex') {
        const { SearchBorderNew, HomeSearch } = this.searchBoxAbt
        return {
          component: 'DefaultSearchBox',
          autoFetch: true,
          boxType: this.getBoxType(SearchBorderNew.home_search_style),
          showSearchIcon: !Boolean(HomeSearch.HomeSearch),
          showCameraIcon: true,
          showSearchButton: Boolean(HomeSearch.HomeSearch),
          variableStyle: {
            '--search-box-box-height': '.90667rem',
            '--search-term-text-color': '#000',
            '--search-box-border-color': 'rgba(0, 0, 0, 0.8)',
            '--search-box-border-width': '.02667rem',
            '--search-box-search-button-color': 'rgba(0, 0, 0, 0.8)'
          },
          isCameraUploadImg: PicSearchUpgrade.PicSearchUpgrade === 'New',
          fetchSearchTermsParams: { word_type: 1, scene: 'home' },
          emptySearchTermsText: language.SHEIN_KEY_PWA_30497
        }
      }

      if (scene === 'allSearchResult') {
        const { newsearch, NewPicSearch } = this.searchBoxAbt

        // searchtrendpic
        // 命中searchtrendpic.show 或者 newsearch.Listnewsearch.old，不展示边框
        const boxType = newsearch.Listnewsearch === 'new' ? 'round' : 'none'

        const boxBorderStyle = this.commonHeaderBg?.showBlock ? { '--search-box-border-width': 0 } : {}

        const isTagMode = this.searchFilterWords.length > 0
        const searchSelectKeyWord = this.searchSelectKeyWord || ''

        const tagItem = searchSelectKeyWord ? {
          filterId: searchSelectKeyWord,
          filterWord: searchSelectKeyWord
        } : null
        const boxBgColor = ([boxType === 'none', isTagMode].includes(true) ? '#f6f6f6' : '#fff')
        return {
          component: 'ActiveSearchBox',
          boxType,
          isTagMode,
          tagList: [tagItem, ...this.searchFilterWords].filter(Boolean),
          showCameraIcon: NewPicSearch.SearchResult === 'A',
          isCameraUploadImg: PicSearchUpgrade.PicSearchUpgrade === 'New',
          variableStyle: {
            '--search-box-box-height': '.90667rem',
            '--active-search-default-text-color': '#000',
            '--search-box-border-color': '#000',
            '--search-box-border-width': '.02667rem',
            '--search-box-bg-color': boxBgColor,
            ...boxBorderStyle
          },
        }
      }

      if (scene === 'goodsDetail') {
        // const { SearchBorderNew, HomeSearch } = this.searchBoxAbt
        const goodsId = this.goodsId || this.$route.params['1']
        const scene = goodsId ? 'detail' : 'home'
        return {
          component: 'DefaultSearchBox',
          autoFetch: goodsId ? true : false,
          fixedFirstTerm: true,
          boxType: 'none',
          showSearchIcon: true,
          showCameraIcon: true,
          showSearchButton: false,
          variableStyle: {
            '--search-box-box-height': '.90667rem',
            '--search-term-text-color': '#000',
            '--search-box-border-color': 'rgba(0, 0, 0, 0.8)',
            '--search-box-border-width': '.02667rem',
            '--search-box-search-button-color': 'rgba(0, 0, 0, 0.8)'
          },
          isCameraUploadImg: PicSearchUpgrade.PicSearchUpgrade === 'New',
          fetchSearchTermsParams: { word_type: 1, goods_id: goodsId, scene },
          emptySearchTermsText: language.SHEIN_KEY_PWA_30497
        }
      }

      if (scene === 'brandSearchResult') {
        return {
          component: 'ActiveSearchBox',
          boxType: 'none',
          isTagMode: false,
          showCameraIcon: false,
          // isCameraUploadImg: PicSearchUpgrade.PicSearchUpgrade === 'New',
          variableStyle: {
            '--search-box-box-height': '.90667rem',
            '--active-search-default-text-color': '#000'
          },
        }
      }

      if (scene === 'picksList') {
        const { ListShadingWord } = this.searchBoxAbt

        const isCloseListShadingWord = ListShadingWord.PListShadingWord === 'close'
        const isShowListShadingWord = ListShadingWord.PListShadingWord === 'show'

        const showAbtParamsObj = isShowListShadingWord ? {
          goods_id: this.goodsId,
          cat_id: this.cateIds,
          select_id: this.selectId,
          list_scene: this.bizScene,
        } : {}
        const autoFetch = isShowListShadingWord && !this.bizScene ? false : true

        if (isCloseListShadingWord && !this.isHitBlackListTitle) {
          return {
            component: 'ActiveSearchBox',
            boxType: 'round',
            showClearIcon: false,
            showCameraIcon: true,
            showSearchButton: true,
            searchButtonType: 'round',
            isCameraUploadImg: PicSearchUpgrade.PicSearchUpgrade === 'New',
            variableStyle: {
              '--search-box-box-height': '.90667rem',
              '--active-search-default-text-color': '#000',
              '--search-term-text-color': 'rgb(34, 34, 34)'
            },
          }
        }

        return {
          component: 'DefaultSearchBox',
          autoFetch,
          boxType: 'round',
          showSearchIcon: false,
          showSearchButton: true,
          showCameraIcon: true,
          variableStyle: {
            '--search-box-box-height': '.90667rem',
            '--search-term-text-color': '#000',
            '--search-box-border-color': 'rgba(0, 0, 0, 0.8)',
            '--search-box-border-width': '.02667rem',
            '--search-box-search-button-color': 'rgba(0, 0, 0, 0.8)'
          },
          isCameraUploadImg: PicSearchUpgrade.PicSearchUpgrade === 'New',
          fetchSearchTermsParams: {
            ...this.fetchSearchTermsParams,
            ...showAbtParamsObj,
          },
          emptySearchTermsText: language.SHEIN_KEY_PWA_30497
        }

      }

      if (scene === 'selectionList') {
        const { ListShadingWord } = this.searchBoxAbt

        const isCloseListShadingWord = ListShadingWord.ListShadingWord === 'close'
        const isShowListShadingWord = ListShadingWord.ListShadingWord === 'show'

        const showAbtParamsObj = isShowListShadingWord ? {
          goods_id: this.goodsId,
          cat_id: this.cateIds,
          select_id: this.selectId,
          list_scene: this.bizScene,
        } : {}
        const autoFetch = isShowListShadingWord && !this.bizScene ? false : true

        if (isCloseListShadingWord && !this.isHitBlackListTitle) {
          return {
            component: 'ActiveSearchBox',
            boxType: 'round',
            showClearIcon: false,
            showCameraIcon: true,
            showSearchButton: true,
            searchButtonType: 'round',
            isCameraUploadImg: PicSearchUpgrade.PicSearchUpgrade === 'New',
            variableStyle: {
              '--search-box-box-height': '.90667rem',
              '--active-search-default-text-color': '#000',
              '--search-term-text-color': 'rgb(34, 34, 34)'
            },
          }
        }

        return {
          component: 'DefaultSearchBox',
          autoFetch,
          boxType: 'round',
          showSearchIcon: false,
          showSearchButton: true,
          showCameraIcon: true,
          variableStyle: {
            '--search-box-box-height': '.90667rem',
            '--search-term-text-color': '#000',
            '--search-box-border-color': 'rgba(0, 0, 0, 0.8)',
            '--search-box-border-width': '.02667rem',
            '--search-box-search-button-color': 'rgba(0, 0, 0, 0.8)'
          },
          isCameraUploadImg: PicSearchUpgrade.PicSearchUpgrade === 'New',
          fetchSearchTermsParams: {
            ...this.fetchSearchTermsParams,
            ...showAbtParamsObj,
          },
          emptySearchTermsText: language.SHEIN_KEY_PWA_30497
        }
      }

      if (scene === 'entityList') {
        const { newsearch, ListShadingWord, NewPicSearch } = this.searchBoxAbt
        const boxType = newsearch.Listnewsearch === 'new' ? 'round' : 'none'

        const isCloseListShadingWord = ListShadingWord.ListShadingWord === 'close'
        const isShowListShadingWord = ListShadingWord.ListShadingWord === 'show'

        const showAbtParamsObj = isShowListShadingWord ? {
          goods_id: this.goodsId,
          cat_id: this.cateIds,
          select_id: this.selectId,
          list_scene: this.bizScene,
        } : {}
        const autoFetch = isShowListShadingWord && !this.bizScene ? false : true

        if (isCloseListShadingWord) {
          return {
            component: 'ActiveSearchBox',
            boxType,
            showClearIcon: boxType === 'round' ? false : true,
            showCameraIcon: boxType === 'round' ? true :  NewPicSearch.SearchResult === 'A',
            showSearchButton: boxType === 'round' ? true : false,
            searchButtonType: 'round',
            isCameraUploadImg: PicSearchUpgrade.PicSearchUpgrade === 'New',
            variableStyle: {
              '--search-box-box-height': '.90667rem',
              '--active-search-default-text-color': '#000',
              '--search-term-text-color': 'rgb(34, 34, 34)'
            },
          }
        }

        return {
          component: 'DefaultSearchBox',
          autoFetch,
          boxType,
          showSearchIcon: false,
          showSearchButton: boxType === 'round' ? true : false,
          showCameraIcon: boxType === 'round' ? true :  NewPicSearch.SearchResult === 'A',
          variableStyle: {
            '--search-box-box-height': '.90667rem',
            '--search-term-text-color': '#000',
            '--search-box-border-color': 'rgba(0, 0, 0, 0.8)',
            '--search-box-border-width': '.02667rem',
            '--search-box-search-button-color': 'rgba(0, 0, 0, 0.8)'
          },
          isCameraUploadImg: PicSearchUpgrade.PicSearchUpgrade === 'New',
          fetchSearchTermsParams: {
            word_type: 1,
            scene: 'home',
            ...showAbtParamsObj
          },
          emptySearchTermsText: language.SHEIN_KEY_PWA_30497,
        }
      }

      if (scene === 'storeSelectionList') {
        const { storelistsearch } = this.searchBoxAbt
        // 这里隐式依赖了 StoreSearch 这个 abt
        const boxType = storelistsearch.storelistsearch === 'A' ? 'none' : 'round'
        const isStorelistsearchBBranch = storelistsearch.storelistsearch === 'B' 
        const hotTermIndexObj = isStorelistsearchBBranch ? {
          showTermIcon: false,
          hotTermIndex: 2
        } : {}

        return {
          component: 'DefaultSearchBox',
          autoFetch: false,
          boxType,
          showSearchIcon: false,
          showSearchButton: boxType === 'none' ? false : true,
          showCameraIcon: false,
          searchButtonType: boxType,
          variableStyle: {
            '--search-box-box-height': '.90667rem',
            '--search-term-text-color': '#000',
            '--search-box-border-color': 'rgba(0, 0, 0, 0.8)',
            '--search-box-border-width': '.02667rem',
            '--search-box-search-button-color': 'rgba(0, 0, 0, 0.8)'
          },
          slideItems: this.searchTermsForStoreSelectionList || [],
          ...hotTermIndexObj,
          emptySearchTermsText: language.SHEIN_KEY_PWA_30497
        }
      }

      if (scene === 'storeSearchResult') {
        const { storelistsearch, } = this.searchBoxAbt
        const boxType = storelistsearch.storelistsearch === 'A' ? 'none' : 'round'

        return {
          component: 'ActiveSearchBox',
          boxType,
          isTagMode: false,
          showCameraIcon: false,
          // isCameraUploadImg: PicSearchUpgrade.PicSearchUpgrade === 'New',
          variableStyle: {
            '--search-box-box-height': '.90667rem',
            '--active-search-default-text-color': '#000'
          },
        }
      }

      // const defaultSearchBoxUI = {
      //   component: 'DefaultSearchBox',
      //   boxType: 'none',
      //   showSearchIcon: true,
      //   showSearchButton: false,
      //   variableStyle: { '--search-term-text-color': '#000' }, 
      //   fetchSearchTermsParams: { word_type: 1, scene: 'home' }
      // }

      // console.log(`---------> not sence ::`, scene)

      return {}
    }
  },
  watch: {
    isShowInput: {
      handler (newVal) {
        if (newVal) {
          this.handleExposeAnalysis()
        }
      },
      immediate: true
    },
    isHitBlackListTitle: {
      handler (newVal) {
        if (newVal) {
          this.fetchSearchTermsParams = {
            word_type: 1,
            scene: 'home'
          }
        }
      },
      immediate: true
    },
    searchInputScene: {
      handler(newVal){
        if(newVal === 'allSearchResult') {
          // 搜索结果页没有底纹词，不会因为底纹词而出发exposeSearch，在这里手动触发
          // exposeSearch有时比结果页的pv先发，所以手动修改page_name
          const srcIdentifier = getQueryString({ key: 'src_identifier' }) ?? ''
          const searchType = (srcIdentifier.match(/st=(\d+)`/) || [])[1] || ''
          requestIdleCallback(() => {
            SaPageInfo.page_name = 'page_search'
            sa('set', 'setPageData', SaPageInfo)
            analysis.exposeSearch({
              currentWord: this.currentDefaultKeyword || this.mixedKeyword,
              isCatNameSearchWrods: this.isCatNameSearchWrods,
              result_type: searchType
            })
          })
        }
      },
      immediate: true
    },
  },
  created() {
    this.initSearchBoxAbt()

    this.exposeVisualSearch = false
  },
  mounted() {
    this.hackFixedSwiper()
  },
  methods: {
    ...mapActions(['fetchKeywords']),
    hackFixedSwiper() {
      // 修复搜索框轮播词不显示问题
      setTimeout(() => {
        this.isMounted = true
      })
    },
    async initSearchBoxAbt() {
      this.searchBoxAbtStatus = 'pending'
      const { 
        SearchBorderNew = {},
        HomeSearch = {},
        NewPicSearch = {},
        PicSearchUpgrade = {},
        BackToSugg = {},
        newsearch = {},
        storelistsearch = {},
        SearchDefaultNew = {},
        SearchHotGD = {},
        searchtrendpic = {},
        StoreSearch = {},
        ListShadingWord = {},
      } = await getUserAbtData()

      this.searchBoxAbt = {
        SearchBorderNew: SearchBorderNew.p || {},
        HomeSearch: HomeSearch.p || {},
        NewPicSearch: NewPicSearch.p || {},
        PicSearchUpgrade: PicSearchUpgrade.p || {},
        BackToSugg: BackToSugg.p || {},
        newsearch: newsearch.p || {},
        storelistsearch: storelistsearch.p || {},
        SearchDefaultNew: SearchDefaultNew.p || {},
        SearchHotGD: SearchHotGD.p || {},
        searchtrendpic: searchtrendpic.p || {},
        StoreSearch: StoreSearch.p || {},
        ListShadingWord: ListShadingWord.p || {},
      }

      this.searchBoxAbtStatus = 'resolved'

      this.initSearchTermsForStoreSelectionList()
    }, 
    getBoxType(params) {
      if (['new', 'white'].includes(params)) {
        return 'square'
      }
      if (params === 'round') {
        return 'round'
      }
      return 'none'
    },

    handleResolveEmptySearchTerms() {
      if (!['entityList', 'picksList', 'selectionList'].includes(this.searchInputScene)) {
        return
      }

      if ((this.cateIds || this.selectId)) {
        if (['entityList', 'selectionList'].includes(this.searchInputScene)) {
          // 当底纹词轮播实验返回空词时，需要走固定展示标题的逻辑，这里通过修改为 close 实验达到一样的效果
          this.searchBoxAbt.ListShadingWord = {
            ListShadingWord: 'close'
          }
        }

        if (['picksList'].includes(this.searchInputScene)) {
          // 当底纹词轮播实验返回空词时，需要走固定展示标题的逻辑，这里通过修改为 close 实验达到一样的效果
          this.searchBoxAbt.ListShadingWord = {
            PListShadingWord: 'close'
          }
        }

        this.$emit('cacheListDefaultWordsReq', { defaultWords: this.searchTerms || [] })
        this.$emit('handleProductListSearchWords', { isDefaultWordsEmpty: true })
      }
    },

    handleResolveSearchTerms(searchTerms) {
      this.searchTerms = searchTerms
    },
    clickVisualSearch() { // 相机按钮点击、打开中间页
      daEventCenter.triggerNotice({
        daId: '1-5-3-1',
      })
      this.$emit('initMiddlePage')
    },
    async initSearchTermsForStoreSelectionList() {
      const { query } = this.$route || {}
      const isStoreSelectionList = ['storeSelectionList'].includes(this.searchInputScene) && query?.store_code && getQueryString({ key: 'searchExpand' }) === '1' && this.searchBoxAbt.storelistsearch.storelistsearch === 'B'
      if (isStoreSelectionList) {
        const StoreSearch = this.searchBoxAbt.StoreSearch.StoreSearch
        const defaultRuleId = {
          word_recall: StoreSearch
        }
        const { keywords = [] } = await this.fetchKeywords({ 
          word_type: 1, 
          rule_id: defaultRuleId, 
          store_code: getQueryString({ key: 'store_code' }), 
          scene: 'store'
        })

        this.searchTermsForStoreSelectionList = keywords
        // 历史代码中有一段 索引缓存的逻辑，暂时不做处理
      }
    },
    handleExposeAnalysis() {
      // 处理第一个轮播词曝光...
      const firstSearchTerm = this.searchTerms[0]?.word
      if (this.searchTerms.length && !exposeSearchTextArr.includes(firstSearchTerm)) {
        analysis.exposeSearch({
          defaultWords: [this.searchTerms[0]],
          placeholder: this.searchTerms[0],
          localIndex: 0,
        })
        exposeSearchTextArr.push(firstSearchTerm)
      }

      // 处理相机曝光...
      if (!this.exposeVisualSearch) {
        this.handleVisualSearchAnalysis()
        this.exposeVisualSearch = true
      }
    },
    // 轮播曝光埋点
    slideChangeTransitionEnd(index, item) {
      if (exposeSearchTextArr.includes(item?.word)) {
        return
      }
      analysis.exposeSearch({
        defaultWords: [item],
        placeholder: this.searchTerms[0],
        localIndex: index,
      })
      exposeSearchTextArr.push(item?.word)
    },
    handleVisualSearchAnalysis() {
      requestIdleCallback(() => {
        daEventCenter.triggerNotice({
          daId: '1-5-3-2',
        })
      })
    }
  }
}
</script>
