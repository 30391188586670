export const createPayRoute = (langPath = '') => {
  return [
    {
      path: `${langPath}/pay/ocp`,
      name: 'page_pay_ocp',
      meta: {
        isCssInVue: true,
        keepAlive: false,
        notNeedCommonHeader: true,
        pageType: 'ocpLanding',
        monitorTag: 'ocp-landing'
      },
      component: () => import(/* webpackChunkName: "pay_ocp_main" */'public/src/pages/one_click_purchase_landing/main.vue')
    }
  ]
}

