export const createBrandZoneRoute = (langPath = '') => {
  return {
    path: `${langPath}/brand-zone`,
    name: 'brand_zone',
    meta: {
      keepAlive: true,
      pageType: 'brand_zone',
      monitorTag: 'brand_zone',
      isCssInVue: true,
      notNeedCommonHeader: true,
    },
    component: () => import( /* webpackChunkName: "brand_zone" */ '../../brand_zone/index.vue'),
  }
}
